import * as React from "react";
import PropTypes from "prop-types";

const VideoIcon = ({
  size = 30,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
<svg width={size} height={size} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.75 27.9265H19.25C25.5 27.9265 28 25.4265 28 19.1765V11.6765C28 5.42651 25.5 2.92651 19.25 2.92651H11.75C5.5 2.92651 3 5.42651 3 11.6765V19.1765C3 25.4265 5.5 27.9265 11.75 27.9265Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.875 15.4266V13.5766C11.875 11.1891 13.5625 10.2266 15.625 11.4141L17.225 12.3391L18.825 13.2641C20.8875 14.4516 20.8875 16.4016 18.825 17.5891L17.225 18.5141L15.625 19.4391C13.5625 20.6266 11.875 19.6516 11.875 17.2766V15.4266Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

VideoIcon.displayName = "Check";



VideoIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default VideoIcon;
