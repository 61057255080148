import React, { useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import NotificationModal from "./NotificationModal";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const NotificationCard = ({ title, time, message, id, seen }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUserActiveVisible, setUserActiveVisible] = useState(!seen); 

  const openModal = () => {
    setModalOpen(true);
    setUserActiveVisible(false); 
  };
  const closeModal = () => setModalOpen(false);

  const trimmedMessage = message.length > 50 
    ? message.substring(0, 50) + "…" 
    : message;

  return (
    <>
      <div className="NotificationCard" onClick={openModal}>
        <div className="iconNotification">
          <Avatar image={null} name={title} />
        </div>
        <div className="contentNotification">
          <h3 className="notificatinUserName">
            {title}
            {isUserActiveVisible && <p className="userActive"></p>} 
          </h3>
          <div className="moreMessage">
            <span className="ellipsSpan">{trimmedMessage}</span>
          </div>
        </div>
        <div className="notificationTime">{time}</div>
      </div>
      <NotificationModal show={isModalOpen} onClose={closeModal} id={id} />
    </>
  );
};

export default NotificationCard;
