

import * as React from "react";
import PropTypes from "prop-types";

const BlockErrorIcon = ({
  size = 16,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-outside-1_1633_1705" maskUnits="userSpaceOnUse" x="-1" y="-1" width="18" height="18" fill="black">
<rect fill="white" x="-1" y="-1" width="18" height="18"/>
<path d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM7.25 4.75V8.75C7.25 9.1875 7.5625 9.5 8 9.5C8.40625 9.5 8.75 9.1875 8.75 8.75V4.75C8.75 4.34375 8.40625 4 8 4C7.5625 4 7.25 4.34375 7.25 4.75ZM8 12.5C8.53125 12.5 8.96875 12.0625 8.96875 11.5312C8.96875 11 8.53125 10.5625 8 10.5625C7.4375 10.5625 7 11 7 11.5312C7 12.0625 7.4375 12.5 8 12.5Z"/>
</mask>
<path d="M8 1C11.854 1 15 4.14603 15 8H17C17 3.04147 12.9585 -1 8 -1V1ZM15 8C15 11.8827 11.8565 15 8 15V17C12.956 17 17 12.9923 17 8H15ZM8 15C4.11478 15 1 11.8852 1 8H-1C-1 12.9898 3.01022 17 8 17V15ZM1 8C1 4.14349 4.11733 1 8 1V-1C3.00767 -1 -1 3.04401 -1 8H1ZM6.25 4.75V8.75H8.25V4.75H6.25ZM6.25 8.75C6.25 9.73979 7.01022 10.5 8 10.5V8.5C8.02585 8.5 8.09923 8.51314 8.16804 8.58196C8.23686 8.65077 8.25 8.72415 8.25 8.75H6.25ZM8 10.5C8.93101 10.5 9.75 9.76691 9.75 8.75H7.75C7.75 8.69606 7.7752 8.62556 7.83056 8.57178C7.88169 8.52211 7.94463 8.5 8 8.5V10.5ZM9.75 8.75V4.75H7.75V8.75H9.75ZM9.75 4.75C9.75 3.79147 8.95854 3 8 3V5C7.91689 5 7.85565 4.96518 7.82024 4.92976C7.78482 4.89435 7.75 4.83311 7.75 4.75H9.75ZM8 3C6.98309 3 6.25 3.81899 6.25 4.75H8.25C8.25 4.80537 8.22789 4.86831 8.17822 4.91944C8.12444 4.9748 8.05394 5 8 5V3ZM8 13.5C9.08354 13.5 9.96875 12.6148 9.96875 11.5312H7.96875C7.96875 11.5257 7.97008 11.5195 7.97214 11.5147C7.9739 11.5106 7.97563 11.5086 7.97649 11.5077C7.97735 11.5069 7.97932 11.5052 7.98344 11.5034C7.98829 11.5013 7.99444 11.5 8 11.5V13.5ZM9.96875 11.5312C9.96875 10.4477 9.08354 9.5625 8 9.5625V11.5625C7.99444 11.5625 7.98829 11.5612 7.98344 11.5591C7.97932 11.5573 7.97735 11.5556 7.97649 11.5548C7.97563 11.5539 7.9739 11.5519 7.97214 11.5478C7.97008 11.543 7.96875 11.5368 7.96875 11.5312H9.96875ZM8 9.5625C6.90605 9.5625 6 10.4271 6 11.5312H8C8 11.5369 7.99863 11.5442 7.99569 11.5511C7.99301 11.5573 7.99003 11.5609 7.98836 11.5625C7.98662 11.5642 7.98568 11.5645 7.98625 11.5642C7.98652 11.5641 7.98749 11.5637 7.98937 11.5634C7.99122 11.563 7.99465 11.5625 8 11.5625V9.5625ZM6 11.5312C6 12.6354 6.90605 13.5 8 13.5V11.5C7.99465 11.5 7.99122 11.4995 7.98937 11.4991C7.98749 11.4988 7.98652 11.4984 7.98625 11.4983C7.98568 11.498 7.98662 11.4983 7.98836 11.5C7.99003 11.5016 7.99301 11.5052 7.99569 11.5114C7.99863 11.5183 8 11.5256 8 11.5312H6Z" fill="#D80000" mask="url(#path-1-outside-1_1633_1705)"/>
</svg>

);

BlockErrorIcon.displayName = "Edit";

BlockErrorIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default BlockErrorIcon;