import React, { useState } from "react";
import "./Comments.css";

const CommentForm = ({ onAddComment }) => {
  const [commentText, setCommentText] = useState("");
  const handleButtonClick = () => {
    if (commentText.trim()) {
      onAddComment(commentText);
      setCommentText("");
    }
  };
  return (
    <div className="comment-box">
      <h3>Şərhlər</h3>
      <form>
        <textarea
          type="text"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Şərh bildir"
        />
      </form>
      <div className="commentBtn">
        <button type="button" onClick={handleButtonClick}>
          Göndər
        </button>
      </div>
    </div>
  );
};

export default CommentForm;
