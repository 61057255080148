import * as React from "react";
import PropTypes from "prop-types";

const PinIcon = ({
  size = 33, // Set the default size back to 33
  active = false, // default to false
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#3E87D9" />
        <stop offset="100%" stopColor="#5CB6F3" />
      </linearGradient>
    </defs>
    <g clipPath="url(#clip0_475_273)">
      <path
        d="M18.4372 9.12478L14.592 9.12289C11.9634 9.12163 10.6491 9.12096 10.0993 9.98207C9.54949 10.8431 10.1026 12.0359 11.2087 14.4215L11.9574 16.0361C12.2523 16.6721 12.3998 16.9901 12.4264 17.3235C12.4383 17.4731 12.4322 17.6236 12.408 17.7717C12.3543 18.1018 12.1814 18.4064 11.8355 19.0155C11.056 20.3884 10.6662 21.0749 10.8155 21.6297C10.8801 21.8696 11.0073 22.088 11.1841 22.2626C11.593 22.6664 12.3827 22.6668 13.9622 22.6676L15.7971 22.6687L15.7999 28.4738C15.8 28.8602 16.1135 29.1737 16.4999 29.1739C16.8863 29.174 17.1994 28.8609 17.1992 28.4745L17.1965 22.6692L19.0979 22.6701C20.6873 22.6709 21.4821 22.6713 21.8915 22.263C22.0641 22.091 22.1886 21.8769 22.2529 21.6419C22.4053 21.0841 22.0124 20.3929 21.2265 19.0104C20.8811 18.4026 20.7083 18.0987 20.6541 17.7692C20.6305 17.625 20.6237 17.4785 20.6342 17.3327C20.6581 16.9997 20.8025 16.6816 21.0912 16.0455L21.8373 14.4011C22.9152 12.0258 23.4542 10.8381 22.9029 9.98241C22.3514 9.12668 21.0466 9.12607 18.4372 9.12478Z"
        fill={active ? "url(#gradient)" : "#82959E"} // apply gradient if active
      />
    </g>
    <defs>
      <clipPath id="clip0_475_273">
        <rect width="22" height="22" fill="white" transform="translate(0.943604 16.5) rotate(-45)" />
      </clipPath>
    </defs>
  </svg>
);

PinIcon.displayName = "PinIcon"; // Updated display name

PinIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.bool, // new active prop
};

export default PinIcon;
