import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import errorImg from "../../images/circle-exclamation.png";
import {
  BlockIcon,
  CancelIcon,
  CompanyUsersIcon,
  DotIcon,
  LogOutIcon,
  NavLogOut,
  NavUserIcon,
  PlusIcon,
} from "../../assets/icons";
import CustomModal from "../../components/Modal/CustomModal";
import axiosClient from "../../api/axiosClient";
import Home from "../Home";
import DepartmentsPermissions from "./DepartmentsPermissions";

const DepartmentsUsersList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectUsers, setSelectUsers] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const controller = useRef();
  const { id } = useParams();
  const [activeCardId, setActiveCardId] = useState(null);
  const menuRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({});
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [dbClick, setDbClick] = useState(true);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState([]);
  const [userId, setUserid] = useState(null);
  const [statsOK, setStatsOk] = useState(false);
  const handleConfirmation = (answer) => {
    if (answer === "yes") {
      console.log("yes");
    } else {
      console.log("no");
    }
    const dialog = document.querySelector(".dialog-box");
    dialog.classList.add("closing");
    setTimeout(() => setShowDialog(false), 300);
  };

  const handleClick = () => {
    setShowDialog(true);
  };
  const isModalOpen = () => {
    setOpenModal(true);
  };
  const isUserModalOpen = () => {
    setOpenUserModal(true);
    closeModal();
  };

  const closeModal = () => {
    setOpenModal(false);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    setStep(1);
  };

  const closeUserModal = () => {
    setOpenUserModal(false);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
  };

  const handleSave = async () => {
    
    const payload = {
      userId: userId,
      videoGroupIds: selectedVideos,
      lessonGroupIds: selectedLessons,
    };
    try {
      const response = await axiosClient.post(
        "/CompanyGroups/GiveGroupPermissionsToUser",
        payload,
        { notification: false }
      );
    } catch (error) {
      console.error(error);
    }
  };
 

  const loadingProgress = false;

  const handleCheckboxChange = (index, type, userId) => {
    const updatedPermissions = [...permissions];
    updatedPermissions[index][type] = !updatedPermissions[index][type];
    setPermissions(updatedPermissions);

    postPermissionUsers(userId, updatedPermissions[index]);
  };
  const adjustMenuPosition = (cardRef) => {
    const cardRect = cardRef.getBoundingClientRect();
    const menuStyles = {};

    menuStyles.left = cardRect.left < 50 ? "30px" : "auto";
    menuStyles.right =
      cardRect.right > window.innerWidth - 140 ? "30px" : "auto";

    menuStyles.top =
      cardRect.bottom > window.innerHeight - 140 ? "-250px" : "30px";

    setMenuPosition(menuStyles);
  };

  const postPermissionUsers = async (userId, updatedPermission) => {
    const apiUrl = "/CompanyGroups/AddVideoLessonPermissionForGroupUsers";
    if (controller.current) {
      controller.current.abort();
    }

    controller.current = new AbortController();
    const signal = controller.current.signal;

    const { video, lesson } = updatedPermission;

    try {
      const response = await axiosClient.post(
        `/CompanyGroups/AddVideoLessonPermissionForGroupUsers?companyGroupId=${id}&isVideo=${video}&isLesson=${lesson}&userId=${userId}`,
        {},
        {
          signal,
          notification: false,
        }
      );
      console.log("Response from permission update:", response.data);
    } catch (error) {
      console.error("Error posting play/stop status:", error);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveCardId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleActive = (id, cardRef) => {
    setActiveCardId((prevId) => (prevId === id ? null : id));
    if (cardRef) {
      adjustMenuPosition(cardRef);
    }
  };
  const groupProfile = (id, event) => {
    navigate(`../details/${id}`);
  };
  const handleBlock = async (id) => {
    try {
      const response = await axiosClient.put(
        `/CompanyGroups/Block?userId=${id}`,
        {},
        { notification: false }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddGroupUser = async () => {
    try {
      const response = await axiosClient.post(
        `/CompanyGroups/AddUserToGroup?companyGroupId=${id}&userId=${selectUsers?.value}`,
        {},
        { notification: false }
      );
      if (response.status === 200) {
        setTableData((prevTableData) => {
          const updatedTableData = [...prevTableData, response?.data];
          setPermissions((prevPermissions) => [
            ...prevPermissions,
            { video: false, lesson: false },
          ]);
          return updatedTableData;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectUsers) {
      handleAddGroupUser();
    }
  }, [selectUsers]);
  const registerUser = async () => {
    const payload = {
      name: name,
      surname: surname,
      email: email,
      phoneNumber: phone,
      companyGroupId: id,
    };
    try {
      const response = await axiosClient.post("/Auth/Register", payload, {
        notification: false,
      });
      setUserid(response?.data?.id);
      
      if (response.status === 200) {
        setStatsOk(true);
        setStep(2);
      }
    } catch (error) {
      const errorData = error.response.data.errors;
      setErrorMessage(errorData);
    }
  };
  useEffect(()=>{
    console.log(userId)
  },[userId])
  const handleNext = async () => {
    if (step === 1) {
      await registerUser();
    } else {
      handleSave();
    }
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosClient.get(
          `/CompanyGroups/GetDataForGiveGroupPermissions`
        );
        setPermissionData(response.data);
      } catch (error) {
        console.error("Error in fetchProfileData:", error.message);
      }
    };
    fetchProfileData();
  }, [userId]);
  return (
    <div className="departments-container">
      <CustomModal isOpen={openUserModal} onClose={closeUserModal}>
        <Home
          dbClick={dbClick}
          closeModal={closeUserModal}
          companyİd={id}
          setSelectUsers={setSelectUsers}
        />
      </CustomModal>
      {showDialog && (
        <div className="overlay">
          <div className="dialog-box">
            <p>Are you sure?</p>
            <div className="button-group">
              <button
                onClick={() => handleConfirmation("yes")}
                className="yes-btn"
              >
                Bəli
              </button>
              <button
                onClick={() => handleConfirmation("no")}
                className="no-btn"
              >
                Xeyr
              </button>
            </div>
          </div>
        </div>
      )}

      {openModal && (
        <CustomModal isOpen={isModalOpen} onClose={closeModal} mid={true}>
          <div className="newCompanyHeader">
            <h2>Yeni istifadəçi</h2>
            <button onClick={closeModal}>
              <CancelIcon />
            </button>
          </div>
          <hr />
      
          {step === 1 && (
            <form className="departments-form-content">
              <div className="departments-input-row">
                <div className="departments-input-group">
                  <label>Ad</label>
                  <input
                    name="name"
                    className={`${errorMessage?.Name ? "errorMessage" : ""}`}
                    placeholder="Ad"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (errorMessage?.Name) {
                        setErrorMessage((prev) => ({
                          ...prev,
                          Name: null,
                        }));
                      }
                    }}
                  />
                  {errorMessage?.Name &&
                    Array.isArray(errorMessage.Name) &&
                    errorMessage.Name[0] && (
                      <div className="error-message">
                        <img src={errorImg} alt="Logo" />
                        <p>{errorMessage.Name[0]}</p>
                      </div>
                    )}
                </div>
                <div className="departments-input-group">
                  <label>Soyad</label>
                  <input
                    name="surname"
                    className={`${errorMessage?.Surname ? "errorMessage" : ""}`}
                    placeholder="Soyad"
                    value={surname}
                    onChange={(e) => {
                      setSurname(e.target.value);
                      if (errorMessage?.Surname) {
                        setErrorMessage((prev) => ({
                          ...prev,
                          Surname: null,
                        }));
                      }
                    }}
                  />
                  {errorMessage?.Surname &&
                    Array.isArray(errorMessage.Surname) &&
                    errorMessage.Surname[0] && (
                      <div className="error-message">
                        <img src={errorImg} alt="Logo" />
                        <p>{errorMessage.Surname[0]}</p>
                      </div>
                    )}
                </div>
              </div>
              <div className="departments-input-row">
                <div className="departments-input-group">
                  <label>Email</label>
                  <input
                    name="email"
                    className={`${errorMessage?.Email ? "errorMessage" : ""}`}
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (errorMessage?.Email) {
                        setErrorMessage((prev) => ({
                          ...prev,
                          Email: null,
                        }));
                      }
                    }}
                  />
                  {errorMessage?.Email &&
                    Array.isArray(errorMessage.Email) &&
                    errorMessage.Email[0] && (
                      <div className="error-message">
                        <img src={errorImg} alt="Logo" />
                        <p>{errorMessage.Email[0]}</p>
                      </div>
                    )}
                </div>
                <div className="departments-input-group">
                  <label>Telefon nömrəsi</label>
                  <input
                    name="phone"
                    className={`${
                      errorMessage?.PhoneNumber ? "errorMessage" : ""
                    }`}
                    placeholder="Telefon nömrəsi"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      if (errorMessage?.PhoneNumber) {
                        setErrorMessage((prev) => ({
                          ...prev,
                          PhoneNumber: null,
                        }));
                      }
                    }}
                  />
                  {errorMessage?.PhoneNumber &&
                    Array.isArray(errorMessage.PhoneNumber) &&
                    errorMessage.PhoneNumber[0] && (
                      <div className="error-message">
                        <img src={errorImg} alt="Logo" />
                        <p>{errorMessage.PhoneNumber[0]}</p>
                      </div>
                    )}
                </div>
              </div>
            </form>
          )}

          {step === 2 && (
            <DepartmentsPermissions
              data={permissionData}
              setSelectedVideos={setSelectedVideos}
              selectedVideos={selectedVideos}
              setSelectedLessons={setSelectedLessons}
              selectedLessons={selectedLessons}
            />
          )}

          <div className="permissionsBtns">
            <button onClick={closeModal}>Ləğv et</button>
            <button type="button" onClick={handleNext}>
              {step === 1 ? "Yadda saxla" : "Icazə ver"}
            </button>
          </div>
        </CustomModal>
      )}

      <div className="departments-header">
        <h2>İstifadəçilər (Maliyyə şöbəsi)</h2>
        <button className="newUserDepartment" onClick={isModalOpen}>
          <span>
            <PlusIcon />
          </span>
          <span>Yeni istifadəçi</span>
        </button>
      </div>
      <table className="DepartmentsTable-table">
        <thead className="DepartmentsTable-thead">
          <tr>
            <th className="DepartmentsTable-th">№</th>
            <th className="DepartmentsTable-th">Ad </th>
            <th className="DepartmentsTable-th">Soyad</th>
            <th className="DepartmentsTable-th">Email</th>
            <th className="DepartmentsTable-th">Telefon</th>
            <th className="DepartmentsTable-th">İcazə (video)</th>
            <th className="DepartmentsTable-th">İcazə (Dərslik)</th>
            <th className="DepartmentsTable-th"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <tr
                key={index}
                className={
                  index % 2 === 0
                    ? "DepartmentsTable-evenRow"
                    : "DepartmentsTable-oddRow"
                }
              >
                <td className="DepartmentsTable-td">{index + 1}</td>
                <td className="DepartmentsTable-td">
                  {row?.name}{" "}
                  {row.isAdmin ? (
                    <span className="DepartmentsTable-isAdmin">(admin)</span>
                  ) : null}
                </td>
                <td className="DepartmentsTable-td">{row?.surname}</td>
                <td className="DepartmentsTable-td">{row?.email}</td>
                <td className="DepartmentsTable-td">{row?.phone}</td>
                <td className="DepartmentsTable-td">
                  <input
                    type="checkbox"
                    checked={permissions[index]?.video || false}
                    onChange={() =>
                      handleCheckboxChange(index, "video", row?.id)
                    }
                  />
                </td>
                <td className="DepartmentsTable-td">
                  <input
                    type="checkbox"
                    checked={permissions[index]?.lesson || false}
                    onChange={() =>
                      handleCheckboxChange(index, "lesson", row?.id)
                    }
                  />
                </td>
                <td>
                  <div className="MoreContextCompany moreContextCompanyForUSers">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleActive(row.id, e.currentTarget.closest(".card"));
                      }}
                    >
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    {activeCardId === row?.id && (
                      <ul ref={menuRef} style={{ marginRight: "100%" }}>
                        <li onClick={(event) => groupProfile(row.id, event)}>
                          <NavUserIcon />
                          <p>İstifadəçilər</p>
                        </li>
                        <li onClick={() => handleBlock(row.id)}>
                          <BlockIcon />
                          <p>Block</p>
                        </li>
                        <li onClick={() => handleClick(row.id)}>
                          <LogOutIcon />
                          <p>Qrupdan Çıxar</p>
                        </li>
                      </ul>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : !loadingProgress ? (
            <tr>
              <td colSpan="7" className="no-data">
                No data
              </td>
            </tr>
          ) : null}

          {loadingProgress &&
            [1, 2, 3].map((_, index) => (
              <tr key={index}>
                <td className="DepartmentsTable-td">
                  <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                </td>
                <td className="DepartmentsTable-td">
                  <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                </td>
                <td className="DepartmentsTable-td">
                  <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DepartmentsUsersList;
