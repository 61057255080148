import React, { useEffect, useRef } from 'react';

const ParticleCanvas = () => {
  const canvasRef = useRef(null);
  const particles = useRef([]);
  const mousePosition = useRef({ x: null, y: null });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight * 0.4;

    for (let i = 0; i < 50; i++) {
      particles.current.push({
        x: Math.random() * (canvas.width / 2) + canvas.width / 2,
        y: Math.random() * canvas.height,
        size: Math.random() * 2 + 2,
        speedX: Math.random() * 2 - 1,
        speedY: Math.random() * 2 - 1,
      });
    }

    const updateMousePosition = (event) => {
      mousePosition.current.x = event.clientX;
      mousePosition.current.y = event.clientY;
    };

    window.addEventListener('mousemove', updateMousePosition);

    const connectParticles = () => {
      particles.current.forEach((particle, i) => {
        for (let j = i + 1; j < particles.current.length; j++) {
          const otherParticle = particles.current[j];
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            context.beginPath();
            context.strokeStyle = 'rgba(255, 255, 255, 0.1)';
            context.lineWidth = 1;
            context.moveTo(particle.x, particle.y);
            context.lineTo(otherParticle.x, otherParticle.y);
            context.stroke();
            context.closePath();
          }
        }

        if (mousePosition.current.x && mousePosition.current.y) {
          const dx = particle.x - mousePosition.current.x;
          const dy = particle.y - mousePosition.current.y;
          const mouseDistance = Math.sqrt(dx * dx + dy * dy);

          if (mouseDistance < 150) {
            context.beginPath();
            context.strokeStyle = 'rgba(255, 255, 255, 0.2)';
            context.lineWidth = 1;
            context.moveTo(particle.x, particle.y);
            context.lineTo(mousePosition.current.x, mousePosition.current.y);
            context.stroke();
            context.closePath();
          }
        }
      });
    };

    const animate = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      particles.current.forEach((particle) => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;

        // Attraction to mouse position
        if (mousePosition.current.x && mousePosition.current.y) {
          const dx = mousePosition.current.x - particle.x;
          const dy = mousePosition.current.y - particle.y;
          const distanceToMouse = Math.sqrt(dx * dx + dy * dy);

          if (distanceToMouse < 150) {
            particle.x += dx * 0.02;
            particle.y += dy * 0.02;
          }
        }

        // Boundary conditions
        if (particle.x < canvas.width / 2) particle.speedX *= -1; // Prevent moving past left half
        if (particle.x > canvas.width) particle.speedX *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;

        context.beginPath();
        context.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        context.fillStyle = '#B6E9FFB0';
        context.fill();
      });

      connectParticles();

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      particles.current = [];
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        background: 'linear-gradient(220.37deg, #4593E1 0.9%, #3E87D9 99.1%)',
        width: '100%',
        height: '40vh'
      }}
    />
  );
};

export default ParticleCanvas;
