import React, { useEffect, useRef, useState } from "react";
import "./Dropdown.css";
import { ArrowDownIcon } from "../../assets/icons";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"; 

const Dropdown = ({
  title,
  items,
  isOpen,
  onClick,
  onSelect,
  minmax,
  setMinValue,
  setMaxValue,
  rating, // Add rating prop here
}) => {
  const dropdownRef = useRef(null);
  const initialMinValue = 0; // Set minimum default value to 0
  const initialMaxValue = rating ? 5 : 1000; // Set max value based on rating

  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
  const [selectedItem, setSelectedItem] = useState(title);
  const [minInputValue, setMinInputValue] = useState(initialMinValue);
  const [maxInputValue, setMaxInputValue] = useState(initialMaxValue); // Start at max value

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        onClick(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick]);

  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    const displayContent = typeof item === "object" ? item.name : item;
    setSelectedItem(displayContent);
    setIsDropdownOpen(false);
    
    // Only reset min/max values when an item is selected
    setMinInputValue(0); // Ensure minimum is always 0
    if (rating) {
      setMaxInputValue(5);
      if (setMinValue) setMinValue(0);
      if (setMaxValue) setMaxValue(5);
    } else {
      setMaxInputValue(1000);
      if (setMinValue) setMinValue(0);
      if (setMaxValue) setMaxValue(1000);
    }

    if (onSelect && typeof onSelect === "function") {
      onSelect(item);
    }
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const handleMinChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^[0-9]*$/.test(value)) {
      setMinInputValue(value);
      if (setMinValue) setMinValue(value);
    }
  };

  const handleMaxChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^[0-9]*$/.test(value)) {
      setMaxInputValue(value);
      if (setMaxValue) setMaxValue(value);
    }
  };

  const handleSliderChange = (values) => {
    setMinInputValue(values[0]);
    setMaxInputValue(values[1]);
    if (setMinValue) setMinValue(values[0]);
    if (setMaxValue) setMaxValue(values[1]);
  };

  const sliderColor = "rgba(62, 135, 217, 1)";
  const sliderBorderColor = "rgba(224, 224, 224, 1)";

  const renderContent = () => {
    if (minmax && minmax.length === 2) {
      return (
        <>
          <div className="minmaxValues" onClick={handleDropdownClick}>
            <input
              type="number"
              placeholder="min."
              value={minInputValue}
              onChange={handleMinChange}
            />
            <input
              type="number"
              placeholder="maks."
              value={maxInputValue}
              onChange={handleMaxChange}
            />
          </div>
          <div style={{marginTop:"6px"}}>
          <Slider
            range
            min={0} 
            step={rating&& 0.5} 
            max={rating ? 5 : 1000} 
            value={[Number(minInputValue), Number(maxInputValue)]}
            onChange={handleSliderChange}
            allowCross={false}
            trackStyle={[{ backgroundColor: sliderColor }]}
            handleStyle={[
              { backgroundColor: sliderColor, borderColor: sliderBorderColor },
              { backgroundColor: sliderColor, borderColor: sliderBorderColor },
            ]}
            railStyle={{ backgroundColor: sliderBorderColor }}
          /></div>
        </>
      );
    }
    return items.map((item, index) => {
      const displayContent = typeof item === "object" ? item.name : item;
      return (
        <li key={index} onClick={() => handleItemClick(item)}>
          {displayContent}
        </li>
      );
    });
  };

  return (
    <div className="dropdown" ref={dropdownRef} onClick={handleDropdownClick}>
      <button className="dropdown-button" onClick={handleButtonClick}>
        <p>{selectedItem}</p>
        <ArrowDownIcon />
      </button>
      <ul className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
        {renderContent()}
      </ul>
    </div>
  );
};

export default Dropdown;
