import React, { useEffect, useRef, useState } from "react";
import { ArrowDownIcon, CancelIcon } from "../../assets/icons";
import "./Departments.css";
import axiosClient from "../../api/axiosClient";
import DepartmentsPermissions from "./DepartmentsPermissions";

const DepartmentNewCompany = ({ closeModal, id, setDepartments }) => {
  const [createData, setCreateData] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const iconRef = useRef(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axiosClient.get(
          `GroupIcons/GetAll`
        );
        if (response.status === 200) {
          setCreateData(response.data);
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchIcons();
  }, []);
  const handleIconSelect = (id) => {
    setSelectedIcon(id);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleOutsideClick = (event) => {
    const dropdown = document.querySelector(".dropdown-menu");
    if (
      dropdown &&
      !dropdown.contains(event.target) &&
      !event.target.closest(".custom-select")
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleCreate = async () => {
    try {
      const payload = {
        groupIconId: selectedIcon,
        name: departmentName,
        parentId: id,
      };
      const response = await axiosClient.post("/CompanyGroups/CreateCompanyGroup", payload);
      if (response) {
        setDepartments(response.data);
        closeModal();
      }
    } catch (error) {
      console.error("Error during POST request:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  return (
    <div className="newCompanyModal">
      <div className="newCompanyHeader">
        <h2>Yeni qrup</h2>
        <button onClick={closeModal}>
          <CancelIcon />
        </button>
      </div>
      <hr />
      <div className="department-input-row">
        <div className="department-input-group">
          <label>İcon təyin edin</label>
          <div className="custom-select">
            <div className="selected-icon" onClick={toggleDropdown}>
              {selectedIcon ? (
                <div>

                  <img
                    src={
                      createData?.find((icon) => icon.id === selectedIcon)
                        ?.icon
                    }
                    alt="Selected Icon"
                  />
                   <span>
                    <ArrowDownIcon />
                  </span>
                </div>
              ) : (
                <div>
                  <span>İconlar</span>
                  <span>
                    <ArrowDownIcon />
                  </span>
                </div>
              )}
            </div>
            {dropdownOpen && (
              <div className="dropdown-menu3" ref={iconRef}>
                {createData?.map((icon) => (
                  <div
                    key={icon.id}
                    className="dropdown-icon"
                    onClick={() => handleIconSelect(icon.id)}
                  >
                    <img src={icon.icon} alt="Icon" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="department-input-group2">
          <label>Qrupun adı</label>
          <input
            type="text"
            name="departmentName"
            placeholder="Qrupun adı"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
          />
        </div>
      </div>
      <div className="permissionsBtns">
        <button onClick={closeModal}>Ləğv et</button>
        <button onClick={handleCreate}>Yarat</button>
      </div>
    </div>
  );
};

export default DepartmentNewCompany;
