import * as React from "react";
import PropTypes from "prop-types";

const NavLessonIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 10.0938C12.25 10.3125 12.1406 10.5039 12.0039 10.6133C11.8672 11.0234 11.8672 12.2266 12.0039 12.6641C12.1406 12.7734 12.25 12.9648 12.25 13.1562V13.5938C12.25 13.9766 11.9492 14.25 11.5938 14.25H2.625C1.17578 14.25 0 13.0742 0 11.625V2.875C0 1.42578 1.17578 0.25 2.625 0.25H11.5938C11.9492 0.25 12.25 0.550781 12.25 0.90625V10.0938ZM3.5 3.91406V4.46094C3.5 4.57031 3.55469 4.625 3.66406 4.625H9.46094C9.54297 4.625 9.625 4.57031 9.625 4.46094V3.91406C9.625 3.83203 9.54297 3.75 9.46094 3.75H3.66406C3.55469 3.75 3.5 3.83203 3.5 3.91406ZM3.5 5.66406V6.21094C3.5 6.32031 3.55469 6.375 3.66406 6.375H9.46094C9.54297 6.375 9.625 6.32031 9.625 6.21094V5.66406C9.625 5.58203 9.54297 5.5 9.46094 5.5H3.66406C3.55469 5.5 3.5 5.58203 3.5 5.66406ZM10.418 12.5C10.3633 12.0352 10.3633 11.2422 10.418 10.75H2.625C2.13281 10.75 1.75 11.1602 1.75 11.625C1.75 12.1172 2.13281 12.5 2.625 12.5H10.418Z"
      fill="#919DA8"
    />
  </svg>

);

NavLessonIcon.displayName = "Check";

NavLessonIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavLessonIcon;
