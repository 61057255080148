import * as React from "react";
import PropTypes from "prop-types";

const NavCompanyIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6406 6.26172L13.6719 9.65234C13.3711 10.1719 12.7969 10.5 12.1406 10.5H1.23047C0.710938 10.5 0.382812 9.95312 0.65625 9.51562L2.625 6.125C2.92578 5.60547 3.5 5.25 4.15625 5.25H15.0664C15.5859 5.25 15.9141 5.82422 15.6406 6.26172ZM4.15625 4.375C3.19922 4.375 2.35156 4.89453 1.88672 5.6875L0 8.94141V1.3125C0 0.601562 0.574219 0 1.3125 0H5.6875L7.4375 1.75H11.8125C12.5234 1.75 13.125 2.35156 13.125 3.0625V4.375H4.15625Z"  fill="#919DA8"/>
    </svg>
    

);

NavCompanyIcon.displayName = "Check";

NavCompanyIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavCompanyIcon;
