

import * as React from "react";
import PropTypes from "prop-types";

const ClockIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.71 15.1798L12.61 13.3298C12.07 13.0098 11.63 12.2398 11.63 11.6098V7.50977" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
);

ClockIcon.displayName = "Edit";

ClockIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default ClockIcon;