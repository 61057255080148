import React, { useState } from "react";
import Avatar from "../../components/Avatar/Avatar";

const AllPermissions = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "Rahim Abbasov",
      checkboxValues: {
        statistics: true,
        videos: false,
        tutorials: true,
        addUser: false,
        editUser: false,
        blockUser: false,
      },
    },
    {
      id: 2,
      name: "Ali Veli",
      checkboxValues: {
        statistics: false,
        videos: true,
        tutorials: false,
        addUser: false,
        editUser: false,
        blockUser: false,
      },
    },
    {
      id: 3,
      name: "Zeynep Demir",
      checkboxValues: {
        statistics: false,
        videos: false,
        tutorials: true,
        addUser: false,
        editUser: true,
        blockUser: false,
      },
    },
    {
      id: 4,
      name: "Mehmet Yılmaz",
      checkboxValues: {
        statistics: true,
        videos: false,
        tutorials: false,
        addUser: false,
        editUser: false,
        blockUser: false,
      },
    },
    {
      id: 5,
      name: "Fatma Can",
      checkboxValues: {
        statistics: false,
        videos: true,
        tutorials: false,
        addUser: false,
        editUser: false,
        blockUser: false,
      },
    },
  ]);

  const handleCheckboxChange = (event, userId) => {
    const { name, checked } = event.target;
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              checkboxValues: { ...user.checkboxValues, [name]: checked },
            }
          : user
      )
    );
  };

  const handleSave = () => {
    const selectedPermissions = users
      .map((user) => ({
        id: user.id,
        checkboxValues: { ...user.checkboxValues },
      }))
      .filter((user) =>
        Object.values(user.checkboxValues).some((value) => value)
      );

    console.log("Selected Users and their Permissions:", selectedPermissions);
  };

  return (
    <div className="permissions-container">
      <div className="departments-header">
        <h2>Səlahiyyətlər</h2>
      </div>
      <div className="unique-custom-table-main">
      <table className="unique-custom-table">
        <thead>
          <tr>
            <th rowSpan={2}>№</th>
            <th rowSpan={2}>İstifadəçi</th>
            <th rowSpan={2} className="turnText">
              Statistika
            </th>
            <th rowSpan={2} className="turnText">
              Videolar
            </th>
            <th rowSpan={2} className="turnText">
              Dərsliklər
            </th>
            <th colSpan={3}>İstifadəçilər</th>
          </tr>
          <tr>
            <th className="turnText" colSpan={1}>
              Əlavə et
            </th>
            <th className="turnText" colSpan={1}>
              Dəyiş
            </th>
            <th className="turnText" colSpan={1}>
              Blokla
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td className="unique-user-cell">
                <div className="centerNames">
                  <span>
                    <Avatar image={null} name={user.name.charAt(0)} />
                  </span>
                  <span>{user.name}</span>
                </div>
              </td>
              {Object.keys(user.checkboxValues).map((key) => (
                <td key={key}>
                  <input
                    type="checkbox"
                    name={key}
                    checked={user.checkboxValues[key]}
                    onChange={(e) => handleCheckboxChange(e, user.id)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table></div>
      <div class="permissionsBtns">
        <button type="button" onClick={handleSave}>
          Təsdiq et
        </button>
      </div>
    </div>
  );
};

export default AllPermissions;
