
import * as React from "react";
import PropTypes from "prop-types";

const CallIcons = ({
  size = 24,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 17.42V20.42C22.5011 20.6985 22.4441 20.9741 22.3325 21.2293C22.2209 21.4845 22.0573 21.7136 21.8521 21.9018C21.6468 22.0901 21.4046 22.2335 21.1407 22.3227C20.8769 22.4119 20.5974 22.445 20.32 22.42C17.2428 22.0856 14.287 21.0341 11.69 19.35C9.27382 17.8146 7.22533 15.7661 5.68999 13.35C3.99997 10.7412 2.94824 7.77097 2.61999 4.67997C2.595 4.40344 2.62787 4.12474 2.71649 3.8616C2.80512 3.59846 2.94756 3.35666 3.13476 3.1516C3.32196 2.94653 3.5498 2.78268 3.80379 2.6705C4.05777 2.55831 4.33233 2.50024 4.60999 2.49997H7.60999C8.0953 2.4952 8.56579 2.66705 8.93376 2.98351C9.30173 3.29996 9.54207 3.73942 9.60999 4.21997C9.73662 5.18004 9.97144 6.1227 10.31 7.02997C10.4445 7.3879 10.4737 7.77689 10.3939 8.15086C10.3141 8.52482 10.1289 8.86809 9.85999 9.13998L8.58999 10.41C10.0135 12.9135 12.0864 14.9864 14.59 16.41L15.86 15.14C16.1319 14.8711 16.4751 14.6858 16.8491 14.6061C17.2231 14.5263 17.6121 14.5554 17.97 14.69C18.8773 15.0285 19.8199 15.2634 20.78 15.39C21.2658 15.4585 21.7094 15.7032 22.0265 16.0775C22.3437 16.4518 22.5122 16.9296 22.5 17.42Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg> 
    
);

CallIcons.displayName = "Check";

CallIcons.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CallIcons;
