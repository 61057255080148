import React, { useState, useEffect } from "react";
import "./Landing.css";
import { LandingSiescoIcon } from "../../assets/icons";
import LandingIntroduction from "./LandingIntroduction";
import LandingVideo from "./LandingVideo";
import { useNavigate } from "react-router-dom";
import LandingInfo from "./LandingInfo";

const Landing = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 868);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 868);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="LandingMain">
      <div className="LandingMainContainer">
        <div className="landingHeader">
          <LandingSiescoIcon
            {...(isSmallScreen ? { height: 39, width: 74 } : {})}
          />
          <div className="landingBtns">
            <button onClick={() => navigate("../login")}>Daxil ol</button>
            <button onClick={() => navigate("../notificationForm")}>
              Müraciət et
            </button>
          </div>
        </div>
        <LandingIntroduction />
        <LandingVideo />
        <LandingInfo />
      </div>
    </div>
  );
};

export default Landing;
