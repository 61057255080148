import * as React from "react";
import PropTypes from "prop-types";

const OpenEyeIcon = ({
  size = 18,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
  </svg>
);

OpenEyeIcon.displayName = "WinkingFaceWithOpenEyes";

OpenEyeIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default OpenEyeIcon;
