import * as React from "react";
import PropTypes from "prop-types";

const NavLogOut = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.02734 11.4453L7.42578 12.0469C7.15234 12.293 6.74219 12.293 6.49609 12.0469L1.19141 6.71484C0.917969 6.46875 0.917969 6.05859 1.19141 5.8125L6.49609 0.480469C6.74219 0.234375 7.17969 0.234375 7.42578 0.480469L8.02734 1.08203C8.30078 1.35547 8.27344 1.76562 8.02734 2.03906L4.71875 5.15625H12.5938C12.9492 5.15625 13.25 5.45703 13.25 5.8125V6.6875C13.25 7.07031 12.9492 7.34375 12.5938 7.34375H4.71875L8.02734 10.4883C8.27344 10.7617 8.30078 11.1719 8.02734 11.4453Z" fill="#919DA8"/>
    </svg>
    

);

NavLogOut.displayName = "Check";

NavLogOut.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavLogOut;
