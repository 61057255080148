import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../UserProfileDetails.css';
import axiosClient from '../../../api/axiosClient';

const DetailsTable = ({ id, tabNumber }) => {
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [tableData, setTableData] = useState([]);
  const scrollableDivRef = useRef(null);
  const [skip, setSkip] = useState(0);
  const [take] = useState(10); 
  const [hasMoreData, setHasMoreData] = useState(true); 
  const loadingMoreRef = useRef(false);
  const fetchProgressData = async () => {
    try {
      setLoadingProgress(true);
      const response = await axiosClient.get(
        `/User/UserDetailStatistic?userId=${id}&tab=${tabNumber}&skip=${skip}&take=${take}`
      );

      if (response.status === 200) {
        const newData = response.data.table;
        setTableData((prevData) => [...prevData, ...newData]);
        if (newData.length < take) { 
          setHasMoreData(false);
        }
      } else {
        throw new Error('Error fetching progress data');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProgress(false);
      loadingMoreRef.current = false; 
    }
  };

  useEffect(() => {
    fetchProgressData();
  }, [id, tabNumber, skip]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleScroll = () => {
    if (
      scrollableDivRef.current &&
      !loadingProgress &&
      hasMoreData &&
      scrollableDivRef.current.scrollTop + scrollableDivRef.current.clientHeight >=
        scrollableDivRef.current.scrollHeight - 10
    ) {
      if (!loadingMoreRef.current) {
        loadingMoreRef.current = true;
        setSkip((prevSkip) => prevSkip + 1); 
      }
    }
  };

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener('scroll', handleScroll);
      return () => {
        if (scrollableDivRef.current) {
          scrollableDivRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [loadingProgress, hasMoreData]);
  useEffect(() => {
    setTableData([]);
    setSkip(0);
    setHasMoreData(true);
  }, [tabNumber]);
  return (
    <div className="detailsTable-container "  ref={scrollableDivRef}>
      <table className="detailsTable-table">
        <thead className="detailsTable-thead">
          <tr>
            <th className="detailsTable-th">Tarix</th>
            <th className="detailsTable-th">Video/ Dərslik</th>
            <th className="detailsTable-th">Ümumi baxış vaxtı</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? 'detailsTable-evenRow' : 'detailsTable-oddRow'}
              >
                <td className="detailsTable-td">{formatDate(row.date)}</td>
                <td className="detailsTable-td">
                  {row?.video?.title || row?.lesson?.title}
                </td>
                <td className="detailsTable-td">{row?.duration?.substring(0,8)}</td>
              </tr>
            ))
          ) : !loadingProgress ? (
            <tr>
              <td colSpan="3" className="no-data">No data</td>
            </tr>
          ) : null}

          {loadingProgress && (
            [1, 2, 3].map((_, index) => (
              <tr key={index}>
                <td className="detailsTable-td">
                  <Skeleton width="100%" style={{ padding: '6px 12px' }} />
                </td>
                <td className="detailsTable-td">
                  <Skeleton width="100%" style={{ padding: '6px 12px' }} />
                </td>
                <td className="detailsTable-td">
                  <Skeleton width="100%" style={{ padding: '6px 12px' }} />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DetailsTable;
