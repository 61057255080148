import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../Avatar/Avatar";
import { ArrowDownIcon, EditIcon, MoreIcon, TrashIcon } from "../../assets/icons";
import "./Comments.css";
import { AuthContext } from "../../context/AuthContext";
import axiosClient from "../../api/axiosClient";
import VideoDateTime from "../../utils/VideoDateTime";

const ReplyItem = ({
  comment,fetchReplies,
  onDelete,
  onReply,
  parentId,
  showReplyCommits,
  setShowReplyCommits,
  setTagName,
  setRefreId,
  refreId,
  tagName,
  activeReplyFormId,
  setActiveReplyFormId,
  activeEditId, setReplyCount,replyCount,
  setActiveEditId, 
}) => {
  const [replyText, setReplyText] = useState("");
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [editText, setEditText] = useState(comment?.content);
  const { auth } = useContext(AuthContext);
  const menuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [contextMenuClose, setContextMenuClose] = useState(true);
  const isEditing = activeEditId === comment.id;
  const [edited, setEdited] = useState(comment.isEdited);
  const handleReply = () => {
    if (replyText.trim()) {
      onReply(parentId, replyText, comment?.user?.id)
        .then((response) => {
          const newReply = {
            ...response,
            user: { id: comment?.user?.id, fullName: auth?.userFullName },
            refUser: tagName,
          };
          setShowReplyCommits((prev) => [...prev, newReply]);
          setReplyText("");
          setShowReplyForm(false);
          setActiveReplyFormId(null);
        })
        .catch((error) => {
          console.error("Error adding reply:", error);
        });
    } else {
      alert("Reply cannot be empty!");
    }
  };
  
  

  const formatName = (name) => {
    return name.replace(/\s+/g, "");
  };

  const handleOpenMenu = () => {
    setActiveReplyFormId(null);
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleEdit = () => {
    setActiveEditId(comment.id); 
    setEditText(comment?.content);
    setActiveReplyFormId(null);
    setContextMenuClose(false);
    setOpenMenu(false);
  };

  const handleDelete = async (e) => {
    setActiveReplyFormId(null);
    e.preventDefault();
    try {
      const response = await axiosClient.delete(
        `/Comments/Delete?commentId=${comment.id}`
      );
      if (response.status === 200) {
        onDelete(comment.id);
        setReplyCount(replyCount-1)
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleSaveEdit = async () => {
    setActiveReplyFormId(null);
    setContextMenuClose(true);
    setOpenMenu(false);
    comment.content = editText;
    setActiveEditId(null); 
    try {
      const response = await axiosClient.put("/Comments/Update", {
        commentId: comment.id,
        newContent: editText,
      });
      if (response.status === 200) {
        setEdited(true)
      }
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  const handleCancelEdit = () => {
    setActiveEditId(null); // Cancel editing
    setActiveReplyFormId(null);
    setContextMenuClose(true);
    setOpenMenu(false);
  };

  return (
    <div className="comment-item">
      <div className="comment-component-container">
        <div className="comment-component-left">
          <Avatar image={null} name={comment?.user?.fullName} />
          <div className="comment-component-body">
            <div className="comment-component-username">
              <h3>{comment?.user?.fullName}</h3>
            </div>

            {isEditing ? (
              <div className="edit-form">
                <span>{tagName ? `@${formatName(tagName)}` : null}</span>
                <input
                  type="text"
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  placeholder="Edit your comment..."
                />
              </div>
            ) : (
              <div className="comment-component-text">
                 <p> <span>{comment?.refUser ? `@${comment?.refUser}` : null}</span>{' '}
              {comment?.content}</p>
              </div>
            )}
            <div    className={`${
                !isEditing
                  ? "comment-component-time"
                  : "comment-component-time-edit"
              }`}>
            <p>
                {VideoDateTime(comment.createDate)}{" "}
                <span className="editedComment">{edited ? "(redaktə edilib)" : null}</span>
              </p>{" "}
              {!isEditing && (
                <button
                  onClick={() => {
                    if (activeReplyFormId === comment.id) {
                      setActiveReplyFormId(null);
                      setShowReplyForm(false);
                    } else {
                      setTagName(comment?.user.fullName);
                      setShowReplyForm(true);
                      setActiveReplyFormId(comment.id);
                      setRefreId(comment?.user?.id);
                    }
                  }}
                >
                  Reply
                </button>
              )}
               {isEditing && (
                <div className="commentEditBtns">
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={handleCancelEdit}>Cancel</button>
                </div>
              )}
            </div>
          </div>
        </div>
        {contextMenuClose && comment.isMe ? (
          <div className="MoreContextComment" ref={menuRef}>
            <button onClick={handleOpenMenu}>
              <MoreIcon />
            </button>
            {openMenu && (
              <ul>
                <li onClick={handleEdit}>
                  <EditIcon size={16} />
                  <p>Edit</p>
                </li>
                <li onClick={handleDelete}>
                  <TrashIcon size={16} />
                  <p>Delete</p>
                </li>
              </ul>
            )}
          </div>
        ) : null}
      </div>

      {activeReplyFormId === comment.id && !isEditing && (
           <>  <div className="reply-form">
          <span>@{formatName(tagName)}</span>
          <input
            type="text"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder="Write a reply..."
          />
          
        </div>
     <div className="commentEditBtns2">
        <button onClick={handleReply}>Send</button>
      </div>
        </>
      )}
    </div>
  );
};


export default ReplyItem;
