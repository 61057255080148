

import * as React from "react";
import PropTypes from "prop-types";

const StarIcon = ({
  size = 24,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.93 3.51014L15.69 7.03014C15.93 7.52014 16.57 7.99014 17.11 8.08014L20.3 8.61014C22.34 8.95014 22.82 10.4301 21.35 11.8901L18.87 14.3701C18.45 14.7901 18.22 15.6001 18.35 16.1801L19.06 19.2501C19.62 21.6801 18.33 22.6201 16.18 21.3501L13.19 19.5801C12.65 19.2601 11.76 19.2601 11.21 19.5801L8.21998 21.3501C6.07998 22.6201 4.77998 21.6701 5.33998 19.2501L6.04998 16.1801C6.17998 15.6001 5.94998 14.7901 5.52998 14.3701L3.04998 11.8901C1.58998 10.4301 2.05998 8.95014 4.09998 8.61014L7.28998 8.08014C7.81998 7.99014 8.45998 7.52014 8.69998 7.03014L10.46 3.51014C11.42 1.60014 12.98 1.60014 13.93 3.51014Z" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
);

StarIcon.displayName = "Check";

StarIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default StarIcon;
