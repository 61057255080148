import React from "react";
// import GroupPhotoDefault from "../../assets/png/GroupPhoto.png";
import "./Avatar.css"
export default function Avatar(props) {
  const profilText = props?.name?.charAt(0)?.toUpperCase();

  return (
    <div className={`avatarPhotoMain ${props?.className}`}>
      <div className="userPhotoAvatar">
        {props?.image ? (
          <img
            src={props.image}
            title={props?.name}
            // onError={e => {
            //   e.target.src = defaultImg;
            // }}
            alt="user avatar"
          />
        ) : (
          <span className="textProfil">{profilText}</span>
        )}
      </div>
    </div>
  );
}
