import React, { useContext, useEffect, useState } from "react";
import { Treebeard, decorators } from "react-treebeard";
import { FaFileContract } from "react-icons/fa";
import { MdFolder, MdFolderOpen } from "react-icons/md";
import axiosClient from "../api/axiosClient";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import doc from "../images/document.svg";
import cover from "../images/siesco video cover 2-01.png";
import StarRatings from "react-star-ratings";

const mapData = data => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    lessons: item.lessons,
    children: [
      ...mapData(item.children),
      ...item.lessons.map(lesson => ({
        id: lesson.id,
        name: lesson.title,
        lessonFile: lesson.lessonFile,
        type: "lesson",
        description: lesson.description,
        rating: lesson.rating,
        fileName: lesson.fileName,
        fileUrl: lesson.fileUrl
      })),
    ],
  }));
};

function Documents() {
  const [cursor, setCursor] = useState(null);
  const [data, setData] = useState([]);
  const [curDocument, setCurDocument] = useState(null);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const lessonData = location.state?.lessonData;
  const [rating, setRating] = useState(0);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lessonData) {
      setCurDocument(lessonData);
      setRating(lessonData.rating || 0);
      setSelectedVideoId(lessonData.id);
      if (lessonData?.id) {
        postViewCount(lessonData?.id);
        LessonViewCount(lessonData?.id);
      }
    } else {
      setCurDocument(null);
    }
  }, [lessonData]);

  const customDecorators = {
    ...decorators,
    Header: ({ style, node }) => {
      const iconType = node.children ? (
        node.toggled ? (
          <MdFolderOpen />
        ) : (
          <MdFolder />
        )
      ) : node.type === "lesson" ? (
        <FaFileContract />
      ) : null;
      return (
        <div style={style.base}>
          <div style={style.title} className="videoNames">
            {iconType} {node.name}
          </div>
        </div>
      );
    },
  };

  const onToggle = (node, toggled) => {
    if (node.type === "lesson") {
      setCurDocument(node);
      setRating(node.rating);
      setSelectedVideoId(node.id);
      postViewCount(node.id);
      LessonViewCount(node.id);
    }

    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setData(Object.assign({}, data));
  };

  useEffect(() => {
    axiosClient
      .get("/GroupLessons/GetAllWithPermission")
      .then(res => {
        setData({
          name: "Bölmələr",
          toggled: true,
          children: mapData(res.data),
        });
      })
      .catch(err => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const ratingChanged = newRating => {
    setLoading(true);
    setRating(newRating);
    if (selectedVideoId) {
      fetchProfileDataStats(selectedVideoId, newRating);
    }
  };
  const fetchProfileDataStats = async (videoId, rateCount) => {
    try {
      setLoading(true);
  
      const response = await axiosClient.post(
        "/UserVIdeoAndLessonRating/CreateLessonRating",
        {
          lessonId: videoId,
          rateCount: rateCount,
        },
        {
          notification: false, 
        }
      );
  
      setRating(response.data); 
    } catch (error) {
      console.error("Error sending rating:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const postViewCount = async (id) => {
    try {
      await axiosClient.post(
        `/Lessons/IncrementCount`,
        {},
        {
          params: { id }, 
          notification: false, 
        }
      );
    } catch (error) {
      console.error("Error posting play/stop status:", error);
    }
  };
  
  const LessonViewCount = async (id) => {
    try {
      await axiosClient.post(
        `/UserVideoAndLesson/CreateLessonView`,
        { lessonId: id }, 
        {
          notification: false, 
        }
      );
    } catch (error) {
      console.error("Error posting lesson view count:", error);
    }
  };
const handleDownload = async (id, file, name) => {
  try {
    const fileUrl =curDocument.lessonFile;
    const fileName =curDocument.lessonFile.split("\\").pop();
    await downloadFile(fileUrl, fileName, id);
  } catch (error) {
    console.error("Error during file download:", error);
  }
};
const downloadFile = async (fileUrl, fileName, id) => {
  const apiUrl = `Lessons/download-file?fileUrl=${encodeURIComponent(fileUrl)}&fileName=${fileName}&lessonId=${id}`;
  try {
    const response = await axiosClient.get(apiUrl, {
      responseType: 'blob', 
    });
    const blob = new Blob([response.data]);
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
  return (
    <div className="video-container">
      <div className="card card-document">
        <h2>{curDocument && curDocument.name}</h2>
        {curDocument ? (
          <>
            <div className="document-content">
              <p>{curDocument.description}</p>
              <div className="document-file">
                <div className="document-file-img">
                  <img src={doc} />
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={e => {
                    e.preventDefault();
                    handleDownload(
                      curDocument.id
                    );
                  }}
                >
                  Yüklə
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "-50px",
              }}
            >
              <StarRatings
                rating={rating}
                starRatedColor="gold"
                changeRating={ratingChanged}
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="5px"
                starHoverColor="gold"
              />
              {loading ? (
                <div className="loaderSearch2"></div>
              ) : (
                <h3>{rating}</h3>
              )}
            </div>
          </>
        ) : (
          <img src={cover} style={{ width: "100%" }} />
        )}
      </div>
      <div className="card card-lesson">
        <div className="card-header">
          <h3 className="card-title">Dərsliklər</h3>
          {(auth.userStatus === "Admin" || auth.userStatus === "SuperAdmin") && (
            <button
              className="card-button"
              onClick={() => navigate("/editdocuments")}
            >
              Ayarlar
            </button>
          )}
        </div>
        <ul className="lesson-category">
          <Treebeard
            data={data}
            onToggle={onToggle}
            decorators={customDecorators}
          />
        </ul>
      </div>
    </div>
  );
}

export default Documents;
