
import * as React from "react";
import PropTypes from "prop-types";

const NavArrowIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
<svg
width="10"
height="7"
viewBox="0 0 10 7"
fill="red"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M-0.00204468 1.24997C-0.00242519 1.18417 0.0101871 1.11894 0.0350695 1.05802C0.0599518 0.997098 0.0966158 0.941689 0.142956 0.894969C0.189438 0.848105 0.244738 0.810908 0.305667 0.785524C0.366597 0.76014 0.43195 0.74707 0.497955 0.74707C0.563961 0.74707 0.629314 0.76014 0.690244 0.785524C0.751173 0.810908 0.806474 0.848105 0.852955 0.894969L4.99795 5.04497L9.14295 0.894969C9.18957 0.84835 9.24492 0.81137 9.30583 0.78614C9.36674 0.76091 9.43202 0.747924 9.49795 0.747924C9.63111 0.747924 9.7588 0.800818 9.85295 0.894969C9.94711 0.989121 10 1.11682 10 1.24997C10 1.38312 9.94711 1.51082 9.85295 1.60497L5.35295 6.10497C5.30647 6.15183 5.25117 6.18903 5.19024 6.21442C5.12931 6.2398 5.06396 6.25287 4.99795 6.25287C4.93195 6.25287 4.8666 6.2398 4.80567 6.21442C4.74474 6.18903 4.68944 6.15183 4.64295 6.10497L0.142956 1.60497C0.0966158 1.55825 0.0599518 1.50284 0.0350695 1.44192C0.0101871 1.381 -0.00242519 1.31577 -0.00204468 1.24997Z"
  fill="red"
/>
</svg>

);

NavArrowIcon.displayName = "Check";

NavArrowIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavArrowIcon;
