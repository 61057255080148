import * as React from "react";
import PropTypes from "prop-types";

const CameraIcon = ({
  size = 16,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.50662 14.6667H11.4933C13.3333 14.6667 14.0666 13.54 14.1533 12.1667L14.4999 6.66004C14.5933 5.22004 13.4466 4.00004 11.9999 4.00004C11.5933 4.00004 11.2199 3.76671 11.0333 3.40671L10.5533 2.44004C10.2466 1.83337 9.44662 1.33337 8.76662 1.33337H7.23995C6.55328 1.33337 5.75328 1.83337 5.44662 2.44004L4.96662 3.40671C4.77995 3.76671 4.40662 4.00004 3.99995 4.00004C2.55328 4.00004 1.40662 5.22004 1.49995 6.66004L1.84662 12.1667C1.92662 13.54 2.66662 14.6667 4.50662 14.6667Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 5.33337H9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.00004 12C9.19337 12 10.1667 11.0266 10.1667 9.83329C10.1667 8.63996 9.19337 7.66663 8.00004 7.66663C6.80671 7.66663 5.83337 8.63996 5.83337 9.83329C5.83337 11.0266 6.80671 12 8.00004 12Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
    
);

CameraIcon.displayName = "Check";

CameraIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CameraIcon;
