import React from "react";
import Logo from "../../images/SIESCO.png";
import { useNavigate } from "react-router-dom";

const LandingIntroduction = () => {
  const navigate=useNavigate()
  return (
        <div className="LandingBgImage">
        <img src={Logo} alt="SIESCO Logo" />
        <div className="welcomeLanding">
          <h3>Xoş Gəlmisiniz!</h3>
          <span>Hörmətli ziyarətçimiz, Siesco saytına xoş gəlmisiniz!</span>
          <p>
            Əgər hər hansı bir xidmətimiz haqqında əlavə məlumat almaq
            istəyirsinizsə, bizimlə əlaqə saxlayın və ya müraciət formunu
            doldurun.
          </p>
          <div className="centerBtnLanding">
          <button onClick={()=>navigate("../notificationForm")}>Müraciət et</button>
        </div>
        </div>
    </div>
  );
};

export default LandingIntroduction;
