import React from 'react';
import './CustomModal.css';
import { CancelIcon } from '../../assets/icons';

const CustomModal = ({ isOpen, onClose, children,mid }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>

      <div className={`${!mid ? "modal-content" : "modal-mid-content"}`} onClick={(e) => e.stopPropagation()}>
        {/* <button className="modal-close-button" onClick={onClose}>
          <CancelIcon/>
        </button> */}
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
