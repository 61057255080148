import React, { useRef, useState } from "react";
import image from '../../images/Videolar 1.png'
import LandingInfo from "./LandingInfo";
const LandingVideo = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="landingVideo">
      <div className="videoLandingMain">
        <video
          ref={videoRef}
          src="https://s3-figma-videos-production-sig.figma.com/video/1415598114358606782/TEAM/d6e1/422a/-425e-4a0c-857f-6898dcd8c518?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UALiyIhjkKoSsgcgy-zDcFenSUVWG-6EK9x1meIHhyvOSBOUrMZgzoFj6vZVO~6-4uy0yPE3HK4Ca44sjhOsFxFaM~h6rxyRL6myrso9AJOWbeniePRvpZzOuTGyswQIP4T-HoLAigG4HCFA5qGLwu9vMSEZzTqS4StkOfvE3y7JYxCNx5oJ6OIgUQzfCZmIcxqldsMz6gC6fZppHqKfi2a3EGmkqZLblDNoFFSoNh2wMETlCGNgmG9kYTlz6crzumDkorvztKN9AxwbQKYEhJousBpiqhpR0ky-ovrRNkpjZ5JVEOCfqM6MWO2Brfr3y2krwtfGF-cm5G2ERSzQuQ__"
          loop
          autoPlay
          muted={isMuted}
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      </div>

    </div>
  );
};

export default LandingVideo;
