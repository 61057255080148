import React from 'react'
import statsimg from '../../images/Videolar 1.png'
import permissionImg from "../../images/Səlahiyyətlər 1.png"
const LandingInfo = () => {
  return (
    <div className='landingInfoContainer'>
         <div className='landingInfo'>
      <div className='landingTexts'>
        <h2>Video və Dərsliklərin Statistikası</h2>
        <p>
          Bu bölmədə, Siesco platformasında yerləşdirilən tədris materialları, videolar və dərsliklərin statistikas ilə tanış ola bilərsiniz.
          Siz burada dərsliklərimizin populyarlığını, izlənmə göstəricilərini və istifadəçilər tərəfindən ən çox baxılan materialları izləyə bilərsiniz.
        </p>
        <ul>
        <h4>Statistika aşağıdakı göstəriciləri əhatə edir:</h4>
          <li>Ümumi video sayı: Platformada yerləşdirilən dərslik və təlim videolarının ümumi sayı.</li>
          <li>İzlənmə sayı: Hər bir dərsliyə və videoya olan ümumi baxış sayı.</li>
          <li>Ən çox izlənən videolar: İstifadəçilər tərəfindən ən çox izlənən 5 video dərslik.</li>
          <li>Yeni dərslər: Platformamıza əlavə olunan ən son tədris materialları və videolar.</li>
          <li>İstifadəçi fəaliyyətləri: Hər bir istifadəçinin baxdığı videoların sayını və fəaliyyətləri izləyə bilərsiniz.</li>
        </ul>
      </div>
      <div className='landingImgSide'>
        <img src={statsimg} alt="Statistics preview" className='landingImage' />
      </div>
    </div>
         <div className='landingInfoSecond'>
      <div className='landingTexts'>
        <h2>Səlahiyyətlər və İcazələr</h2>
        <p>
        Bu bölmədə istifadəçilərə müəyyən funksiyalar və məhdudiyyətlər daxilində icazələr təyin edə bilərsiniz. Siesco platformasında hər bir istifadəçi müəyyən səlahiyyətlərə sahibdir və bu səlahiyyətlər istifadəçinin vəzifəsi və ya roluna uyğun olaraq dəyişir.
        </p>
        <ul>
        <h4>Aşağıdakı icazələr mövcuddur və hər biri istifadəçi rollarına əsasən fərdiləşdirilə bilər:</h4>
          <li>Tam Giriş İcazəsi: İstifadəçi bütün məlumatlara və funksiyalara tam giriş imkanı əldə edir. (Adminlər üçün uyğundur)</li>
          <li>Məhdud Giriş İcazəsi: İstifadəçi yalnız müəyyən məlumatlara və bölmələrə giriş edə bilər. (Departament rəhbərləri və menecerlər üçün)</li>
          <li>Oxuma İcazəsi: İstifadəçi məlumatları görüntüləyə bilər, lakin dəyişiklik edə bilməz. (Müəyyən işçilər və ya izləyicilər üçün)</li>
          <li>Redaktə İcazəsi: İstifadəçi məlumatları redaktə edə və yeniləyə bilər, lakin yeni məlumatlar əlavə edə bilməz. (Müəyyən sahə menecerləri üçün)</li>
          <li>Məlumat Əlavə İcazəsi: İstifadəçi yeni məlumatlar əlavə edə bilər, lakin mövcud məlumatları dəyişdirə bilməz. (Operatorlar və ya qeydiyyatçılar üçün)</li>
        </ul>
      </div>
      <div className='landingImgSide'>
        <img src={permissionImg} alt="Statistics preview" className='landingImage' />
      </div> 
   </div>
    </div>
  )
}

export default LandingInfo