

import * as React from "react";
import PropTypes from "prop-types";

const MoreIcon = ({
  size = 24,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 5.90906C14 4.80906 13.1 3.90906 12 3.90906C10.9 3.90906 10 4.80906 10 5.90906C10 7.00906 10.9 7.90906 12 7.90906C13.1 7.90906 14 7.00906 14 5.90906Z" fill="#002344"/>
    <path d="M14 19.9091C14 18.8091 13.1 17.9091 12 17.9091C10.9 17.9091 10 18.8091 10 19.9091C10 21.0091 10.9 21.9091 12 21.9091C13.1 21.9091 14 21.0091 14 19.9091Z" fill="#002344"/>
    <path d="M14 12.9091C14 11.8091 13.1 10.9091 12 10.9091C10.9 10.9091 10 11.8091 10 12.9091C10 14.0091 10.9 14.9091 12 14.9091C13.1 14.9091 14 14.0091 14 12.9091Z" fill="#002344"/>
    </svg>
    
);

MoreIcon.displayName = "Check";

MoreIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default MoreIcon;
