import React, { useState, useEffect, useContext } from "react";
import axiosClient from "../../api/axiosClient";
import { AuthContext } from "../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Profile.css";

function ProfilePermissions() {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // Store selected IDs
  const { auth } = useContext(AuthContext);
  const [userId, setUserId] = useState(auth.userId);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosClient.get(
          `/CompanyGroups/GetDataForUpdateGroupPermissions/${userId}`
        );

        const combinedGroups = [
          ...response.data.videoGroupsDto.map((group) => ({
            ...group,
            type: "video",
          })),
          ...response.data.lessonGroupsDto.map((group) => ({
            ...group,
            type: "lesson",
          })),
        ];

        setAllGroups(combinedGroups);

        const initiallySelected = combinedGroups
          .filter((group) => group.isAllowed)
          .map((group) => ({
            id:
              group.type === "video" ? group.videoGroupId : group.lessonGroupId,
            type: group.type,
          }));
        setSelectedItems(initiallySelected);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  const toggleSelection = (item) => {
    const itemId =
      item.type === "video" ? item.videoGroupId : item.lessonGroupId;
    setSelectedItems((prevItems) =>
      prevItems.some(({ id, type }) => id === itemId && type === item.type)
        ? prevItems.filter(
            ({ id, type }) => id !== itemId || type !== item.type
          )
        : [...prevItems, { id: itemId, type: item.type }]
    );
  };

  const handleSave = async () => {
    const selectedVideos = selectedItems
      .filter((item) => item.type === "video")
      .map((item) => item.id);
    const selectedLessons = selectedItems
      .filter((item) => item.type === "lesson")
      .map((item) => item.id);
    const payload = {
      userId: userId,
      videoGroupIds: selectedVideos,
      lessonGroupIds: selectedLessons,
    };
    try {
      const response = await axiosClient.put(
        "CompanyGroups/UpdateGroupPermissionsForUser",
        payload,
        { notification: false }
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="unique-video-column permission-video-profile">
        {loading
          ? // Skeleton loader for loading state
            Array(5)
              .fill()
              .map((_, index) => (
                <div key={index} className="unique-video-item">
                  <Skeleton width={20} height={20} />
                  <Skeleton
                    width={150}
                    height={20}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              ))
          : allGroups.map((group) => (
              <div
                className="unique-video-item"
                key={
                  group.type === "video"
                    ? group.videoGroupId
                    : group.lessonGroupId
                }
              >
                <input
                  type="checkbox"
                  className="unique-checkbox"
                  checked={selectedItems.some(
                    ({ id, type }) =>
                      id ===
                        (group.type === "video"
                          ? group.videoGroupId
                          : group.lessonGroupId) && type === group.type
                  )}
                  onChange={() => toggleSelection(group)}
                />
                <label className="unique-video-label">
                  {group.type === "video"
                    ? group.videoGroupName
                    : group.lessonGroupName}
                </label>
              </div>
            ))}
      </div>
      <div className="save-button-permissions">
        <button onClick={handleSave}>Yadda saxla</button>
      </div>
    </div>
  );
}

export default ProfilePermissions;
