import React from 'react';
import AsyncSelect from "react-select/async";

const AsyncSelectComponent = ({ filterData, defaultOptions, handleChange, placeholder, value }) => {
  return (
    <AsyncSelect
      cacheOptions
      value={value}
      loadOptions={filterData}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable={true}
      styles={{
        control: (provided, state) => ({
          ...provided,
          backgroundColor: "#ffffff",
          border: "1px solid transparent",
          boxShadow: "none",
          cursor: "pointer",
          borderRadius: "5px",
          padding: "8px 30px",
          display: "flex",
          justifyContent: "space-between",
          gap: "4px",
          alignItems: "center",
          color: "#000000",
          fontWeight: "600",
          fontFamily: "Mulish",
          lineHeight: "16px",
          fontSize: "16px",
          width: "220px",
          "&:hover": {
            border: "1px solid transparent",
          },
          "&:focus": {
            border: "1px solid transparent",
            boxShadow: "none",
          },
          "@media screen and (max-width: 1500px)": {
            fontSize: "13px",
            width: "170px",
            padding:"8px 20px"
          },
          // "@media screen and (max-width: 1200px)": {
          //   padding: "2px 6px",
          //   fontSize: "14px",
          //   padding: "0",
          // },
          // "@media screen and (max-width: 1100px)": {
          //   width: "160px",
          //   height: "40px",
          //   padding: "0",
          // },
          "@media screen and (max-width: 500px)": {
            width: "145px",
            height: "50px",
            padding: "8px 7px",
          },
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: "5px",
          marginTop: "2px",
          overflowX: "hidden",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#d3d3d3" : "transparent",
          color: "#000000",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "#000000",
          fontSize: "16px", 
          fontWeight: "600",
          fontFamily: "Mulish",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "@media screen and (max-width: 1500px)": {
            fontSize: "13px", 
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#000000",
          fontSize: "16px",
          fontWeight: "600",
          fontFamily: "Mulish",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }),
      }}
    />
  );
};

export default AsyncSelectComponent;
