import React, { useEffect, useState } from 'react'
import { ClockIcon, DocsIcon, EyeIcon } from '../../../../assets/icons'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axiosClient from '../../../../api/axiosClient';
const AnalytcsVideosTop = ({ tabNumber,secondTabNumber }) => {
  
  const [videoStats, setVideoStats] = useState([]);
    useEffect(() => {
        const fetchProfileDataStats = async () => {
          const response = await axiosClient.get(
            `/UserVideoAndLesson/VideoInformation?tab=${tabNumber}`
          );
          if (response.status === 200) {
            setVideoStats(response.data);
          } else {
            throw new Error("Error fetching profile data");
          }
        };
        fetchProfileDataStats();
      }, [tabNumber,secondTabNumber]);
  return (
    <div className="videoStatistics">
        <div className="statisticsTop">
          <h2>Ümumi statistika</h2>
          <p>Videolar üzrə göstəricilər</p>
        </div>
        <div className="statisticsBottom">
          <div className="videoStatContent">
            <EyeIcon />
            <p>Ümumi baxış sayı</p>{" "}
            <h2>
              {videoStats && videoStats.viewCount !== undefined ? (
                videoStats.viewCount
              ) : (
                <Skeleton width={100} height={30} />
              )}
            </h2>
          </div>
          <div className="videoStatContent">
            <DocsIcon />
            <p>Yükləmələrin ümumi sayı</p>{" "}
            <h2>
              {videoStats && videoStats.downloadCount !== undefined ? (
                videoStats.downloadCount
              ) : (
                <Skeleton width={100} height={30} />
              )}
            </h2>
          </div>
          <div className="videoStatContent">
            <ClockIcon />
            <p>Ümumi baxış vaxtı</p>
            <h2>
              {videoStats && videoStats.duration !== undefined ? (
                videoStats.duration.duration !== undefined ? (
                  `${videoStats.duration.duration} ${
                    videoStats.duration.hour ? "saat" : "dəqiqə"
                  }`
                ) : (
                  <Skeleton width={100} height={30} />
                )
              ) : (
                <Skeleton width={100} height={30} />
              )}
            </h2>
          </div>
        </div>
      </div>
  )
}

export default AnalytcsVideosTop