import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Profile.css";
import ProfileDetails from "./ProfileDetails";
import ProfilePermissions from "./ProfilePermissions";
import { AuthContext } from "../../context/AuthContext";
const MainProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const getActiveDateTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("dateTab")) || 0;
  };

  const [activeDateTab, setActiveDateTab] = useState(getActiveDateTabFromURL());
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setNotifications([]);
    setPage(0);
  }, [activeDateTab]);

  const tabRefs = useRef([]);
  const underlineRef = useRef(null);

  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("tab")) || 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTabFromURL());

  useEffect(() => {
    setActiveTab(getActiveTabFromURL());
  }, [location.search]);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "none";
    }
  }, []);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "transform 0.3s ease, width 0.3s ease";
    }
  }, [activeTab]);
  const handleTabClick = (index) => {
    setActiveTab(index);
    const params = new URLSearchParams(location.search);
    params.set("tab", index);
    navigate({ search: params.toString() });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="profile-form">
      {auth.userStatus !== "User" ? (
        <>
          <div className="tabMenu2">
            <div className="tabUnderlineContainer2 profileUnderline">
              {["Profil", "Icazələr"].map((tab, index) => (
                <button
                  key={index}
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`tabMenuButtonProfile ${
                    activeTab === index ? "activeTab2" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </button>
              ))}
              <div ref={underlineRef} className="tabUnderline2 " />
            </div>
          </div>

          <div className="contentUserProfile">
            {activeTab === 0 && <ProfileDetails />}
            {activeTab === 1 && <ProfilePermissions />}
          </div>
        </>
      ) : (
        <ProfileDetails />
      )}
    </div>
  );
};
export default MainProfile;
