import * as React from "react";
import PropTypes from "prop-types";

const ArrowUpIcon = ({
  size = 16,
  strokeWidth = 1,
  color = "currentColor",
  rotate = 180,  // Rotate by 180 degrees to flip upside down
  ...props
}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }} 
    >
      <path
        d="M2.72003 5.96658L7.0667 10.3132C7.58003 10.8266 8.42003 10.8266 8.93336 10.3132L13.28 5.96658"
        stroke="#999999"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
);

ArrowUpIcon.displayName = "ArrowUpIcon";

ArrowUpIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.number, 
};

export default ArrowUpIcon;
