import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, BrowserRouter, HashRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Error from './pages/Error';
import Layout from './components/Layout';
import Videos from './pages/Videos';
import Login from './pages/Login';
import EditVideos from './pages/Admin/Videos'
import { AuthProvider, AuthContext } from './context/AuthContext';
import Documents from './pages/Documents';
import EditDocuments from './pages/Admin/Documents';
import VideoRules from './pages/Admin/VideoRules';
import DocumentRules from './pages/Admin/DocumentRules';
import AddPassword from './pages/AddPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './pages/ResetPassword';
import ProfileDetails from './pages/Profile/ProfileDetails';
import Analytcs from './pages/Analytcs/Analytcs';
import UserProfileDetails from './pages/UserProfileDetails/UserProfileDetails';
import Notifications from './pages/Notifications/Notifications';
import NotificationForm from './pages/Notifications/NotificationForm';
import Departments from './pages/Departments/Departments';
import DepartmentsUsersList from './pages/Departments/DepartmentsUsersList';
import SetAdmin from './pages/Departments/SetAdmin';
import AllPermissions from './pages/Departments/AllPermissions';
import Landing from './pages/Landing/Landing';
import MainProfile from './pages/Profile/MainProfile';
const App = () => {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/AddPassword/:token" element={<AddPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/notificationForm" element={<NotificationForm />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
            <Route index element={<Videos />} />
            <Route path='documents' element={<Documents />} />
            <Route path='analitika' element={<AdminRoute><Analytcs /></AdminRoute>} />
            <Route path='details/:id' element={<AdminRoute><UserProfileDetails /></AdminRoute>} />
            <Route path='profile/:id?' element={<MainProfile />} />
            <Route path='users' element={<AdminRoute> <Home /></AdminRoute>} />
            <Route path='editvideos/:id?' element={<AdminRoute> <EditVideos /></AdminRoute>} />
            <Route path='editdocuments/:id?' element={<AdminRoute> <EditDocuments /></AdminRoute>} />
            <Route path='videorules/:id?' element={<AdminRoute> <VideoRules /></AdminRoute>} />
            <Route path='documentrules/:id?' element={<AdminRoute> <DocumentRules /></AdminRoute>} />
            <Route path='notifications' element={<AdminRoute> <Notifications/></AdminRoute>} />
            <Route path="departments/*" element={<AdminRoute> <Departments/></AdminRoute>} />

            <Route path='permissions' element={<AdminRoute> <AllPermissions/></AdminRoute>} />
            <Route path="/departments/users/:id" element={<DepartmentsUsersList />} />
            <Route path="/departments/admin/:id" element={<SetAdmin />} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
        <ToastContainer />
      </HashRouter>
    </AuthProvider>
  );
};

const PrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return auth.accessToken ? children : <Navigate to="/landing" />;
};

const AdminRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return auth.userStatus === 'Admin' || auth.userStatus === 'SuperAdmin' ? children : <Navigate to="/" />;
};

export default App;
