
import * as React from "react";
import PropTypes from "prop-types";

const DotIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 10.9092C8.4 10.9092 7.5 11.8092 7.5 12.9092C7.5 14.0092 8.4 14.9092 9.5 14.9092C10.6 14.9092 11.5 14.0092 11.5 12.9092C11.5 11.8092 10.6 10.9092 9.5 10.9092Z" fill="#002344"/>
    <path d="M16.5 10.9092C15.4 10.9092 14.5 11.8092 14.5 12.9092C14.5 14.0092 15.4 14.9092 16.5 14.9092C17.6 14.9092 18.5 14.0092 18.5 12.9092C18.5 11.8092 17.6 10.9092 16.5 10.9092Z" fill="#002344"/>
    <path d="M23.5 10.9092C22.4 10.9092 21.5 11.8092 21.5 12.9092C21.5 14.0092 22.4 14.9092 23.5 14.9092C24.6 14.9092 25.5 14.0092 25.5 12.9092C25.5 11.8092 24.6 10.9092 23.5 10.9092Z" fill="#002344"/>
    </svg>
    
    
);

DotIcon.displayName = "Edit";

DotIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default DotIcon;