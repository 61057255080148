import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import "./AnalytcsUsers.css";
import UserImage from "../../../assets/images/premium_photo-1683121366070-5ceb7e007a97.jfif";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { SearchIcon } from "../../../assets/icons";
import axiosClient from "../../../api/axiosClient";
import AnalytcsTable from "../AnalytcsTable/AnalytcsTable";
import CustomModal from "../../../components/Modal/CustomModal";
import Home from "../../Home";
import AnalytcsUserTop from "./AnalytcsUserTop/AnalytcsUserTop";
import AsyncSelectComponent from "../../../components/AsyncSelect/AsyncSelect";
import { useNavigate } from "react-router-dom";
import { duration } from "@mui/material";
import UseDebounce from "../../../shared/hooks/UseDebonce";
import DatePicker1 from "../../../components/DatePicker/DatePicker";
const AnalytcsUsers = React.forwardRef(
  ({ tabNumber, secondTabNumber, scrollableDivRef }, ref) => {
    const avatars = [UserImage, UserImage, UserImage, UserImage];
    const [openDropdown, setOpenDropdown] = useState(null);
    const [minValue, setMinValue] = useState();
    const [maxValue, setMaxValue] = useState();
    const [selectedVideos, setSelectedVideos] = useState(null);
    const [selectedUsers, setSelectUsers] = useState(null);
    const [selectedViews, setSelectedViews] = useState("Baxış sayı");
    const [formData, setFormData] = useState([]);
    const [searchFilters, setSearchFilters] = useState(null);
    const [metricData, setMetricData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultLessons, setDefaultLessons] = useState([]);
    const [defaultlesAndVideo, setDefaultLesAndVideo] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dbClick, setDbClick] = useState(true);
    const [modalUserSelect, setModalUserSelect] = useState(null);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const [skip, setSkip] = useState(0);
    const [take] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchLesAndVideo, setSearchLesAndVideo] = useState("");
    const debouncedSearchTerm = UseDebounce(searchTerm, 300);
    const debouncedsearchLesAndVideo = UseDebounce(searchLesAndVideo, 300);
    const [mainTab,setMainTab]=useState(tabNumber)
    const [dates, setDates] = useState([]);

    useEffect(() => {
      setMainTab(tabNumber)
      scrollableDivRef?.scrollTo(0, 0);
      setFormData([]);
      setSkip(0);
      setHasMoreData(true);
    }, [tabNumber]);

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axiosClient.get(
            `/UserVideoAndLesson/Get?tab=${mainTab}&skip=${skip}&take=${take}`,
            { params: searchFilters }
          );
          if (response.status === 200) {
            if (response.data.length === 0 && skip > 0) {
              setHasMoreData(false);
            } else {
              setFormData((prev) => {
                const newData = [...prev, ...response.data];
                const uniqueData = Array.from(
                  new Set(newData.map((item) => item.id))
                ).map((id) => newData.find((item) => item.id === id));
                return uniqueData;
              });
              setHasMoreData(response.data.length === take);
            }
          } 
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
        fetchData();
    }, [mainTab, skip,searchFilters]);
    useEffect(() => {
      const fetchProfileDataStats = async () => {
        const response = await axiosClient.get(
          `/UserVideoAndLesson/Metrics?tab=${tabNumber}`
        );
        if (response.status === 200) {
          setMetricData(response.data);
        } 
      };
      fetchProfileDataStats();
    }, [tabNumber, secondTabNumber]);
    const handleSearchClick = () => {
      const filters = {
        lessonId:
          selectedVideos?.type === "lesson" ? selectedVideos?.value : null,
        videoId:
          selectedVideos?.type === "video" ? selectedVideos?.value : null,
        durationMin: minValue || null,
        durationMax: maxValue || null,
        userId: selectedUsers ? selectedUsers.value : null,
        min: dates ? dates.min : null,
        max: dates ? dates.max : null,
      };

      setSearchFilters(filters);
      setFormData([]);
      setSkip(0);
      if (scrollableDivRef) {
        scrollableDivRef.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        
      }
      setLoading(true);
    };

    const handleDropdownClick = (dropdownId, isOpen) => {
      setOpenDropdown(isOpen ? dropdownId : null);
    };
    const headers = [
      { text: "№" },
      { text: "İstifadəçi adı" },
      { text: "Video/Dərslik" },
      { text: "Başlama vaxtı" },
      { text: "Bitmə vaxtı" },
      { text: "Ümumi baxış vaxtı" },
    ];

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };
    const handleSelectViews = (item) => {
      setSelectedViews(item);
    };
    const extraCount = 6;
    const filterLessons = async (inputValue) => {
      if (!inputValue) return [];

      try {
        const response = await axiosClient.get(
          `/UserVideoAndLesson/UserFilterSearchUser?`,
          { params: { title: inputValue, tab: tabNumber } }
        );
        if (response.status === 200) {
          const lessons = response.data.map((item) => ({
            label: item.fullName,
            value: item.id,
          }));
          setDefaultLessons([
            ...lessons,
            { label: "Hamısını bax", value: "view_all" },
          ]);
          return lessons;
        }
      } catch (error) {
        console.error("Error fetching filtered lessons", error);
      }
      return [];
    };
    const filterLesAndVideo = async (inputValue) => {
      if (!inputValue) return [];

      try {
        const response = await axiosClient.get(
          `/UserVideoAndLesson/UserFilterSearchVideoLesson?`,
          { params: { title: inputValue, tab: tabNumber } }
        );

        if (response.status === 200) {
          const items = [
            ...response.data.video.map((item) => ({
              label: item.title,
              value: item.id,
              type: "video",
            })),
            ...response.data.lesson.map((item) => ({
              label: item.title,
              value: item.id,
              type: "lesson",
            })),
          ];

          setDefaultLesAndVideo(items);
          return items;
        }
      } catch (error) {
        console.error("Error fetching filtered lessons", error);
      }
      return [];
    };
    useEffect(() => {
      if (debouncedSearchTerm) {
        filterLessons(debouncedSearchTerm);
      } else {
        setDefaultLessons([]);
      }
    }, [debouncedSearchTerm]);
    
    useEffect(() => {
      if (debouncedsearchLesAndVideo) {
        filterLessons(debouncedsearchLesAndVideo);
      } else {
        setDefaultLesAndVideo([]);

      }
    }, [debouncedsearchLesAndVideo]);

    const handleSelectChange = (selectedOption) => {
      if (selectedOption?.value === "view_all") {
        openModal();
      } else {
        setSelectUsers(selectedOption);
      }
    };
    const handleSelectChange2 = (selectedOption) => {
      if (selectedOption?.value === "view_all") {
        // openModal();
      } else {
        setSelectedVideos(selectedOption);
      }
    };
    const navigate = useNavigate();
    const userDetails = (id) => {
      navigate(`../details/${id}`);
    };
    useEffect(() => {
      const selectLessonData = async () => {
        try {
          const response = await axiosClient.get(
            `/UserVideoAndLesson/SingleUserGetAll?tab=${tabNumber}`
          );
          if (response.status === 200) {
            const lessons = response.data.map((item) => ({
              label: item.fullName,
              value: item.userId,
            }));
            setDefaultLessons([
              ...lessons,
              { label: "Hamısını bax", value: "view_all" },
            ]);
          }
        } catch (error) {
          console.error("Error fetching lesson data:", error);
        }
      };
    
      selectLessonData();
      if (tabNumber || secondTabNumber) {
        selectLessonData();
      }
    }, [tabNumber, secondTabNumber]);
    
    useEffect(() => {
      const selectLessonAndVideoData = async () => {
        try {
          const response = await axiosClient.get(
            `/UserVideoAndLesson/SingleVideoAndLessonGetAll?tab=${tabNumber}`
          );
          if (response.status === 200) {
            const lessons = response.data.map((item) => {
              const video = item.videos;
              const lesson = item.lessons;
              return {
                label: video ? video.title : lesson.title,
                value: video ? video.id : lesson.id,
                type: video ? "video" : "lesson",
              };
            });
            setDefaultLesAndVideo([...lessons]);
            if (lessons?.length > 0) {
              setSelectedVideos(lessons[0]);
            }
          } 
        } catch (error) {
          console.error("Error fetching lesson data:", error);
        }
      };
      selectLessonAndVideoData();
    }, [tabNumber,secondTabNumber]);

    useEffect(() => {
      if (modalUserSelect && modalUserSelect.value) {
        setSelectUsers(modalUserSelect);
      }
    }, [modalUserSelect]);
   
    const [hasMoreData, setHasMoreData] = useState(true);
    const loadingMoreRef = useRef(false);
    useEffect(() => {
      const handleScroll = () => {
        if (
          scrollableDivRef &&
          !loading &&
          hasMoreData &&
          scrollableDivRef.scrollTop + scrollableDivRef.clientHeight >=
            scrollableDivRef.scrollHeight - 100
        ) {
          if (!loadingMoreRef.current) {
            loadingMoreRef.current = true;
            setSkip((prevSkip) => prevSkip + 1);
          }
        }
      };
      if (scrollableDivRef) {
        scrollableDivRef.addEventListener("scroll", handleScroll);
        return () => {
          scrollableDivRef.removeEventListener("scroll", handleScroll);
        };
      }
    }, [scrollableDivRef, loading, hasMoreData]);

    useEffect(() => {
      if (!loading) {
        loadingMoreRef.current = false;
      }
    }, [loading]);
    return (
      <div className="analytc_users">
        <CustomModal isOpen={isModalOpen} onClose={closeModal}>
          <Home
            dbClick={dbClick}
            closeModal={closeModal}
            setSelectUsers={setModalUserSelect}
          />
        </CustomModal>
        <AnalytcsUserTop
          avatars={avatars}
          extraCount={extraCount}
          metricData={metricData}
          tabNumber={tabNumber}
          secondTabNumber={secondTabNumber}
        />
        <div className="DropdDownMain">
          <div className="dropDowns">
            <AsyncSelectComponent
              placeholder={"İstifadəçi adı"}
              value={selectedUsers}
              handleChange={handleSelectChange}
              filterData={filterLessons}
              defaultOptions={defaultLessons}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
            />
            <AsyncSelectComponent
              placeholder={"Video/Dərslik"}
              handleChange={handleSelectChange2}
              filterData={filterLesAndVideo}
              defaultOptions={defaultlesAndVideo}
              onInputChange={(inputValue) => setSearchLesAndVideo(inputValue)}
            />
            <Dropdown
              title="Baxış vaxtı"
              minmax={[minValue, maxValue]}
              isOpen={openDropdown === "views"}
              onClick={(isOpen) => handleDropdownClick("views", isOpen)}
              setMinValue={setMinValue}
              setMaxValue={setMaxValue}
              onSelect={handleSelectViews}
            />
            <DatePicker1 setData={setDates} />
            <button className="search-button" onClick={handleSearchClick}>
              <p>Axtar</p>
              <span>
                <SearchIcon />
              </span>
            </button>
          </div>
        </div>
        <AnalytcsTable
          headers={headers}
          data={formData}
          formatDate={formatDate}
          loading={loading}
          tabNumber={tabNumber}
          secondTabNumber={secondTabNumber}
        >
          {formData && formData?.length > 0
            ? formData.map((elem, i) => (
                <tr key={i} onClick={() => userDetails(elem.user.id)}>
                  <td className="analytc_table_id">{i + 1}</td>
                  <td>
                    <div className="analytc_table_image_div">
                      <div className="analytc_table_image_divs">
                        <img src={elem?.user?.photo} alt="img" />
                      </div>
                      <span>{elem?.user?.fullName}</span>
                    </div>
                  </td>
                  <td>
                    <span>{elem?.lesson?.title || elem?.video?.title}</span>
                  </td>
                  <td>
                    <div className="analytc_table_date_div">
                      <span>
                        {formatDate(elem?.startDate?.substring(0, 10))}
                      </span>
                      <span>{elem?.startDate?.substring(11, 16)}</span>
                    </div>
                  </td>
                  <td>
                    <div className="analytc_table_date_div">
                      <span>{formatDate(elem?.endDate?.substring(0, 10))}</span>
                      <span>{elem?.endDate?.substring(11, 16)}</span>
                    </div>
                  </td>
                  <td>
                    <span>{elem?.duration?.substring(0, 8)}</span>
                  </td>
                </tr>
              ))
            : null}
        </AnalytcsTable>
      </div>
    );
  }
);
export default AnalytcsUsers;
