
import * as React from "react";
import PropTypes from "prop-types";

const NotificationIcon = ({
  size = 18,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.1875 9.3125C2.40625 9.3125 2.625 9.53125 2.625 9.75V11.5C2.625 11.7461 2.40625 11.9375 2.1875 11.9375H0.4375C0.191406 11.9375 0 11.7461 0 11.5V9.75C0 9.53125 0.191406 9.3125 0.4375 9.3125H2.1875ZM2.1875 0.5625C2.40625 0.5625 2.625 0.78125 2.625 1V2.75C2.625 2.99609 2.40625 3.1875 2.1875 3.1875H0.4375C0.191406 3.1875 0 2.99609 0 2.75V1C0 0.78125 0.191406 0.5625 0.4375 0.5625H2.1875ZM2.1875 4.9375C2.40625 4.9375 2.625 5.15625 2.625 5.375V7.125C2.625 7.37109 2.40625 7.5625 2.1875 7.5625H0.4375C0.191406 7.5625 0 7.37109 0 7.125V5.375C0 5.15625 0.191406 4.9375 0.4375 4.9375H2.1875ZM13.5625 9.75C13.7812 9.75 14 9.96875 14 10.1875V11.0625C14 11.3086 13.7812 11.5 13.5625 11.5H4.8125C4.56641 11.5 4.375 11.3086 4.375 11.0625V10.1875C4.375 9.96875 4.56641 9.75 4.8125 9.75H13.5625ZM13.5625 1C13.7812 1 14 1.21875 14 1.4375V2.3125C14 2.55859 13.7812 2.75 13.5625 2.75H4.8125C4.56641 2.75 4.375 2.55859 4.375 2.3125V1.4375C4.375 1.21875 4.56641 1 4.8125 1H13.5625ZM13.5625 5.375C13.7812 5.375 14 5.59375 14 5.8125V6.6875C14 6.93359 13.7812 7.125 13.5625 7.125H4.8125C4.56641 7.125 4.375 6.93359 4.375 6.6875V5.8125C4.375 5.59375 4.56641 5.375 4.8125 5.375H13.5625Z" fill="#919DA8"/>
  </svg>
  
);

NotificationIcon.displayName = "Check";

NotificationIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NotificationIcon;