

import * as React from "react";
import PropTypes from "prop-types";

const DocsIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 11V17L11 15" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 17L7 15" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#3E87D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

DocsIcon.displayName = "Edit";

DocsIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default DocsIcon;