import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axiosClient from '../../api/axiosClient';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ tabNumber, id }) => {
  const [data, setData] = useState([]);
  const [lessonStats, setLessonStats] = useState([]);

  useEffect(() => {
    const fetchProfileDataStats = async () => {
      const response = await axiosClient.get(
        `/User/UserDetailStatistic?userId=${id}&tab=${tabNumber}&skip=0&take=10`
      );
      if (response.status === 200) {
        setLessonStats(response.data.timePeriods);
      } else {
        throw new Error("Error fetching profile data");
      }
    };
    fetchProfileDataStats();
  }, [tabNumber, id]);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    if (tabNumber === 1) {
      const transformedData = lessonStats.map(item => ({
        hour: item.date.substring(11, 16),
        value: item.value,
      }));
      setData(transformedData);
    } else if (tabNumber === 2) {
      const filteredData = lessonStats.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate.getDate() <= currentDay;
      });

      setData(
        filteredData.map(item => ({
          hour: `${item.date.substring(8, 10)}/${item.date.substring(5, 7)}`,
          value: item.value,
        }))
      );
    } else if (tabNumber === 3) {
      const transformedData = lessonStats?.map(item => ({
        hour: `${item?.date.substring(5, 7)}/${item?.date?.substring(2, 4)}`,
        value: item?.value,
      }));
      setData(transformedData);
    }
  }, [lessonStats, tabNumber]); 

  const formatTooltipLabel = (value) => {
    if (value === 60) {
      return '1 saat';
    } else if (value >= 60) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return `${hours} saat ${minutes} dk`;
    }
    return `${value} dəqiqə`;
  };

  const chartData = {
    labels: data.map(d => d.hour),
    datasets: [
      {
        data: data.map(d => d.value),
        backgroundColor: '#3E87D9',
        borderColor: '#3E87D9',
        borderWidth: 1,
        minBarThickness: 5,
        maxBarThickness: 40,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div style={{ height: '100%', width: '99%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ height: '250px', width: '99%', marginTop: '10px' }}>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false, 
              },
              tooltip: {
                callbacks: {
                  label: (context) => formatTooltipLabel(context.raw),
                },
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: ''
                },
                grid: {
                  display: false,
                },
                barPercentage: 0.8, 
                categoryPercentage: 0.8,
              },
              y: {
                title: {
                  display: false,
                },
                grid: {
                  display: false,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;
