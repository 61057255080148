import React, { useState } from "react";
import CommentItem from "./CommentItem";
import CommentForm from "./CommentForm";

const Comments = ({ comments, onAddComment, onReply,videoId, onDelete}) => {
  const [activeReplyFormId, setActiveReplyFormId] = useState(null);
  const [activeEditFormId, setActiveEditFormId] = useState(null);

  const handleSetActiveReplyForm = (id) => {
    setActiveReplyFormId(id);
  };

  return (
    <div className="comments-section">
      <CommentForm onAddComment={onAddComment} />
      {comments.map((comment) => (
        <CommentItem setActiveEditId={setActiveEditFormId} activeEditId={activeEditFormId} onDelete={onDelete} key={comment.id} comment={comment} onReply={onReply} videoId={videoId}  activeReplyFormId={activeReplyFormId}
        setActiveReplyFormId={handleSetActiveReplyForm} />
      ))}
    </div>
  );
};

export default Comments;
