import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../Avatar/Avatar";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  EditIcon,
  MoreIcon,
  TrashIcon,
} from "../../assets/icons";
import axiosClient from "../../api/axiosClient";
import "./Comments.css";
import ReplyItem from "./ReplyItem";
import { AuthContext } from "../../context/AuthContext";
import VideoDateTime from "../../utils/VideoDateTime";

const CommentItem = ({
  comment,
  onReply,
  onDelete,
  videoId,
  activeReplyFormId,
  setActiveReplyFormId,
  activeEditId,
  setActiveEditId,
}) => {
  const [replyText, setReplyText] = useState("");
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showAllReplys, setShowAllReplys] = useState(false);
  const [showReplyCommits, setShowReplyCommits] = useState([]);
  const [tagName, setTagName] = useState("");
  const [refreId, setRefreId] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [editText, setEditText] = useState(comment?.content);
  const [contextMenuClose, setContextMenuClose] = useState(true);
  const [skip, setSkip] = useState(0);
  const [replyCount, setReplyCount] = useState(comment.replyCount);
  const take = 5;
  const [hasClickedMore, setHasClickedMore] = useState(false);
  const { auth } = useContext(AuthContext);
  const menuRef = useRef(null);
  const isEditing = activeEditId === comment.id;
  const [hasMoreReplies, setHasMoreReplies] = useState(true);
  const [edited, setEdited] = useState(comment.isEdited);

  const handleReply = () => {
    if (replyText.trim()) {
      onReply(comment?.id, replyText, comment?.user?.id)
        .then((response) => {
          const newReply = {
            ...response,
            user: { id: comment?.user?.id, fullName: auth?.userFullName },
            refUser: tagName,
          };
          setShowReplyCommits((prev) => {
            if (prev.some((reply) => reply.id === newReply.id)) {
              return prev;
            }
            return [...prev, newReply];
          });
  
          setReplyText("");
          setShowReplyForm(false);
          setActiveReplyFormId(null);
  
          setReplyCount((prevCount) => prevCount + 1);
        })
        .catch((error) => {
          console.error("Error adding reply:", error);
        });
    } else {
      alert("Reply cannot be empty!");
    }
  };
  
  const fetchReplies = () => {
    axiosClient
      .get(`/Comments/GetAllChildren?parentId=${comment?.id}&skip=${skip}&take=${take}`)
      .then((response) => {
        setShowReplyCommits((prev) => {
          const newReplies = response.data.filter(
            (newReply) => !prev.some((reply) => reply.id === newReply.id)
          );
          return [...prev, ...newReplies];
        });
        setSkip((prev) => prev + 1);
        if (response.data.length + showReplyCommits.length >= replyCount) {
          setHasMoreReplies(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching replies:", error);
      });
  };

  const showMoreReplyComments = () => {
    fetchReplies();
  };
  const showCommentsReply = () => {
    setShowAllReplys(!showAllReplys);
    if (!showAllReplys) {
      setSkip(0);
      fetchReplies();
    }
  };
  function formatName(name) {
    return name.replace(/\s+/g, "");
  }

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
    setActiveReplyFormId(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleDelete = async (e) => {
    setActiveReplyFormId(null);
    e.preventDefault();
    try {
      const response = await axiosClient.delete(
        `/Comments/Delete?commentId=${comment.id}`
      );
      if (response.status === 200) {
        onDelete(comment.id);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleDeleteReply = (replyId) => {
    setShowReplyCommits((prevReplies) =>
      prevReplies.filter((reply) => reply.id !== replyId)
    );
  };

  const handleEdit = () => {
    setActiveEditId(comment.id);
    setActiveReplyFormId(null);
    setEditText(comment?.content);
    setContextMenuClose(false);
    setOpenMenu(false);
  };

  const handleSaveEdit = async () => {
    setActiveReplyFormId(null);
    setOpenMenu(false);
    comment.content = editText;
    setContextMenuClose(true);
    setActiveEditId(null);
    try {
      const response = await axiosClient.put("/Comments/Update", {
        commentId: comment.id,
        newContent: editText,
      });
      if (response.status === 200) {
        setEdited(true)
      }
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  const handleCancelEdit = () => {
    setActiveReplyFormId(null);
    setActiveEditId(null);
    setContextMenuClose(true);
    setOpenMenu(false);
  };

  return (
    <div className="comment-item">
      <div className="comment-component-container">
        <div className="comment-component-left">
          <Avatar image={null} name={comment?.user?.fullName} />
          <div className="comment-component-body">
            <div className="comment-component-username">
              <h3>{comment?.user?.fullName}</h3>
            </div>
            {isEditing ? (
              <>
                <div className="edit-form">
                  <span>{tagName ? `@${formatName(tagName)}` : null}</span>
                  <input
                    type="text"
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    placeholder="Cavabla..."
                  />
                </div>
              </>
            ) : (
              <div className="comment-component-text">
                <p>
                  {" "}
                  <span>
                    {comment?.refUser ? `@${comment?.refUser}` : null}
                  </span>{" "}
                  {comment?.content}
                </p>
              </div>
            )}

            <div
              className={`${
                !isEditing
                  ? "comment-component-time"
                  : "comment-component-time-edit"
              }`}
            >
              <p>
                {VideoDateTime(comment.createDate)}{" "}
                <span className="editedComment">{edited ? "(redaktə edilib)" : null}</span>
              </p>{" "}
              {!isEditing && (
                <button
                  onClick={() => {
                    if (activeReplyFormId === comment.id) {
                      setActiveReplyFormId(null);
                      setShowReplyForm(false);
                    } else {
                      setTagName(comment?.user.fullName);
                      setShowReplyForm(true);
                      setActiveReplyFormId(comment.id);
                      setRefreId(comment?.user?.id);
                    }
                  }}
                >
                  Reply
                </button>
              )}
              {isEditing && (
                <div className="commentEditBtns">
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={handleCancelEdit}>Cancel</button>
                </div>
              )}
            </div>
            {replyCount > 0 && !comment.isReply && (
              <button className="moreReplyComment" onClick={showCommentsReply}>
                {showAllReplys ? <ArrowUpIcon /> : <ArrowDownIcon />}{" "}
                <p>{replyCount} cavab</p>
              </button>
            )}
          </div>
        </div>
        {contextMenuClose ? (
          comment.isMe ? (
            <div className="MoreContextComment" ref={menuRef}>
              <button onClick={handleOpenMenu}>
                <MoreIcon />
              </button>
              {openMenu && (
                <ul>
                  <li onClick={handleEdit}>
                    <EditIcon size={16} />
                    <p>Edit</p>
                  </li>
                  <li onClick={handleDelete}>
                    <TrashIcon size={16} />
                    <p>Delete</p>
                  </li>
                </ul>
              )}
            </div>
          ) : null
        ) : null}
      </div>

      {activeReplyFormId === comment.id && (
        <>
          <div className="reply-form">
            <span>@{formatName(tagName)}</span>
            <input
              type="text"
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              placeholder="Cavabla..."
            />
          </div>
          <div className="commentEditBtns2">
            <button onClick={handleReply}>Send</button>
          </div>
        </>
      )}

      {showAllReplys && replyCount > 0 && (
        <div className="replies">
          {showReplyCommits.map((reply) => (
            <ReplyItem
              activeReplyFormId={activeReplyFormId}
              setActiveReplyFormId={setActiveReplyFormId}
              setRefreId={setRefreId}
              refreId={refreId}
              tagName={tagName}
              setTagName={setTagName}
              key={reply.id}
              comment={reply}
              lastcomment={comment}
              onReply={onReply}
              parentId={comment.id}
              showReplyCommits={showReplyCommits}
              setShowReplyCommits={setShowReplyCommits}
              onDelete={handleDeleteReply}
              activeEditId={activeEditId}
              setActiveEditId={setActiveEditId}
              fetchReplies={fetchReplies}
              setReplyCount={setReplyCount}
              replyCount={replyCount}
            />
          ))}
          {showAllReplys && replyCount > 0 && hasMoreReplies && (
            <p onClick={showMoreReplyComments} className="moreLoadingText">
              Daha cox yukle
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default CommentItem;
