
import * as React from "react";
import PropTypes from "prop-types";

const NavAnalytcsIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5625 8.75C13.7812 8.75 14 8.96875 14 9.1875V10.0625C14 10.3086 13.7812 10.5 13.5625 10.5H0.875C0.382812 10.5 0 10.1172 0 9.625V0.4375C0 0.21875 0.191406 0 0.4375 0H1.3125C1.53125 0 1.75 0.21875 1.75 0.4375V8.75H13.5625ZM12.6875 0.875C12.9062 0.875 13.125 1.09375 13.0977 1.3125V4.56641C13.0977 5.14062 12.4141 5.44141 12.0039 5.03125L11.1016 4.12891L8.47656 6.75391C8.14844 7.10938 7.57422 7.10938 7.24609 6.75391L5.25 4.75781L3.96484 6.01562C3.80078 6.17969 3.52734 6.17969 3.36328 6.01562L2.73438 5.38672C2.57031 5.22266 2.57031 4.94922 2.73438 4.78516L4.62109 2.89844C4.94922 2.54297 5.52344 2.54297 5.85156 2.89844L7.875 4.89453L9.87109 2.89844L8.96875 1.99609C8.55859 1.58594 8.85938 0.875 9.43359 0.875H12.6875Z"
      fill="#919DA8"
    />
  </svg>


);

NavAnalytcsIcon.displayName = "Check";

NavAnalytcsIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavAnalytcsIcon;
