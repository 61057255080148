
import * as React from "react";
import PropTypes from "prop-types";

const FileDownloadIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.3335 11V17L11.3335 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.3335 17L7.3335 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3335 10V15C22.3335 20 20.3335 22 15.3335 22H9.3335C4.3335 22 2.3335 20 2.3335 15V9C2.3335 4 4.3335 2 9.3335 2H14.3335" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3335 10H18.3335C15.3335 10 14.3335 9 14.3335 6V2L22.3335 10Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    
);

FileDownloadIcon.displayName = "Edit";

FileDownloadIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default FileDownloadIcon;