import React, { useContext, useState } from "react";
import "../App.css";
import Nav from "./Nav";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { DownArrowIcon } from "../assets/icons";
import MainSearch from "./MainSearch/MainSearch";

function Layout() {
  const { auth } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const profilePath = () => {
    navigate("/profile");
  };

  const prevPage = () => {
      navigate(-1); 
  };
  const nextPage = () => {
    navigate(+1);
  };
  return (
    <>
      <Nav />
      <main>
        <header>
          <div className="headerLeft">
            <span onClick={prevPage} >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.76669 11.3495L15.392 4.16168C15.6853 3.91914 16.0626 3.80267 16.4416 3.83779C16.8205 3.87291 17.17 4.05675 17.4137 4.34905C17.6573 4.64135 17.7752 5.01829 17.7415 5.39733C17.7078 5.77637 17.5253 6.12661 17.2339 6.37136L9.93381 12.4544L17.2339 18.5374C17.5264 18.7818 17.7099 19.1323 17.7442 19.512C17.7784 19.8916 17.6606 20.2693 17.4165 20.5621C17.1725 20.8549 16.8222 21.0388 16.4426 21.0735C16.063 21.1082 15.6851 20.9908 15.392 20.7471L6.76669 13.5592C6.60495 13.424 6.47486 13.2551 6.38559 13.0642C6.29632 12.8733 6.25005 12.6651 6.25005 12.4543C6.25005 12.2436 6.29632 12.0354 6.38559 11.8445C6.47486 11.6536 6.60495 11.4846 6.76669 11.3495Z"
                  fill="#002344"
                />
              </svg>
            </span>
            <span onClick={nextPage}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2333 11.3495L8.60795 4.16168C8.31474 3.91914 7.93735 3.80267 7.55845 3.83779C7.17954 3.87291 6.82999 4.05675 6.58634 4.34905C6.34269 4.64135 6.22481 5.01829 6.2585 5.39733C6.29219 5.77637 6.47471 6.12661 6.76609 6.37136L14.0662 12.4544L6.76609 18.5374C6.47358 18.7818 6.29005 19.1323 6.25582 19.512C6.22158 19.8916 6.33944 20.2693 6.58349 20.5621C6.82755 20.8549 7.17785 21.0388 7.55744 21.0735C7.93703 21.1082 8.31487 20.9908 8.60795 20.7471L17.2333 13.5592C17.395 13.424 17.5251 13.2551 17.6144 13.0642C17.7037 12.8733 17.7499 12.6651 17.7499 12.4543C17.7499 12.2436 17.7037 12.0354 17.6144 11.8445C17.5251 11.6536 17.395 11.4846 17.2333 11.3495Z"
                  fill="#002344"
                />
              </svg>
            </span>
          </div>
          <div className="headerRight">
            <MainSearch />
            <div
              className="spanProfile"
              onClick={profilePath}
              style={{ cursor: "pointer" }}
            >
              <div className="spanProfileInfo">
                <p>{auth.userFullName}</p>
                <span>
                  {auth.userStatus === "Admin" ? "Admin" :auth.userStatus === "SuperAdmin"?"Super Admin": "Istifadəçi"}
                </span>
              </div>
              <div className="avatarPhoto">
                <img src={auth.photo} alt="" />
              </div>
            </div>
          </div>
        </header>
        <Outlet />
      </main>
    </>
  );
}

export default Layout;
