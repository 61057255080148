import React, { useEffect, useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import { CancelIcon } from "../../assets/icons";
import Avatar from "../../components/Avatar/Avatar";
import axiosClient from "../../api/axiosClient";

const UserPermission = ({ id, parentİd }) => {
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({
    statistics: false,
    videos: false,
    tutorials: false,
    addUser: false,
    editUser: false,
    blockUser: false,
  });
  const [enabledPermissions, setEnabledPermissions] = useState({
    statistics: false,
    videos: false,
    tutorials: false,
    addUser: false,
    editUser: false,
    blockUser: false,
  });

  const isModalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const confirmation = async () => {
    try {
      const response = await axiosClient.put(
        `/CompanyGroups/AddAdmin?groupId=${parentİd}&userId=${id}`,
        {},
        { notification: false }
      );
      if (response.status === 200) {
        isModalOpen();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    try {
      const response = await axiosClient.get(
        `/CompanyGroups/GetAllPermissionForAdmin?adminId=${id}`,
        {},
        { notification: false }
      );
      setUserData(response.data);

      const permissions = response.data.getAllPermissionDtos || [];
      const updatedEnabledPermissions = {
        statistics: permissions.some((p) => p.id === 2),
        videos: permissions.some((p) => p.id === 3),
        tutorials: permissions.some((p) => p.id === 4),
        addUser: permissions.some((p) => p.id === 5),
        editUser: permissions.some((p) => p.id === 6),
        blockUser: permissions.some((p) => p.id === 7),
      };
      setEnabledPermissions(updatedEnabledPermissions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id && openModal === true) {
      getData();
    }
  }, [id, openModal]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleSave = async() => {
    const permissionIdMap = {
      statistics: 2,
      videos: 3,
      tutorials: 4,
      addUser: 5,
      editUser: 6,
      blockUser: 7,
    };
    const selectedPermissionIds = Object.keys(checkboxValues)
      .filter((key) => checkboxValues[key])
      .map((key) => permissionIdMap[key]);
      try {
        const payload = {
          adminId: id,
          permissions: selectedPermissionIds,
        };
        const response = await axiosClient.post("/CompanyGroups/AddPermissionToAdmin", payload);
       
      } catch (error) {
        console.error("Error during POST request:", error);
      }
  };
   return (
    <>
      <div className="permissionsBtns">
        <button type="button" onClick={confirmation}>
          Təsdiq et
        </button>
      </div>
      <CustomModal isOpen={openModal} onClose={closeModal}>
        <div className="newCompanyHeader">
          <h2>Səlahiyyətlər</h2>
          <button onClick={closeModal}>
            <CancelIcon />
          </button>
        </div>
        <hr className="modalunderline" />
        <table className="unique-custom-table">
          <thead>
            <tr>
              <th rowSpan={2}>№</th>
              <th rowSpan={2}>İstifadəçi</th>
              <th rowSpan={2} className="turnText">
                Statistika
              </th>
              <th rowSpan={2} className="turnText">
                Videolar
              </th>
              <th rowSpan={2} className="turnText">
                Dərsliklər
              </th>
              <th colSpan={3}>İstifadəçilər</th>
            </tr>
            <tr>
              <th className="turnText" colSpan={1}>
                Əlavə et
              </th>
              <th className="turnText" colSpan={1}>
                Dəyiş
              </th>
              <th className="turnText" colSpan={1}>
                Blokla
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td className="unique-user-cell">
                <div className="centerNames">
                  <span>
                    <Avatar image={null} name={userData?.userFullName} />
                  </span>
                  <span>{userData?.userFullName}</span>
                </div>
              </td>
              <td>
                <input
                  type="checkbox"
                  name="statistics"
                  checked={checkboxValues.statistics}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.statistics}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="videos"
                  checked={checkboxValues.videos}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.videos}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="tutorials"
                  checked={checkboxValues.tutorials}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.tutorials}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="addUser"
                  checked={checkboxValues.addUser}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.addUser}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="editUser"
                  checked={checkboxValues.editUser}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.editUser}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="blockUser"
                  checked={checkboxValues.blockUser}
                  onChange={handleCheckboxChange}
                  disabled={!enabledPermissions.blockUser}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="permissionConfirmBtn">
          <button type="button" onClick={handleSave}>
            Yadda saxla
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default UserPermission;
