import React, { useEffect, useState } from 'react'
import { DocsIcon, EyeIcon, StarIcon } from '../../../../assets/icons'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axiosClient from '../../../../api/axiosClient';
const AnalytcsLessonsTop = ({tabNumber}) => {
  const [lessonStats, setLessonStats] = useState([]);
  useEffect(() => {
    const fetchProfileDataStats = async () => {
      const response = await axiosClient.get(
        `/UserVideoAndLesson/LessonInformation?tab=${tabNumber}`
      );
      if (response.status === 200) {
        setLessonStats(response.data);
      } else {
        throw new Error("Error fetching profile data");
      }
    };
    fetchProfileDataStats();
  }, [tabNumber]);
  return (
    <div className="videoStatistics">
    <div className="statisticsTop">
      <h2>Ümumi statistika</h2>
      <p>Dərsliklər üzrə göstəricilər</p>
    </div>
    <div className="statisticsBottom">
      <div className="videoStatContent">
        <EyeIcon />
        <p>Ümumi baxış sayı</p>{" "}
        <h2>
          {lessonStats && lessonStats.viewCount !== undefined ? (
            lessonStats.viewCount
          ) : (
            <Skeleton width={100} height={30} />
          )}
        </h2>
      </div>
      <div className="videoStatContent">
        <DocsIcon />
        <p>Yükləmələrin ümumi sayı</p>{" "}
        <h2>
          {lessonStats && lessonStats.downloadCount !== undefined ? (
            lessonStats.downloadCount
          ) : (
            <Skeleton width={100} height={30} />
          )}
        </h2>
      </div>
      <div className="videoStatContent">
        <StarIcon />
        <p>Reytinq</p>{" "}
        <h2>
          {lessonStats && lessonStats.rating !== undefined ? (
            lessonStats.rating
          ) : (
            <Skeleton width={100} height={30} />
          )}
        </h2>
      </div>
    </div>
  </div>
  )
}

export default AnalytcsLessonsTop