import React, { useEffect, useState } from "react";
import axiosClient from "../api/axiosClient";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/ReactContexify.css";
import { BlockedUsersIcon, BlockIcon, EditIcon, TrashIcon } from "../assets/icons";
import errorImg from "../images/circle-exclamation.png";
import { useNavigate } from "react-router-dom";
import '../App.css'
const MENU_ID = "blahblah";
function Home({ dbClick, closeModal, setSelectUsers,companyİd }) {
  const [modal, setModal] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [block, setBlock] = useState("");
  const [containerHeight, setContainerHeight] = useState("auto");
  const navigate = useNavigate();
  const [editedUser, setEditedUser] = useState({
    id: null,
    name: null,
    surname: null,
    email: null,
    phoneNumber: null,
  });
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  useEffect(() => {
    let url = '/CompanyGroups/GetAllUserForGroup';
    
    if (companyİd) {
      url += `?companyGroupId=${companyİd}`;
    }
    axiosClient
      .get(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);  
  const addUser = (e) => {
    e.preventDefault();
    axiosClient
      .post("/Auth/Register", {
        name: e.target.name.value,
        surname: e.target.surname.value,
        email: e.target.email.value,
        phoneNumber: e.target.phoneNumber.value,
      })
      .then((res) => {
        setData([{ users: res.data }].concat(data));
        setModal(false);
        setEmailError("");
        setNameError("");
        setLastNameError("");
        setPhoneError("");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err?.response?.data || {};
        setNameError(
          Array.isArray(errorData?.errors?.Name) &&
            errorData?.errors?.Name.length > 0
            ? errorData.errors.Name[0]
            : ""
        );
        setEmailError(
          Array.isArray(errorData?.errors?.Email) &&
            errorData?.errors?.Email.length > 0
            ? errorData.errors.Email[0]
            : ""
        );
        setLastNameError(
          Array.isArray(errorData?.errors?.Surname) &&
            errorData?.errors?.Surname.length > 0
            ? errorData.errors.Surname[0]
            : ""
        );
        setPhoneError(
          Array.isArray(errorData?.errors?.PhoneNumber) &&
            errorData?.errors?.PhoneNumber.length > 0
            ? errorData.errors.PhoneNumber[0]
            : ""
        );
      });
  };
  const updateUser = (e) => {
    e.preventDefault();
    axiosClient
      .put("/Auth/UpdateUserInformation/" + editedUser.id, {
        name: e.target.name.value,
        surname: e.target.surname.value,
        email: e.target.email.value,
        phoneNumber: e.target.phoneNumber.value,
      })
      .then((res) => {
        const _data = data;
        const _row = _data.find((x) => x.id == editedUser.id);
        _row.name = e.target.name.value;
        _row.surname = e.target.surname.value;
        _row.email = e.target.email.value;
        _row.phoneNumber = e.target.phoneNumber.value;
        setData(_data);
        setModalUpdate(false);
        setEmailError("");
        setNameError("");
        setLastNameError("");
        setPhoneError("");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err?.response?.data || {};
        setNameError(
          Array.isArray(errorData?.errors?.Name) &&
            errorData?.errors?.Name.length > 0
            ? errorData.errors.Name[0]
            : ""
        );
        setLastNameError(
          Array.isArray(errorData?.errors?.Surname) &&
            errorData?.errors?.Surname?.length > 0
            ? errorData?.errors?.Surname[0]
            : ""
        );
        setEmailError(
          Array.isArray(errorData?.errors?.Email) &&
            errorData?.errors?.Email?.length > 0
            ? errorData?.errors?.Email[0]
            : errorData?.message
            ? errorData?.message
            : ""
        );
        setPhoneError(
          Array.isArray(errorData?.errors?.PhoneNumber) &&
            errorData?.errors?.PhoneNumber?.length > 0
            ? errorData?.errors?.PhoneNumber[0]
            : ""
        );
      });
  };
  const deleteUser = () => {
    axiosClient
      .delete("/Auth/DeleteUser/" + deleteUserId)
      .then((res) => {
        setData(data.filter((d) => d.id !== deleteUserId));
        setModalRemove(false);
        setEmailError("");
        setNameError("");
        setLastNameError("");
        setPhoneError("");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };
  function handleContextMenu(event, itemid,blocked) {
    setBlock(blocked)
    console.log(blocked);
    
    show({
      event,
      props: {
        itemid,
      },
    });
  }
  const handleItemClick = ({ id, event, props }) => {
    switch (id) {
      case "edit":
        setModalUpdate(true);
        const u = data.find((x) => x.id == props.itemid);
        u.id = props.itemid;
        setEditedUser(u);
        break;
      case "remove":
        setDeleteUserId(props.itemid);
        setModalRemove(true);
        break;
      case "profile":
        navigate(`/details/${props.itemid}`);
        break;
      case "block":
        handleBlock(props.itemid)
        break;
    }
  };
  const handleBlock = async (id) => {
    try {
      const response = await axiosClient.put(
        `/CompanyGroups/Block?userId=${id}`,
        {},
        { notification: false }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const handleOverlayClick = (e, closeFunction) => {
    if (e.target === e.currentTarget) {
      closeFunction(false);
      setEmailError("");
      setNameError("");
      setLastNameError("");
      setPhoneError("");
    }
  };
  const handleNameChange = () => {
    setNameError("");
  };
  const handleLastNameChange = () => {
    setLastNameError("");
  };
  const handleEmailChange = () => {
    setEmailError("");
  };
  const handlePhoneChange = () => {
    setPhoneError("");
  };
  const handleModalClose = () => {
    setModalRemove(false);
    setModalUpdate(false);
    setModal(false);
    setEmailError("");
    setNameError("");
    setLastNameError("");
    setPhoneError("");
  };

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;
      setContainerHeight(`${viewportHeight - 100}px`);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  const selectUser = (user) => {
    setSelectUsers({
      label: `${user.name}${" "}${user.surname}`,
      value: user.id,
    });
    closeModal();
  };
  return (
    <>
      <div className={`card ${dbClick ? "Cardmodal" : ""}`}>
        <div className="card-header">
          <h2 className="card-title">İstifadəçilər</h2>
          {dbClick ? (
            <h2 onClick={() => closeModal()}>x</h2>
          ) : (
            <>
              <button className="card-button" onClick={() => setModal(true)}>
                Yeni istifadəçi
              </button>
            </>
          )}
        </div>
        <div className="tableDivContainer">
          <table className="table">
            <thead>
              <tr>
                <th>№</th>
                <th>Ad</th>
                <th>Soyad</th>
                <th>Email</th>
                <th>Telefon</th>
                {dbClick ? null : <th></th>}
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr
                  key={d.email}
                  onContextMenu={(event) => handleContextMenu(event, d.id,d.isBlock)}
                  onClick={dbClick ? () => selectUser(d) : null}
                >
                  <td>{i + 1}</td>
                  <td>{d.name}</td>
                  <td>{d.surname}</td>
                  <td>{d.email}</td>
                  <td>{d.phoneNumber}</td>
                  {dbClick ? null : (
                    <td
                      className="contextMenuTd"
                      style={{ paddingRight: "5px" }}
                    >
                      <i
                        className="fa-solid fa-ellipsis-vertical"
                        onClick={(event) => handleContextMenu(event, d.id,d.isBlock)}
                      ></i>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modal && (
        <div
          className="user-modal"
          onClick={(e) => handleOverlayClick(e, setModal)}
        >
          <form className="modal-card" onSubmit={addUser}>
            <div className="modal-header">
              <h4 className="modal-title">Yeni istifadəçi</h4>
              <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
            </div>
            <div className="modal-body">
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    nameError ? "errorMessage" : ""
                  }`}
                  required
                  name="name"
                  placeholder="Ad"
                  onChange={handleNameChange}
                />
                {nameError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{nameError}</p>
                  </div>
                )}
              </div>
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    lastNameError ? "errorMessage" : ""
                  }`}
                  required
                  name="surname"
                  placeholder="Soyad"
                  onChange={handleLastNameChange}
                />
                {lastNameError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{lastNameError}</p>
                  </div>
                )}
              </div>
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    emailError ? "errorMessage" : ""
                  }`}
                  required
                  name="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{emailError}</p>
                  </div>
                )}
              </div>
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    phoneError ? "errorMessage" : ""
                  }`}
                  name="phoneNumber"
                  placeholder="Telefon"
                  onChange={handlePhoneChange}
                />
                {phoneError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{phoneError}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={handleModalClose}
              >
                Ləğv et
              </button>
              <button type="submit" className="save">
                Yadda saxla
              </button>
            </div>
          </form>
        </div>
      )}
      {modalUpdate && (
        <div
          className="user-modal"
          onClick={(e) => handleOverlayClick(e, setModalUpdate)}
        >
          <form className="modal-card" onSubmit={updateUser}>
            <div className="modal-header">
              <h4 className="modal-title">İstifadəçi dəyiş</h4>
              <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
            </div>
            <div className="modal-body">
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    nameError ? "errorMessage" : ""
                  }`}
                  required
                  name="name"
                  placeholder="Ad"
                  onChange={handleNameChange}
                  defaultValue={editedUser.name}
                />
                {nameError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{nameError}</p>
                  </div>
                )}
              </div>
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    lastNameError ? "errorMessage" : ""
                  }`}
                  required
                  name="surname"
                  placeholder="Soyad"
                  onChange={handleLastNameChange}
                  defaultValue={editedUser.surname}
                />
                {lastNameError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{lastNameError}</p>
                  </div>
                )}
              </div>
              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    emailError ? "errorMessage" : ""
                  }`}
                  required
                  name="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  defaultValue={editedUser.email}
                />
                {emailError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{emailError}</p>
                  </div>
                )}
              </div>

              <div className="userErrors">
                <input
                  className={`form-control-secondary ${
                    phoneError ? "errorMessage" : ""
                  }`}
                  name="phoneNumber"
                  placeholder="Telefon"
                  onChange={handlePhoneChange}
                  defaultValue={editedUser.phoneNumber}
                />
                {phoneError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{phoneError}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={handleModalClose}
              >
                Ləğv et
              </button>
              <button type="submit" className="save">
                Yadda saxla
              </button>
            </div>
          </form>
        </div>
      )}
      {modalRemove && (
        <div
          className="user-modal modal-remove"
          onClick={(e) => handleOverlayClick(e, setModalRemove)}
        >
          <div className="modal-card">
            <div className="modal-header">
              <h4 className="modal-title">Sil</h4>
              <i className="fa-solid fa-xmark" onClick={handleModalClose}></i>
            </div>
            <div className="modal-body">
              <h3>İstifadəçini silmək istədiyinizə əminsinizmi?</h3>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={handleModalClose}
              >
                Ləğv et
              </button>
              <button type="submit" className="save" onClick={deleteUser}>
                Bəli
              </button>
            </div>
          </div>
        </div>
      )}
      <Menu id={MENU_ID}>
      <Item id="profile" onClick={handleItemClick} className="hoverItemUsers">
        <p className="userProfileNav">
          <i className="fa-regular fa-user"></i>
        </p>
        Profile
      </Item>
      <Item id="edit" onClick={handleItemClick} className="hoverItemUsers">
        <EditIcon className="userEditIcon" />
        Düzəliş et
      </Item>
      <Item id="remove" onClick={handleItemClick} className="hoverItemUsers">
        <TrashIcon className="userTrashIcon" />
        Sil
      </Item>
      <Item id="blcok" onClick={handleItemClick} className="hoverItemUsers">
              <BlockedUsersIcon className="userBlockIcon" />
        {block ? 'Blokdan çıxar' : 'Blokla'}
      </Item>
    </Menu>
    
    </>
  );
}

export default Home;
