import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import './Departments.css';

const LessonList = ({ setSelectedLessons, selectedLessons, lessonData, setLessonData }) => {
  useEffect(() => {
    if (!lessonData) return;

    const allowedLessonIds = lessonData
      .filter(lesson => lesson.isAllowed)
      .map(lesson => lesson.lessonGroupId);

    setSelectedLessons(prevSelected => {
      const newSelection = [...new Set([...prevSelected, ...allowedLessonIds])];
      if (JSON.stringify(prevSelected) !== JSON.stringify(newSelection)) {
        return newSelection;
      }
      return prevSelected;
    });
  }, [lessonData, setSelectedLessons]);

  const handleCheckboxChange = (lessonGroupId) => {
    setSelectedLessons((prevSelected) =>
      prevSelected.includes(lessonGroupId)
        ? prevSelected.filter((id) => id !== lessonGroupId)
        : [...prevSelected, lessonGroupId]
    );

    setLessonData((prevLessonData) =>
      prevLessonData.map((lesson) =>
        lesson.lessonGroupId === lessonGroupId
          ? { ...lesson, isAllowed: !selectedLessons.includes(lessonGroupId) }
          : lesson
      )
    );
  };

  const isLoading = !lessonData || lessonData.length === 0;

  return (
    <div className="unique-video-list-container">
      <div className="unique-video-column">
        {isLoading
          ? Array.from({ length: lessonData.length }).map((_, index) => (
              <div className="unique-video-item" key={index}>
                <Skeleton width={150} height={20} style={{ marginLeft: 10 }} />
              </div>
            ))
          : lessonData.map((lesson) => (
              <div className="unique-video-item" key={lesson.lessonGroupId}>
                <input
                  type="checkbox"
                  className="unique-checkbox"
                  checked={selectedLessons.includes(lesson.lessonGroupId) || lesson.isAllowed}
                  onChange={() => handleCheckboxChange(lesson.lessonGroupId)}
                />
                <label className="unique-video-label">
                  {lesson.lessonGroupName}
                </label>
              </div>
            ))}
      </div>
    </div>
  );
};

export default LessonList;
