import * as React from "react";
import PropTypes from "prop-types";

const SearchIcon = ({
  size = 16,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66666 14.0002C11.1645 14.0002 14 11.1646 14 7.66683C14 4.16903 11.1645 1.3335 7.66666 1.3335C4.16886 1.3335 1.33333 4.16903 1.33333 7.66683C1.33333 11.1646 4.16886 14.0002 7.66666 14.0002Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1363 15.1968C14.4292 15.4897 14.9041 15.4897 15.197 15.1968C15.4899 14.9039 15.4899 14.4291 15.197 14.1362L14.1363 15.1968ZM13.197 12.1362L12.6667 11.6058L11.606 12.6665L12.1363 13.1968L13.197 12.1362ZM15.197 14.1362L13.197 12.1362L12.1363 13.1968L14.1363 15.1968L15.197 14.1362Z"
      fill="white"
    />
  </svg>
);

SearchIcon.displayName = "Check";

SearchIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default SearchIcon;
