import * as React from "react";
import PropTypes from "prop-types";

const BookIcon = ({
  size = 30,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg  width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 31 31"
   xmlns="http://www.w3.org/2000/svg">
    <path d="M4.8645 22.9485V9.18497C4.8645 4.18006 6.11573 2.92883 11.1206 2.92883H19.8792C24.8841 2.92883 26.1354 4.18006 26.1354 9.18497V21.6972C26.1354 21.8724 26.1354 22.0476 26.1229 22.2228" fill="black"/>
    <path d="M4.8645 22.9485V9.18497C4.8645 4.18006 6.11573 2.92883 11.1206 2.92883H19.8792C24.8841 2.92883 26.1354 4.18006 26.1354 9.18497V21.6972C26.1354 21.8724 26.1354 22.0476 26.1229 22.2228" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.4305 19.1948H26.1354V23.5741C26.1354 25.989 24.1709 27.9534 21.7561 27.9534H9.2438C6.82893 27.9534 4.8645 25.989 4.8645 23.5741V22.7608C4.8645 20.7964 6.46607 19.1948 8.4305 19.1948Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.495 9.18494H20.5048" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.495 13.5643H16.7511" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
);

BookIcon.displayName = "Check";

BookIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default BookIcon;