import React, { useState, useEffect, useCallback, useRef } from "react";
import Comments from "./Comments";
import axiosClient from "../../api/axiosClient";

const MainComment = ({ videoId,commentsContainerRef }) => {
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const fetchComments = useCallback(async () => {
    if (!hasMore) return;
    try {
      const response = await axiosClient.get(`/Comments/GetALlParents?videoId=${videoId}&skip=${page}&take=5`);
      const commentsWithReplies = response.data.map((comment) => ({
        ...comment,
        showReplyCommits: comment.showReplyCommits || [],
      }));
      
      setComments((prevComments) => [...prevComments, ...commentsWithReplies]);
      setHasMore(commentsWithReplies.length > 0);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }, [videoId, page, hasMore]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  useEffect(() => {
    setComments([]);
    setPage(0);
    setHasMore(true);
    window.scrollTo(0, 0); 
  }, [videoId]);

  const handleScroll = () => {
    const container = commentsContainerRef.current;
    if (container) {
      const bottom = container.scrollHeight === container.scrollTop + container.clientHeight;
      if (bottom && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const container = commentsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore]);

  const addComment = (commentText) => {
    const newComment = {
      videoId: videoId,
      content: commentText,
    };
    axiosClient
      .post("/Comments/Create", newComment)
      .then((response) => {
        setComments((prevComments) => [{ ...response.data, showReplyCommits: [] }, ...prevComments]);
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
      });
  };

  const replyToCommentOrReply = (commentId, replyText, userId) => {
    const newReply = {
      videoId: videoId,
      content: replyText,
      refUserId: userId,
      parentId: commentId,
    };

    return axiosClient.post("/Comments/Create", newReply)
      .then((response) => {
        setComments((prevComments) =>
          prevComments.map((comment) => {
            if (comment.id === commentId) {
              return {
                ...comment,
                showReplyCommits: [...(comment.showReplyCommits || []), response.data],
              };
            }
            return comment;
          })
        );
        return response.data;
      })
      .catch((error) => {
        console.error("Error adding reply:", error);
        throw error;
      });
  };

  const deleteComment = (commentId) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== commentId)
    );
  };

  return (
    <div className="container">
      <Comments
        comments={comments}
        onAddComment={addComment}
        videoId={videoId}
        onReply={replyToCommentOrReply}
        onDelete={deleteComment}
      />
    </div>
  );
};

export default MainComment;
