import React from "react";

const time = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const today = now.toISOString().split("T")[0];
  const yesterday = new Date(now.setDate(now.getDate() - 1))
    .toISOString()
    .split("T")[0];
  const formattedDate = date.toISOString().split("T")[0];
  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear(); 
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (formattedDate === today) {
    return `Bugün, ${hours}:${minutes}`;
  } else if (formattedDate === yesterday) {
    return `Dünən, ${hours}:${minutes}`;
  } else if (diffDays < 7) {
    const dayNames = [
      "Bazar",
      "Bazar ertəsi",
      "Çərşənbə axşamı",
      "Çərşənbə",
      "Cümə axşamı",
      "Cümə",
      "Şənbə",
    ];
    const dayName = dayNames[date.getDay()];
    return `${dayName}, ${hours}:${minutes}`;
  } else {
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
};

export default time;
