import React, { useEffect, useState } from 'react'
import BarChart from '../../../components/Charts/BarChart'
import axiosClient from '../../../api/axiosClient';

const DetailsStats = ({id,tabNumber}) => {
  const [barData, setBarData] = useState([]);
  useEffect(() => {
    const fetchProfileDataStats = async () => {
      const response = await axiosClient.get(
        `/User/UserDetailStatistic?userId=${id}&tab=${tabNumber}&skip=0&take=10`
      );
      if (response.status === 200) {
        setBarData(response.data);
      } else {
        throw new Error("Error fetching profile data");
      }
    };
    fetchProfileDataStats();
  }, [tabNumber]);
  return (
    <div>
        {/* <h3>Progress</h3> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding:"0px 20px"
            }}
          >
            <div className="userStatsTime">
              <h4 style={{ textAlign: "center", marginBottom: "10px" }}>30</h4>
              <p>Saat</p>
            </div>
           
          </div> */}
          <div className="progress-charts">
            <BarChart tabNumber={tabNumber} id={id}/>
          </div>
    </div>
  )
}

export default DetailsStats