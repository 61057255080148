import React, { useContext, useEffect, useRef, useState } from "react";
import "./Departments.css";
import {
  BlockedIcon,
  BlockIcon,
  CompanyAdminIcon,
  CompanyPermissionsIcon,
  CompanyStatsIcon,
  CompanyUsersIcon,
  DotIcon,
  EditIcon,
  PlusIcon,
} from "../../assets/icons";
import CustomModal from "../../components/Modal/CustomModal";
import DepartmentNewCompany from "./DepartmentNewCompany";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import GroupPermission from "./GroupPermission";
import Skeleton from "react-loading-skeleton";
import { AuthContext } from "../../context/AuthContext";
import DepartmentEdit from "./DepartmentEdit";

function Departments() {
  const [activeCardId, setActiveCardId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPermission, setOpenModalPermission] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [departments, setDepartments] = useState({
    companyGroupDtos: [],
  });
  const [selectedDeptId, setSelectedDeptId] = useState(null);
  const [menuPosition, setMenuPosition] = useState({});
  const [loading, setLoading] = useState(true);
  const [editedData, setEditedData] = useState([]);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { term } = location.state || {};
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const id = /^\d+$/.test(lastSegment) ? lastSegment : null;
  const { auth } = useContext(AuthContext);
  const toggleActive = (id, cardRef) => {
    setActiveCardId((prevId) => (prevId === id ? null : id));
    if (cardRef) {
      adjustMenuPosition(cardRef);
    }
  };

  const adjustMenuPosition = (cardRef) => {
    const cardRect = cardRef.getBoundingClientRect();
    const menuStyles = {};

    menuStyles.left = cardRect.left < 50 ? "30px" : "auto";
    menuStyles.right =
      cardRect.right > window.innerWidth - 140 ? "30px" : "auto";

    menuStyles.top =
      cardRect.bottom > window.innerHeight - 140 ? "-250px" : "30px";

    setMenuPosition(menuStyles);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveCardId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isModalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const isModalOpenPermission = (id) => {
    setSelectedDeptId(id);
    setOpenModalPermission(true);
  };

  const closeModalPermission = () => {
    setOpenModalPermission(false);
  };
  const isModalOpenEdit = (id) => {
    setOpenModalEdit(true);
  };

  const closeModalEdit = () => {
    setOpenModalEdit(false);
  };

  const departmentUsers = (id, event) => {
    event.stopPropagation();
    navigate(`/departments/users/${id}`);
  };

  const departmentAdmin = (id, event) => {
    event.stopPropagation();
    navigate(`/departments/admin/${id}`);
  };

  const departmentAnalytics = (id, event) => {
    event.stopPropagation();
    navigate(`../analitika`);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get(
          id
            ? `/CompanyGroups/GetAllCompanyGroups?companyGroupId=${id}`
            : `/CompanyGroups/GetAllCompanyGroups?skip=0&take=10`
        );
        if (response.status === 200) {
          setDepartments(response.data);
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error("Error in fetchProfileData:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileData();
  }, [id]);

  const handleAddDepartment = (newDepartment) => {
    setDepartments((prevDepartments) => ({
      ...prevDepartments,
      companyGroupDtos: [
        ...(prevDepartments.companyGroupDtos || []),
        newDepartment,
      ],
    }));
  };

  const handleBlock = async (id) => {
    try {
      const response = await axiosClient.put(
        `/CompanyGroups/Block?groupId=${id}`,
        {},
        { notification: false }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const openDepartment = (id) => {
    navigate(`/departments/${id}`);
  };
  const handleEdit = (item) => {
    setOpenModalEdit(true)
    setEditedData(item)
  };

  return (
    <div className="departments-container">
      {openModal && (
        <CustomModal isOpen={isModalOpen} onClose={closeModal} mid={true}>
          <DepartmentNewCompany
            closeModal={closeModal}
            id={departments.parentId}
            setDepartments={handleAddDepartment}
          />
        </CustomModal>
      )}
      {openModalEdit && (
        <CustomModal isOpen={isModalOpenEdit} onClose={closeModalEdit} mid={true}>
          <DepartmentEdit
            closeModal={closeModalEdit}
            id={departments.parentId}
            data={editedData}
            setDepartments={handleAddDepartment}
          />
        </CustomModal>
      )}
      {openModalPermission && (
        <CustomModal
          isOpen={isModalOpenPermission}
          onClose={closeModalPermission}
          mid={true}
        >
          <GroupPermission
            closeModal={closeModalPermission}
            id={selectedDeptId}
            parentId={departments.parentId}
          />
        </CustomModal>
      )}
      <div className="departments-header">
        <h2>Qruplar</h2>
        {departments.canCreate && (
          <button onClick={isModalOpen}>
            <span>
              <PlusIcon />
            </span>
            <span>Yeni qrup</span>
          </button>
        )}
      </div>

      <div className="departments-grid-container">
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="card cardDepartment" key={index}>
                <Skeleton height={100} />
              </div>
            ))
          : departments?.companyGroupDtos?.map((dept) => (
              <div
                className="card cardDepartment"
                key={dept.id}
                onClick={(e) => {
                  if (!e.target.closest(".MoreContextCompany")) {
                    openDepartment(dept.id);
                  }
                }}
              >
                <div className="card-icon">
                  <img src={dept.iconUrl} alt="user avatar" />
                  <div className="MoreContextCompany">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleActive(dept.id, e.currentTarget.closest(".card"));
                      }}
                    >
                      <DotIcon />
                    </button>
                    {activeCardId === dept.id && (
                      <ul ref={menuRef} style={menuPosition}>
                        <li
                          onClick={(event) => departmentUsers(dept.id, event)}
                        >
                          <CompanyUsersIcon />
                          <p>İstifadəçilər</p>
                        </li>
                        <li
                          onClick={(event) => departmentAdmin(dept.id, event)}
                        >
                          <CompanyAdminIcon />
                          <p>Admin teyin et</p>
                        </li>
                        <li
                          onClick={(event) =>
                            departmentAnalytics(dept.id, event)
                          }
                        >
                          <CompanyStatsIcon />
                          <p>Statistikaya bax</p>
                        </li>
                        <li onClick={() => isModalOpenPermission(dept.id)}>
                          <CompanyPermissionsIcon />
                          <p>Video/dərslik icazəsi</p>
                        </li>
                        <li onClick={() => handleEdit(dept)}>
                          <EditIcon />
                          <p>Dəyişiklik</p>
                        </li>
                        {auth.userStatus === "SuperAdmin" ? (
                          <li onClick={() => handleBlock(dept.id)}>
                            <BlockIcon />
                            {!dept.isActive ? (
                              <p>Blokdan Çıxar</p>
                            ) : (
                              <p>Blokla</p>
                            )}
                          </li>
                        ) : null}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="card-name departmentCardName">
                  <p>{dept.name}</p>
                  <p>{!dept.isActive ? <BlockedIcon /> : null}</p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default Departments;
