import React, { useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import axiosClient from "../../api/axiosClient";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const NotificationModal = ({ show, onClose, id }) => {
  const [Application, setApplication] = useState({});
  const [loading, setLoading] = useState(true);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const fetchReplies = () => {
    setLoading(true); // Set loading to true before fetching data
    axiosClient
      .get(`/Applications/GetById/id?id=${id}`)
      .then((response) => {
        setApplication(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching replies:", error);
        setLoading(false); // Also set loading to false on error
      });
  };

  useEffect(() => {
    if (show) {
      fetchReplies();
    }
  }, [show, id]);

  if (!show) return null;

  return (
    <div className="modalOverlayNotification">
      <div className="modalContentNotification" ref={modalRef}>
        <div className="modalHeaderNotification">
          {loading ? (
            <Skeleton circle={true} height={40} width={40} />
          ) : (
            <Avatar image={null} name={Application.name} />
          )}
          <h3>Ümumi məlumat</h3>
        </div>
        <hr />
        <div>
          <div className="modalRow">
            <h4>Ad:</h4> 
            {loading ? <Skeleton width={90} height={25}/> : <p>{Application.name}</p>}
          </div>
          <div className="modalRow">
            <h4>Soyad:</h4> 
            {loading ? <Skeleton width={90} height={25}/> : <p>{Application.surname}</p>}
          </div>
          <div className="modalRow">
            <h4>Email:</h4> 
            {loading ? <Skeleton width={140} height={25} /> : <p>{Application.email}</p>}
          </div>
          <div className="modalRow">
            <h4>Əlaqə nömrəsi:</h4> 
            {loading ? <Skeleton width={90} height={25}/> : <p>{Application.phone}</p>}
          </div>
        </div>
        <div className="requestsNotification">
          <h4>Müraciət</h4>
          {loading ? <Skeleton  height={100} /> : <p>{Application.apllicationText}</p>}
        </div>
        <div className="closeButtonNotification">
          <button onClick={onClose}>Bağla</button>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
