import React, { useEffect, useState } from "react";
import "./AnalytcsTable.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AnalytcsTable = ({ headers, loading, children,data }) => {
  const [displayedChildren, setDisplayedChildren] = useState(children);

  useEffect(() => {
    setDisplayedChildren(children);
  }, [children]);

  return (
    <div className="scrollable-container">
      <table className="custom-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} rowSpan={header.rowSpan || 1}>
                {header.icon ? <header.icon /> : header.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedChildren?.length > 0 ? (
            displayedChildren
          ) : (
            !loading &&<tr>
              <td colSpan={headers?.length}>No data available</td>
            </tr>
          )}
          {loading && (
            <tr>
              <td>
                <div style={{ width: "200%", maxWidth: "200px" }}>
                  <Skeleton height={20} />
                </div>
              </td>
              <td>
                <div className="analytc_table_image_div">
                  <div style={{ width: "200%", maxWidth: "200px" }}>
                    <Skeleton height={20} />
                  </div>
                  <div style={{ width: "200%", maxWidth: "200px" }}>
                    <Skeleton height={20} />
                  </div>
                </div>
              </td>
              <td>
                <div style={{ width: "100%", maxWidth: "200px" }}>
                  <Skeleton height={20} />
                </div>
              </td>
              <td>
                <div style={{ width: "100%", maxWidth: "200px" }}>
                  <Skeleton height={20} />
                </div>
              </td>
              <td>
                <div style={{ width: "100%", maxWidth: "160px" }}>
                  <Skeleton height={20} />
                </div>
              </td>
              <td>
                <div style={{ width: "100%", maxWidth: "160px" }}>
                  <Skeleton height={20} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AnalytcsTable;
