import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Analtycs.css';
import AnalytcsUsers from './AnalytcsUsers/AnalytcsUsers';
import AnalytcsVideos from './AnalytcsVideos/AnalytcsVideos';
import AnalytcsLessons from './AnalytcsLessons/AnalytcsLessons';
import AnalytcsActive from './AnalytcsActive/AnalytcsActive';
const Analytcs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [scrollableDiv, setScrollableDiv] = useState(null);
  const setRef = (node) => {
    setScrollableDiv(node);
  };
  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get('tab')) || 0;
  };

  const getActiveDateTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get('dateTab')) || 0;
  };
  const [activeTab, setActiveTab] = useState(getActiveTabFromURL());
  const [activeDateTab, setActiveDateTab] = useState(getActiveDateTabFromURL());
  const tabRefs = useRef([]);
  const underlineRef = useRef(null);
  const [tabNumber, setTabNumber] = useState(activeDateTab + 1);
  const [secondTabNumber, setSecondTabNumber] = useState(activeDateTab + 1);

  const handleTabClick = (index) => {
    setActiveTab(index);
    const params = new URLSearchParams(location.search);
    params.set('tab', index);
    navigate({ search: params.toString() });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setSecondTabNumber(index+1)
  };

  const handleDateTabClick = (index) => {
    setActiveDateTab(index);
    const params = new URLSearchParams(location.search);
    params.set('dateTab', index);
    navigate({ search: params.toString() });
    setTabNumber(index + 1);
  };

  useEffect(() => {
    setActiveTab(getActiveTabFromURL());
    setActiveDateTab(getActiveDateTabFromURL());
  }, [location.search]);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = 'none';
    }
  }, []);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = 'transform 0.3s ease, width 0.3s ease';
    }
  }, [activeTab]);

  return (
    <div className='analytcs_main'>
      <div className='tabMenuContainer'>
        <div className='tabMenu'>
          <div className='tabUnderlineContainer'>
            {['İstifadəçilər', 'Videolar', 'Dərsliklər','Aktivlik'].map((tab, index) => (
              <button
                key={index}
                ref={(el) => (tabRefs.current[index] = el)}
                className={`tabMenuButton ${activeTab === index ? 'activeTab' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab}
              </button>
            ))}
            <div ref={underlineRef} className='tabUnderline' />
          </div>
        </div>
      </div>

      <div className='analytic_date_tab'>
        <ul className='dateTabMenu'>
          {['Gün', 'Ay', 'İl'].map((dateTab, index) => (
            <li
              key={index}
              className={`dateTab ${activeDateTab === index ? 'activeDateTab' : ''}`}
              onClick={() => handleDateTabClick(index)}
            >
              {dateTab}
            </li>
          ))}
        </ul>
      </div>

      <div className='content' ref={setRef}>
      {activeTab === 0 && <AnalytcsUsers tabNumber={tabNumber} scrollableDivRef={scrollableDiv} secondTabNumber={secondTabNumber}/>}
      {activeTab === 1 && <AnalytcsVideos tabNumber={tabNumber} scrollableDivRef={scrollableDiv}  secondTabNumber={secondTabNumber}  />}
      {activeTab === 2 && <AnalytcsLessons tabNumber={tabNumber} scrollableDivRef={scrollableDiv}  secondTabNumber={secondTabNumber} />}
      {activeTab===3&&<AnalytcsActive tabNumber={tabNumber} scrollableDivRef={scrollableDiv}  secondTabNumber={secondTabNumber} />}
    </div>
    </div>
  );
};

export default Analytcs;
