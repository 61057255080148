import * as React from "react";
import PropTypes from "prop-types";

const BlockIcon = ({
  size = 20,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 7.25C14 11.1328 10.8555 14.25 7 14.25C3.11719 14.25 0 11.1328 0 7.25C0 3.39453 3.11719 0.25 7 0.25C10.8555 0.25 14 3.39453 14 7.25ZM2.70703 4.21484C2.10547 5.08984 1.75 6.12891 1.75 7.25C1.75 10.1484 4.07422 12.5 7 12.5C8.12109 12.5 9.16016 12.1445 10.0352 11.543L2.70703 4.21484ZM12.25 7.25C12.25 4.35156 9.89844 2 7 2C5.85156 2 4.8125 2.38281 3.9375 2.98438L11.2656 10.3125C11.8672 9.4375 12.25 8.39844 12.25 7.25Z" fill="#919DA8"/>
  </svg>
);

BlockIcon.displayName = "Trash";

BlockIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default BlockIcon;
