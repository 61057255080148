
import * as React from "react";
import PropTypes from "prop-types";

const BlockedIcon = ({
  size = 15,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" fill="none">
<path d="M14.6 7.65918C14.6 11.542 11.4555 14.6592 7.60001 14.6592C3.71719 14.6592 0.600006 11.542 0.600006 7.65918C0.600006 3.80371 3.71719 0.65918 7.60001 0.65918C11.4555 0.65918 14.6 3.80371 14.6 7.65918ZM3.30704 4.62402C2.70547 5.49902 2.35001 6.53809 2.35001 7.65918C2.35001 10.5576 4.67422 12.9092 7.60001 12.9092C8.7211 12.9092 9.76016 12.5537 10.6352 11.9521L3.30704 4.62402ZM12.85 7.65918C12.85 4.76074 10.4984 2.40918 7.60001 2.40918C6.45157 2.40918 5.41251 2.79199 4.53751 3.39355L11.8656 10.7217C12.4672 9.84668 12.85 8.80762 12.85 7.65918Z" fill="#E1E1E1"/>
</svg>

);

BlockedIcon.displayName = "Edit";

BlockedIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default BlockedIcon;