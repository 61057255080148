


import * as React from "react";
import PropTypes from "prop-types";

const LeftArrowicon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 19.92L8.97997 13.4C8.20997 12.63 8.20997 11.37 8.97997 10.6L15.5 4.07999" stroke="#5F6573" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

LeftArrowicon.displayName = "Check";

LeftArrowicon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default LeftArrowicon;
