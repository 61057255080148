
import * as React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({
  size = 24, 
  active = false,
  ...props
}) => (
    <svg width={24} height={24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5.90918V19.9092M5 12.9092H19" stroke="#4593E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

PlusIcon.displayName = "PinIcon";

PlusIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.bool,
};

export default PlusIcon;
