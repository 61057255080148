
import * as React from "react";
import PropTypes from "prop-types";

const CompanyPermissionsIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.15625 0.316406C5.40234 0.5625 5.42969 0.972656 5.18359 1.24609L3.21484 3.43359C3.10547 3.54297 2.94141 3.625 2.75 3.625C2.58594 3.65234 2.39453 3.57031 2.28516 3.43359L1.19141 2.33984C0.917969 2.09375 0.917969 1.68359 1.19141 1.4375C1.4375 1.16406 1.84766 1.16406 2.09375 1.4375L2.72266 2.03906L4.22656 0.34375C4.47266 0.0976562 4.88281 0.0703125 5.15625 0.316406ZM5.15625 4.69141C5.40234 4.91016 5.42969 5.34766 5.18359 5.62109L3.21484 7.80859C3.10547 7.91797 2.94141 8 2.75 8C2.58594 8.02734 2.39453 7.94531 2.28516 7.80859L1.19141 6.71484C0.917969 6.46875 0.917969 6.05859 1.19141 5.78516C1.4375 5.53906 1.84766 5.53906 2.09375 5.78516L2.72266 6.41406L4.22656 4.71875C4.47266 4.47266 4.88281 4.44531 5.15625 4.69141ZM7.125 1.875C7.125 1.41016 7.50781 1 8 1H14.125C14.5898 1 15 1.41016 15 1.875C15 2.36719 14.5898 2.75 14.125 2.75H8C7.50781 2.75 7.125 2.36719 7.125 1.875ZM7.125 6.25C7.125 5.78516 7.50781 5.375 8 5.375H14.125C14.5898 5.375 15 5.78516 15 6.25C15 6.74219 14.5898 7.125 14.125 7.125H8C7.50781 7.125 7.125 6.74219 7.125 6.25ZM5.375 10.625C5.375 10.1602 5.75781 9.75 6.25 9.75H14.125C14.5898 9.75 15 10.1602 15 10.625C15 11.1172 14.5898 11.5 14.125 11.5H6.25C5.75781 11.5 5.375 11.1172 5.375 10.625ZM1 10.625C1 9.91406 1.57422 9.3125 2.3125 9.3125C3.02344 9.3125 3.625 9.91406 3.625 10.625C3.625 11.3633 3.02344 11.9375 2.3125 11.9375C1.57422 11.9375 1 11.3633 1 10.625Z" fill="#919DA8"/>
</svg>

);

CompanyPermissionsIcon.displayName = "Edit";

CompanyPermissionsIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CompanyPermissionsIcon;