import * as React from "react";
import PropTypes from "prop-types";

const LogOutIcon = ({
  size = 15,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"  />
    <path d="m16 17 5-5-5-5" />
    <path d="M21 12H9" />
  </svg>
);

LogOutIcon.displayName = "LogOut";

LogOutIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default LogOutIcon;