import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axiosClient from "../../api/axiosClient";

const ApexChart = ({ tabNumber, secondTabNumber }) => {
  const [activeUsers, setActiveUsers] = useState({ users: [], dates: [] });

  useEffect(() => {
    const fetchProfileDataStats = async () => {
      try {
        const response = await axiosClient.get(
          `/UserVideoAndLesson/ActiveUsers?tab=${tabNumber}`
        );
        if (response.status === 200) {
          setActiveUsers(response.data);
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProfileDataStats();
  }, [tabNumber, secondTabNumber]);

  const uniqueDates = Array.from(new Set(activeUsers.dates));
  const series = [
    {
      name: "Aktiv İstifadəçilər",
      data: uniqueDates.map(date => {
        const index = activeUsers.dates.indexOf(date);
        return index !== -1 ? activeUsers.users[index] : 0;
      }),
    },
  ];

  const options = {
    chart: {
      height: 220,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["#3E87D9"],
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: "datetime",
      categories: uniqueDates,
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          if (tabNumber === 2) {
            return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}`;
          } else if (tabNumber === 3) {
            // Ay isimlerini gösteriyoruz
            const monthNames = ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"];
            return monthNames[date.getMonth()];
          } else {
            return `${date.getHours().toString().padStart(2, '0')}:00`; 
          }
        },
        show: true,
      },
      min: new Date(Math.min(...activeUsers.dates.map(date => new Date(date)))).getTime(),
      max: new Date(Math.max(...activeUsers.dates.map(date => new Date(date)))).getTime(),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      x: {
        format: tabNumber === 2 ? "dd.MM" : "HH:mm",
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {activeUsers.users.length > 0 && uniqueDates.length > 0 ? (
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={220}
        />
      ) : null}
    </div>
  );
};

export default ApexChart;
