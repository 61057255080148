import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import PinIcon from "../assets/icons/PinIcon";
import {
  NavAnalytcsIcon,
  NavApplication,
  NavCompanyIcon,
  NavLessonIcon,
  NavLogOut,
  NavUserIcon,
  NavVideoIcon,
} from "../assets/icons";
import logo from "../images/SiescoLogo.svg";

function Nav() {
  const { auth, logout } = useContext(AuthContext);
  const [pinned, setPinned] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownCompanyOpen, setDropdownCompanyOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const handleLinkClick = (link, isCompanyLink = false) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
    if (!isCompanyLink) {
      setDropdownOpen(false);
      setDropdownCompanyOpen(false);
    }
  };

  useEffect(() => {
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
      if (
        storedActiveLink === "departments" ||
        storedActiveLink === "permissions"
      ) {
        setDropdownCompanyOpen(true);
      }
    }
  }, []);

  const resetActiveLink = () => setActiveLink("");

  const toggleDropdownCompany = () => {
    setPinned(isDropdownCompanyOpen && false);
    setDropdownOpen(false);
    setDropdownCompanyOpen((prev) => {
      if (prev) {
        return false;
      } else {
        setActiveLink("company");
        localStorage.setItem("activeLink", "company");
        return true;
      }
    });
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem("activeLink");
    handleClose();
  };

  const handleClose = () => {
    setDropdownOpen(!dropdownOpen);
    setDropdownCompanyOpen(dropdownOpen && false);
  };

  const pincCloseOpen = () => {
    setPinned(!pinned);
    setDropdownCompanyOpen(false);
  };

  return (
    <nav className={pinned ? "pin" : ""}>
      {dropdownOpen && (
        <ul className="navDropdownMenu">
          <li
            onClick={() => {
              handleClose();
              resetActiveLink();
            }}
          >
            <Link
              to="/profile"
              className={activeLink === "profile" ? "active" : ""}
              onClick={() => handleLinkClick("profile")}
            >
              <div className="spanUserProfile" style={{ cursor: "pointer" }}>
                <div className="avatarPhoto">
                  <img src={auth.photo} alt="" />
                </div>
                <p>{auth.userFullName}</p>
              </div>
            </Link>
          </li>
          <li
            onClick={() => {
              handleLogout();
              resetActiveLink();
            }}
          >
            <Link
              to="/login"
              className={activeLink === "logout" ? "active" : ""}
              onClick={() => handleLinkClick("logout")}
            >
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
              <p className="logoutFromNav">Çıxış</p>
            </Link>
          </li>
        </ul>
      )}
      {isDropdownCompanyOpen && (
        <ul className="navDropdownMenuCompany">
          <li>
            <Link
              to="/departments"
              className={activeLink === "departments" ? "active" : ""}
              onClick={() => handleLinkClick("departments", true)}
            >
              Qruplar
            </Link>
          </li>
          <li>
            <Link
              to="/permissions"
              className={activeLink === "permissions" ? "active" : ""}
              onClick={() => handleLinkClick("permissions", true)}
            >
              Səlahiyyətlər
            </Link>
          </li>
        </ul>
      )}
      <div className="nav-header">
        <Link to="//siesco.az">
          <img src={logo} alt="Logo" />
        </Link>
        <div className="ActivePin" onClick={pincCloseOpen}>
          <PinIcon active={pinned} />
        </div>
      </div>
      <ul>
        {(auth.userStatus === "Admin" || auth.userStatus === "SuperAdmin") && (
          <li>
            <Link
              to="/users"
              className={activeLink === "users" ? "active" : ""}
              onClick={() => handleLinkClick("users")}
            >
              <NavUserIcon />
              <span>İstifadəçilər</span>
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/"
            className={activeLink === "home" ? "active" : ""}
            onClick={() => handleLinkClick("home")}
          >
            <NavVideoIcon />
            <span>Videolar</span>
          </Link>
        </li>
        <li>
          <Link
            to="/documents"
            className={activeLink === "documents" ? "active" : ""}
            onClick={() => handleLinkClick("documents")}
          >
            <NavLessonIcon />
            <span>Dərsliklər</span>
          </Link>
        </li>
        {(auth.userStatus === "Admin" || auth.userStatus === "SuperAdmin") && (
          <>
            <li>
              <Link
                to="/analitika"
                className={activeLink === "analytics" ? "active" : ""}
                onClick={() => handleLinkClick("analytics")}
              >
                <NavAnalytcsIcon />
                <span>Analitika</span>
              </Link>
            </li>
            <li>
              <Link
                to="/notifications"
                className={activeLink === "notifications" ? "active" : ""}
                onClick={() => handleLinkClick("notifications")}
              >
                <NavApplication />
                <span className="notificationNavSpan">Müraciətlər</span>
              </Link>
            </li>
            <li onClick={toggleDropdownCompany}>
              <a
                className={`navlink ${
                  isDropdownCompanyOpen ||
                  activeLink === "company" ||
                  activeLink === "departments" ||
                  activeLink === "permissions"
                    ? "active"
                    : ""
                }`}
              >
                <NavCompanyIcon />
                <span className="notificationNavSpan">Şirkət</span>
                <span
                  className={`arrow ${isDropdownCompanyOpen ? "open" : ""}`}
                ></span>
              </a>
            </li>
            {isDropdownCompanyOpen && (
              <ul className="NavCompanyMenu">
                <li>
                  <Link
                    to="/departments"
                    className={activeLink === "departments" ? "active" : ""}
                    onClick={() => handleLinkClick("departments", true)}
                  >
                    Qruplar
                  </Link>
                </li>
                <li>
                  <Link
                    to="/permissions"
                    className={activeLink === "permissions" ? "active" : ""}
                    onClick={() => handleLinkClick("permissions", true)}
                  >
                    Səlahiyyətlər
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
        <li
          onClick={() => {
            handleLogout();
            resetActiveLink();
          }}
          className="displayNoneNavMenu"
        >
          <Link
            to="/login"
            className={activeLink === "logout" ? "active" : ""}
            onClick={() => handleLinkClick("logout")}
          >
            <NavLogOut />
            <span>Çıxış</span>
          </Link>
        </li>
        <li
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            resetActiveLink();
            setDropdownCompanyOpen(false);
          }}
          className="displayBlockNavMenu"
        >
          <i className="fa-solid fa-gear"></i>
          {/* <NavLogOut /> */}
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
