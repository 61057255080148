
import * as React from "react";
import PropTypes from "prop-types";

const CompanyAdminIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1875 0.25C16.8984 0.25 17.5 0.878906 17.5 1.61719V10.2852C17.5 11.0234 16.8984 11.625 16.1602 11.625H9.24219C8.91406 10.9414 8.44922 10.3398 7.84766 9.875H10.4727V8.5625C10.4727 8.34375 10.6641 8.125 10.9102 8.125H13.5352C13.7539 8.125 13.9727 8.34375 13.9727 8.5625V9.875H15.7227V2H6.09766V3.36719C5.60547 3.06641 5.00391 2.875 4.375 2.875V1.61719C4.375 0.878906 4.94922 0.25 5.6875 0.25H16.1875ZM4.375 9C2.89844 9 1.75 7.82422 1.75 6.375C1.75 4.92578 2.89844 3.75 4.375 3.75C5.82422 3.75 7 4.92578 7 6.375C7 7.85156 5.82422 9 4.375 9ZM5.25 9.875C7.16406 9.875 8.75 11.4609 8.75 13.375C8.75 13.8672 8.33984 14.25 7.875 14.25H0.875C0.382812 14.25 0 13.8672 0 13.375C0 11.4609 1.55859 9.875 3.5 9.875H5.25Z" fill="#919DA8"/>
</svg>


);

CompanyAdminIcon.displayName = "Edit";

CompanyAdminIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CompanyAdminIcon;