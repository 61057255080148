import * as React from "react";
import PropTypes from "prop-types";

const NavUserIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
<svg
width="13"
height="15"
viewBox="0 0 13 15"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M6.125 7.25C4.18359 7.25 2.625 5.69141 2.625 3.75C2.625 1.83594 4.18359 0.25 6.125 0.25C8.03906 0.25 9.625 1.83594 9.625 3.75C9.625 5.69141 8.03906 7.25 6.125 7.25ZM8.55859 8.125C10.582 8.125 12.25 9.79297 12.25 11.8164V12.9375C12.25 13.6758 11.6484 14.25 10.9375 14.25H1.3125C0.574219 14.25 0 13.6758 0 12.9375V11.8164C0 9.79297 1.64062 8.125 3.66406 8.125H4.12891C4.73047 8.42578 5.41406 8.5625 6.125 8.5625C6.83594 8.5625 7.49219 8.42578 8.09375 8.125H8.55859Z"
  fill="#919DA8"
/>
</svg>

);

NavUserIcon.displayName = "Check";

NavUserIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavUserIcon;
