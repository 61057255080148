

import * as React from "react";
import PropTypes from "prop-types";

const DownArrowIcon = ({
  size = 20,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 8L10 13L5 8" stroke="#7D7D7D" strokeWidth="2"/>
    </svg>
    
);

DownArrowIcon.displayName = "Edit";

DownArrowIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default DownArrowIcon;