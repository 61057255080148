
import * as React from "react";
import PropTypes from "prop-types";

const MailIcon = ({
  size = 18,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5833 18.7916H6.41659C3.66659 18.7916 1.83325 17.4166 1.83325 14.2083V7.79158C1.83325 4.58325 3.66659 3.20825 6.41659 3.20825H15.5833C18.3333 3.20825 20.1666 4.58325 20.1666 7.79158V14.2083C20.1666 17.4166 18.3333 18.7916 15.5833 18.7916Z" stroke="#82959E" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.2054 7.12134L12.1207 11.0504C11.4604 11.5606 10.539 11.5606 9.87871 11.0504L4.79395 7.12134" stroke="#82959E"strokeWidth="1.2" strokeLinecap="round"/>
</svg>

);

MailIcon.displayName = "Check";

MailIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default MailIcon;