import React, { useEffect, useRef, useState } from "react";
import "./AnalytcsActive.css";
import AnalytcsTable from "../AnalytcsTable/AnalytcsTable";
import axiosClient from "../../../api/axiosClient";
import AsyncSelectComponent from "../../../components/AsyncSelect/AsyncSelect";
import {
  SearchIcon,
} from "../../../assets/icons";
import UseDebounce from "../../../shared/hooks/UseDebonce";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker1 from "../../../components/DatePicker/DatePicker";
const headers = [
  { text: "№" },
  { text: "İstifadəçi adı" },
  { text: "Giriş vaxtı" },
  { text: "Çıxış vaxtı" },
  { text: "Aktivlik müddəti" },
  { text: "Status" },
];

const AnalytcsActive = React.forwardRef(
  ({ tabNumber, secondTabNumber, scrollableDivRef }, ref) => {
    const { ActiveUsers } = useContext(AuthContext);
    const [updatedFormData, setUpdatedFormData] = useState([]);
    const [selectedLessons, setSelectedLessons] = useState(null);
    const [searchFilters, setSearchFilters] = useState(null);
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultLessons, setDefaultLessons] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(10);
    const [hasMoreData, setHasMoreData] = useState(true);
    const loadingMoreRef = useRef(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = UseDebounce(searchTerm, 300);
    const [dates, setDates] = useState([]);
    const [mainTab, setMainTab] = useState(tabNumber);
    useEffect(() => {
      setMainTab(tabNumber);
      scrollableDivRef?.scrollTo(0, 0);
      setFormData([]);
      setUpdatedFormData([]);
      setSkip(0);
      setHasMoreData(true);
    }, [tabNumber]);

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axiosClient.get(
            `/UserActivities/Get?tab=${mainTab}&skip=${skip}&take=${take}`,
            { params: searchFilters }
          );

          if (response.status === 200) {
            if (response.data.length === 0 && skip > 0) {
              setHasMoreData(false);
            } else {
              setFormData((prev) => {
                const newData = [...prev, ...response.data];
                const uniqueData = Array.from(
                  new Set(newData.map((item) => item.id))
                ).map((id) => newData.find((item) => item.id === id));
                return uniqueData;
              });
              setHasMoreData(response.data.length === take);
            }
          } else {
            throw new Error("Error fetching profile data");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [mainTab, skip, searchFilters]);

    const navigate = useNavigate();
    const userDetails = (id) => {
      navigate(`../details/${id}`);
    };
    useEffect(
      () => {
        const handleScroll = () => {
          if (
            scrollableDivRef &&
            !loading &&
            hasMoreData &&
            scrollableDivRef.scrollTop + scrollableDivRef.clientHeight >=
              scrollableDivRef.scrollHeight - 100
          ) {
            if (!loadingMoreRef.current) {
              loadingMoreRef.current = true;
              setSkip((prevSkip) => prevSkip + 1);
            }
          }
        };

        if (scrollableDivRef) {
          scrollableDivRef.addEventListener("scroll", handleScroll);
          return () => {
            scrollableDivRef.removeEventListener("scroll", handleScroll);
          };
        }
      },
      [scrollableDivRef, loading],
      hasMoreData
    );
    useEffect(() => {
      if (!loading) {
        loadingMoreRef.current = false;
      }
    }, [loading]);
    const handleSearchClick = () => {
      const filters = {
        userId: selectedLessons ? selectedLessons.value : null,
        min: dates ? dates.min : null,
        max: dates ? dates.max : null,
      };
      setSearchFilters(filters);

      setFormData([]);
      setSkip(0);
      if (scrollableDivRef) {
        scrollableDivRef.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      setLoading(true);
    };

    useEffect(() => {
      const selectLessonData = async () => {
        try {
          const response = await axiosClient.get(
            `/UserActivities/GetSingleUsers?tab=${tabNumber}`
          );
          if (response.status === 200) {
            const lessons = response.data.map((item) => ({
              label: item.fullName,
              value: item.userId,
            }));
            setDefaultLessons([...lessons]);
          } else {
            throw new Error("Error fetching lesson data");
          }
        } catch (error) {
          console.error("Error fetching lesson data:", error);
        }
      };
      selectLessonData();
    }, [tabNumber]);
    const filterLessons = async (inputValue) => {
      if (!inputValue) return [];

      try {
        const response = await axiosClient.get(`/UserActivities/SearchUser`, {
          params: { name: inputValue, tab: tabNumber },
        });

        if (response.status === 200) {
          const lessons = response.data.map((item) => ({
            label: item.fullName,
            value: item.userId,
          }));
          setDefaultLessons(lessons);
          return lessons;
        }
      } catch (error) {
        console.error("Error fetching filtered lessons", error);
      }
      return [];
    };
    useEffect(() => {
      if (debouncedSearchTerm) {
        filterLessons(debouncedSearchTerm);
      } else {
        setDefaultLessons([]);
      }
    }, [debouncedSearchTerm]);

    const handleSelectChange = (selectedOption) => {
      if (selectedOption?.value === "view_all") {
      } else {
        setSelectedLessons(selectedOption);
        setSearchTerm(selectedOption?.label);
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };
    function convertDurationToString(duration) {
      if (typeof duration !== "string") {
        return "0";
      }
      const timeParts = duration.split(":");
      if (timeParts.length !== 3) {
        return "0";
      }
      const [hours, minutes, seconds] = timeParts.map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + Math.floor(seconds);
      const days = Math.floor(totalSeconds / 86400);
      const remainingSecondsAfterDays = totalSeconds % 86400;
      const hoursLeft = Math.floor(remainingSecondsAfterDays / 3600);
      const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
      const minutesLeft = Math.floor(remainingSecondsAfterHours / 60);
      const secondsLeft = remainingSecondsAfterHours % 60;
      const parts = [];
      if (days > 0) parts.push(`${days}g`);
      if (hoursLeft > 0) parts.push(`${hoursLeft}s`);
      if (minutesLeft > 0) parts.push(`${minutesLeft}d`);
      if (secondsLeft > 0) parts.push(`${secondsLeft}s`);
      return parts.join(" ") || "0s";
    }
    useEffect(() => {
      if (formData && formData.length > 0) {
        const newFormData = formData.map((elem) => {
          const isOnline =
            elem.isActive &&
            ActiveUsers.some((user) => user.userId === elem.userId);
          return { ...elem, isOnline };
        });
        setUpdatedFormData(newFormData);
      }
    }, [formData, ActiveUsers]);
    return (
      <div className="mainLessons">
        <div className="DropdDownMain">
          <div className="dropDowns2">
            <AsyncSelectComponent
              placeholder={"Istifadəçilər"}
              handleChange={handleSelectChange}
              filterData={filterLessons}
              defaultOptions={defaultLessons}
              onInputChange={(inputValue) => setSearchTerm(inputValue)}
            />
            <DatePicker1 setData={setDates} />
            <button className="search-button" onClick={handleSearchClick}>
              <p>Axtar</p>
              <span>
                <SearchIcon />
              </span>
            </button>
          </div>
        </div>
        <AnalytcsTable
          headers={headers}
          data={formData}
          loading={loading}
          tabNumber={tabNumber}
          secondTabNumber={secondTabNumber}
        >
          {formData && formData.length > 0
            ? updatedFormData.map((elem, i) => {
                const isOnline =
                  elem.isActive &&
                  ActiveUsers.some((user) => user.userId === elem.userId);

                return (
                  <tr key={elem.id} onClick={() => userDetails(elem.userId)}>
                    <td className="analytc_table_id">{i + 1}</td>
                    <td>
                      <div className="analytc_table_image_div">
                        <div className="analytc_table_image_divs">
                          <img src={elem.photo} alt="img" />
                        </div>
                        <span>{elem.fullName}</span>
                      </div>
                    </td>
                    <td>
                      <div className="analytc_table_date_div">
                        <span>
                          {formatDate(elem?.enterDate?.substring(0, 10))}
                        </span>
                        <span>{elem?.enterDate?.substring(11, 16)}</span>
                      </div>
                    </td>
                    <td>
                      {elem.isActive ? (
                        <span>Davam edir</span>
                      ) : (
                        <div className="analytc_table_date_div">
                          <span>
                            {formatDate(elem?.exitDate?.substring(0, 10))}
                          </span>
                          <span>{elem?.exitDate?.substring(11, 16)}</span>
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="analytc_table_doc_div">
                        <span>{convertDurationToString(elem?.duration)}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="analytc_table_rate_div"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="doticon"
                          style={{
                            display: "inline-block",
                            width: "8px", 
                            height: "8px",
                            borderRadius: "50%", 
                            backgroundColor: isOnline ? "green" : "red", 
                            marginRight: "5px", 
                          }}
                        ></span>
                        {isOnline ? (
                          <span style={{ color: "green" }}>Online</span>
                        ) : (
                          <span style={{ color: "red" }}>Offline</span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </AnalytcsTable>
      </div>
    );
  }
);
export default AnalytcsActive;
