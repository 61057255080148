import React, { useContext, useEffect, useRef, useState } from "react";
import { Treebeard, decorators } from "react-treebeard";
import { FaFileVideo } from "react-icons/fa";
import { MdFolder, MdFolderOpen } from "react-icons/md";
import axiosClient from "../api/axiosClient";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import cover from "../images/siesco video cover 2-01.png";
import StarRatings from "react-star-ratings";
import Player from "@vimeo/player";
import axios from "axios";
import Comments from "../components/Comments/Comments";
import MainComment from "../components/Comments/MainComment";

const mapData = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    videos: item.videos,
    children: [
      ...mapData(item.children),
      ...item.videos.map((video) => ({
        id: video.id,
        name: video.title,
        videoFile: video.videoFile,
        type: "video",
        rating: video.rating,
      })),
    ],
  }));
};

function Videos() {
  const [cursor, setCursor] = useState(null);
  const [data, setData] = useState([]);
  const [curVideo, setCurVideo] = useState(null);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const videoData = location.state?.videoData;
  const [rating, setRating] = useState(0);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const videoRef = useRef(null);
  const [toggledFunc, setToggledFunc] = useState(true);
  const controller = useRef();
  const intervalRef = useRef(null);
  const [entityId, setEntityId] = useState(0);
  const test = useRef(0);
  const [loading, setLoading] = useState(false);
  const [videoDatasArr, setVideoDatasArr] = useState([]);
const videoContainerRef=useRef(null)
  useEffect(() => {
    if (videoData) {
      setCurVideo(videoData.videoFile);
      setRating(videoData.rating || 0);
      setSelectedVideoId(videoData.id);
      if (videoData?.id) {
        postViewCount(videoData.id);
      }
    } else {
      setCurVideo(null);
    }
  }, [videoData]);

  const fetchProfileDataStats = async (videoId, rateCount) => {
    try {
      setLoading(true);

      const response = await axiosClient.post(
        "/UserVIdeoAndLessonRating/CreateVideoRating",
        {
          VideoId: videoId,
          RateCount: rateCount,
        },
        {
          notification: false,
        }
      );
      setRating(response.data || 0); 
    } catch (error) {
      console.error("Error sending rating:", error);
    } finally {
      setLoading(false);
    }
  };

  const postVideoPlayStop = async (isPlay, ids) => {
    const apiUrl = "/UserVideoAndLesson/Create";
    const token = localStorage.getItem("authState");
    const a = JSON.parse(token);

    if (controller.current) {
      controller.current.abort();
    }

    controller.current = new AbortController();
    const signal = controller.current.signal;

    try {
      const response = await axiosClient.post(
        apiUrl,
        {
          videoId: selectedVideoId,
          isplay: isPlay,
          lessonId: null,
          entityId: ids,
        },
        {
          signal,
          notification: false,
        }
      );

      test.current = response.data;
    } catch (error) {
      console.error("Error posting play/stop status:", error);
    }
  };

  const handlePlay = () => {
    postVideoPlayStop(true, test.current);
    intervalRef.current = setInterval(() => {
      postVideoPlayStop(true, test.current);
    }, 30000);
  };

  const handlePause = () => {
    postVideoPlayStop(false, test.current);
    clearInterval(intervalRef.current);
  };

  const postViewCount = async (id) => {
    try {
      await axiosClient.post(
        `/Videos/IncrementCount`,
        {},
        {
          params: { id },
          notification: false,
        }
      );
    } catch (error) {
      console.error("Error posting play/stop status:", error);
    }
  };

  const customDecorators = {
    ...decorators,
    Header: ({ style, node }) => {
      const iconType = node.children ? (
        node.toggled ? (
          <MdFolderOpen />
        ) : (
          <MdFolder />
        )
      ) : node.type === "video" ? (
        <FaFileVideo />
      ) : null;

      return (
        <div style={style.base}>
          <div style={style.title} className="videoNames">
            {iconType} {node.name}
          </div>
        </div>
      );
    },
  };

  const onToggle = (node, toggled) => {
    setToggledFunc(!toggledFunc);
    if (node.type === "video") {
      setCurVideo(node.videoFile);
      setRating(node.rating);
      setSelectedVideoId(node.id);
      postViewCount(node.id);
      setVideoDatasArr(node);
      test.current = 0;
    }
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setData({ ...data });
  };

  useEffect(() => {
    axiosClient
      .get("/Groups/GetAllWithPermission")
      .then((res) => {
        setData({
          name: "Bölmələr",
          toggled: true,
          children: mapData(res.data),
        });
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const ratingChanged = (newRating) => {
    setLoading(true);
    setRating(newRating);
    if (selectedVideoId) {
      fetchProfileDataStats(selectedVideoId, newRating);
    }
  };

  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return (...args) => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //     timeoutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  useEffect(() => {
    if (curVideo) {
      const player = new Player(videoRef?.current, {
        id: curVideo,
        width: 640,
      });

      player.on("play", handlePlay);
      player.on("pause", handlePause);

      return () => {
        player.destroy();
        clearInterval(intervalRef.current);
      };
    }
  }, [curVideo]);

  return (
    <div className="video-container">
      <div className="card card-video" ref={videoContainerRef} >
        {curVideo ? (
          <>
            <div ref={videoRef} className="vimeo-video" />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <StarRatings
                rating={rating || 0}
                starRatedColor="gold"
                changeRating={ratingChanged}
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="5px"
                starHoverColor="gold"
              />

              {loading ? (
                <div className="loaderSearch2"></div>
              ) : (
                <h3>{rating}</h3>
              )}
            </div>
            <MainComment videoId={selectedVideoId} commentsContainerRef={videoContainerRef} />
          </>
        ) : (
          <img src={cover} alt="Siesco Education" />
        )}
      </div>
      <div className="card card-lesson">
        <div className="card-header">
          <h3 className="card-title">Videolar</h3>
          {(auth.userStatus === "Admin" || auth.userStatus === "SuperAdmin") && (
            <button
              className="card-button"
              onClick={() => navigate("/editvideos")}
            >
              Ayarlar
            </button>
          )}
        </div>
        <ul className="lesson-category">
          <Treebeard
            data={data}
            onToggle={onToggle}
            decorators={customDecorators}
          />
        </ul>
      </div>
    </div>
  );
}

export default Videos;
