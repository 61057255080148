import React, { useState, useEffect } from "react";
import "./Notifications.css";
import logo from "../../images/SiescoLogo.svg";
import InfoIcon from '../../assets/icons/İnfoİcon'
import {
  ApplicationSendIcon,
  CallIcons,
  LeftArrowicon,
  SiescoLogo,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import errorImg from "../../images/circle-exclamation.png";
import ParticleBackground from "../../components/Particle/ParticleBackground";
import Logo from "../../images/LogoNotification.png";
const NotificationForm = () => {
  const [formData, setFormData] = useState({
    isCompany: false,
    name: "",
    surname: "",
    email: "",
    phone: "",
    companyName: "",
    apllicationText: "",
  });

  const [userType, setUserType] = useState("0");
  const [applicationTextError, setApplicationTextError] = useState("");
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "name") setNameError("");
    if (name === "surname") setSurnameError("");
    if (name === "email") setEmailError("");
    if (name === "phone") setPhoneError("");
    if (name === "companyName") setCompanyNameError("");
    if (name === "apllicationText") setApplicationTextError("");

    setHasError(false);
  };

  const handleUserTypeChange = (e) => {
    const value = e.target.value;
    setUserType(value);
    setFormData({ ...formData, isCompany: value === "1" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApplicationTextError("");
    setHasError(false);
    let errorsExist = false;

    try {
      await axiosClient.post("/Applications/Create", formData);
      handleClick();
    } catch (err) {
      const errorData = err.response?.data.errors || {};
      setNameError(errorData?.Name || "");
      setSurnameError(errorData?.Surname || "");
      setEmailError(errorData?.Email || "");
      setPhoneError(errorData?.Phone || "");
      setCompanyNameError(errorData?.companyName || "");
      setApplicationTextError(errorData?.ApllicationText || "");
      errorsExist = !!(
        errorData?.Name ||
        errorData?.Surname ||
        errorData?.Email ||
        errorData?.Phone ||
        errorData?.companyName ||
        errorData?.ApllicationText
      );
      setHasError(errorsExist);

      console.error("Error submitting form:", err);
    }
  };

  const goBack = () => {
    navigate("/");
  };

  const handleClick = () => {
    setIsVisible(true);
  };

  const handleReturnHome = () => {
    navigate("/login");
  };
  return (
    <div className="notification-container">
      <div className="particleBg">
        <div className="ParticleNotHeader">
          <img src={Logo} alt="" />
          <div className="NotificationsHeaderBtn">
            <button> <InfoIcon /><p>Şərtlər və qaydalar</p></button>
            <button>
              <CallIcons /> <p>Əlaqə</p>
            </button>
          </div>
        </div>
        <ParticleBackground />
      </div>
      {isVisible && (
        <div className="success-messageNotMain">
          <div className="success-messageNot">
            <div className="icon">
              <ApplicationSendIcon />
            </div>
            <h2>Müraciətiniz göndərildi</h2>
            <p>
              Müraciətiniz uğurla göndərildi. Müraciətinizə baxıldıqdan sonra ən
              qısa zamanda sizinlə əlaqə saxlanılacaq.
            </p>
            <div className="NotificationSucces">
              <button className="return-button" onClick={handleReturnHome}>
                Əsas səhifəyə qayıt
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`notification-container-main ${
          hasError ? "notification-container-mainError" : ""
        }`}
      >
        <div className="notification-headText">
          <h4>Kompleks Həllər Mərkəzi</h4>
          <h2>ONLAYN MÜRACİƏT FORMU</h2>
        </div>
        <div className="notification-form-container">
          <div className="notification-form-header">
          <h3>Müraciət üçün məlumatları daxil edin</h3>
          <p>Zəhmət olmasa, şəxsi məlumatlarınızı daxil edin. Əgər sualınız yaranarsa, bizimlə əlaqə yarada bilərsiniz.</p>
          <hr /></div>
          <form onSubmit={handleSubmit} className="notification-form-content">
            <div className="notification-form-group">
              <label className="radio-container">
                <input
                  type="radio"
                  name="isCompany"
                  value="0"
                  checked={userType === "0"}
                  onChange={handleUserTypeChange}
                />
                <span className="radio-custom"></span>
                <span className="radio-label">Fərdi</span>
              </label>
              <label className="radio-container">
                <input
                  type="radio"
                  name="isCompany"
                  value="1"
                  checked={userType === "1"}
                  onChange={handleUserTypeChange}
                />
                <span className="radio-custom"></span>
                <span className="radio-label">Şirkət</span>
              </label>
            </div>

            <div
              className={`notification-input-row ${
                nameError || surnameError ? "error-notRow" : ""
              }`}
            >
              <div className="notification-input-group">
                <label>Ad</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Ad"
                  className={nameError ? "errorMessageProfile" : ""}
                  required
                />
                {nameError && (
                  <div className="error-messageNot">
                    <img src={errorImg} alt="Error" />
                    <p>{nameError}</p>
                  </div>
                )}
              </div>
              <div className="notification-input-group">
                <label>Soyad</label>
                <input
                  type="text"
                  name="surname"
                  value={formData.surname}
                  onChange={handleInputChange}
                  placeholder="Soyad"
                  className={surnameError ? "errorMessageProfile" : ""}
                  required
                />
                {surnameError && (
                  <div className="error-messageNot">
                    <img src={errorImg} alt="Error" />
                    <p>{surnameError}</p>
                  </div>
                )}
              </div>
            </div>

            <div
              className={`notification-input-row ${
                emailError || phoneError ? "error-notRow" : ""
              }`}
            >
              <div className="notification-input-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className={emailError ? "errorMessageProfile" : ""}
                  required
                />
                {emailError && (
                  <div className="error-messageNot">
                    <img src={errorImg} alt="Error" />
                    <p>{emailError}</p>
                  </div>
                )}
              </div>
              <div className="notification-input-group">
                <label>Telefon nömrəsi</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Telefon nömrəsi"
                  className={phoneError ? "errorMessageProfile" : ""}
                  required
                />
                {phoneError && (
                  <div className="error-messageNot">
                    <img src={errorImg} alt="Error" />
                    <p>{phoneError}</p>
                  </div>
                )}
              </div>
            </div>

            {userType === "1" && (
              <div className="notification-input-group">
                <label>Şirkətin adı</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Şirkətin adı"
                  className={companyNameError ? "errorMessageProfile" : ""}
                  required
                />
                {companyNameError && (
                  <div className="error-messageNot">
                    <img src={errorImg} alt="Error" />
                    <p>{companyNameError}</p>
                  </div>
                )}
              </div>
            )}

            <div className="textarea-input-group">
              <label htmlFor="applicationText">Müraciətin mətni</label>
              <textarea
                id="applicationText"
                name="apllicationText"
                value={formData.apllicationText}
                onChange={handleInputChange}
                placeholder="Müraciətin mətni"
                className={applicationTextError ? "errorMessageProfile" : ""}
                required
                maxLength={1500}
              />
              {applicationTextError && (
                <div className="error-messageNot">
                  <img src={errorImg} alt="Error" />
                  <p>{applicationTextError}</p>
                </div>
              )}
            </div>
            <div className="notificationFormBtn">
            <button  className="notification-submit-button" onClick={()=>navigate('../login')}>
            İmtina et
            </button>
            <button type="submit" className="notification-submit-button">
              Müraciəti göndər
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotificationForm;
