import * as React from "react";
import PropTypes from "prop-types";

const DefaulProfileIcon = ({
  size = 30,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.7 14.014C15.575 14.0015 15.425 14.0015 15.2875 14.014C12.3125 13.914 9.94995 11.4765 9.94995 8.47651C9.94995 5.41401 12.425 2.92651 15.5 2.92651C18.5625 2.92651 21.05 5.41401 21.05 8.47651C21.0375 11.4765 18.675 13.914 15.7 14.014Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.45002 18.6265C6.42502 20.6515 6.42502 23.9515 9.45002 25.964C12.8875 28.264 18.525 28.264 21.9625 25.964C24.9875 23.939 24.9875 20.639 21.9625 18.6265C18.5375 16.339 12.9 16.339 9.45002 18.6265Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

DefaulProfileIcon.displayName = "Check";

DefaulProfileIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default DefaulProfileIcon;
