import React, { useEffect, useRef, useState } from "react";
import NotificationCard from "./NotificationCard";
import "./Notifications.css";
import DateTime from "../../utils/DateTime";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../api/axiosClient";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Notifications = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveDateTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("dateTab")) || 0;
  };

  const [activeDateTab, setActiveDateTab] = useState(getActiveDateTabFromURL());
  const [tabNumber, setTabNumber] = useState(activeDateTab + 1);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); 

  const handleDateTabClick = (index) => {
    setActiveDateTab(index);
    const params = new URLSearchParams(location.search);
    params.set("dateTab", index);
    navigate({ search: params.toString() });
    setTabNumber(index + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNotifications([]);
    setPage(0);
    setHasMore(true); 
  };

  useEffect(() => {
    setNotifications([]);
    setPage(0);
  }, [activeDateTab]);

  const tabRefs = useRef([]);
  const underlineRef = useRef(null);

  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("tab")) || 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTabFromURL());

  useEffect(() => {
    setActiveTab(getActiveTabFromURL());
  }, [location.search]);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "none";
    }
  }, []);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "transform 0.3s ease, width 0.3s ease";
    }
  }, [activeTab]);

  const fetchReplies = async () => {
    if (loading || !hasMore) return; 
    setLoading(true);
    try {
      const response = await axiosClient.get(
        `/Applications/GetAll?tab=${tabNumber}&skip=${page}&take=10`
      );

      if (response.data.length < 10) {
        setHasMore(false); 
      }

      setNotifications((prev) => [...prev, ...response.data]);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching replies:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReplies();
  }, [tabNumber]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      if(page!==0){
        fetchReplies();
      }
    }
  };

  return (
    <>
      <div className="analytic_date_tab">
        <ul className="dateTabMenu3">
          {["Hamısı", "Oxunmamış"].map((dateTab, index) => (
            <li
              key={index}
              className={`dateTab3 ${
                activeDateTab === index ? "activeDateTab" : ""
              }`}
              onClick={() => handleDateTabClick(index)}
            >
              {dateTab}
            </li>
          ))}
        </ul>
      </div>

      <div className="notificationMain" onScroll={handleScroll}>
        {notifications.length > 0
          ? notifications.map((elem, index) => (
              <NotificationCard
                key={elem.id}
                title={`${elem.name} ${elem.surname}`}
                time={DateTime(elem.createDate)}
                message={elem.apllicationText}
                id={elem.id}
                loading={loading}
                seen={elem.isSeen}
              />
            ))
          : !loading && (
              <div className="withOutNotification">Müraciət yoxdur</div>
            )}
        {loading && hasMore && (
          <div className="skeletonContainer">
            {Array.from({ length: 7 }, (_, index) => (
              <div className="NotificationCard" key={index}>
                <div className="iconNotification">
                  <Skeleton circle={true} height={40} width={40} />
                </div>
                <div className="contentNotification">
                  <h3 className="notificatinUserName">
                    <Skeleton width={`120px`} />
                  </h3>
                  <div className="moreMessage">
                    <Skeleton width={`120px`} />
                  </div>
                </div>
                <div className="notificationTime">
                  <Skeleton width={`30%`} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
