import React, { useContext, useState } from "react";
// import "../Login.css";
import logo from "../images/logo white-01 1.png";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import errorImg from "../images/circle-exclamation.png";
import {
  BlockErrorIcon,
  CheckIcon,
  LockIcon,
  MailIcon,
  OpenEyeIcon,
} from "../assets/icons";
import CloseEyeIcon from "../assets/icons/CloseEyeIcon";
import CustomModal from "../components/Modal/CustomModal";
import { notification } from "antd";
function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [blockedModal, setBlockedModal] = useState(false);
  const isModalOpen = () => {
    setBlockedModal(true);
  };

  const closeModal = () => {
    setBlockedModal(false);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await login(
        e.target.email.value,
        e.target.password.value,
      );
        navigate("/");
    } catch (error) {
      const errorData = error.response?.data || {};
      if(errorData.statusCode!==403){

      setEmailError(
        errorData.message ||
        (errorData.errors?.Email?.length > 0 ? errorData.errors.Email[0] : "")
      );
      setPasswordError(
        (errorData.errors?.Password?.length > 0
          ? errorData.errors.Password[0]
          : "") ||
        errorData.password ||
        ""
      )}else{
        setBlockedModal(true)
      }
    }
  };
  
  const handleEmailChange = () => {
    setEmailError("");
  };

  const handlePasswordChange = () => {
    setPasswordError("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <div className="root">
      {blockedModal && (
        <CustomModal isOpen={isModalOpen} onClose={closeModal} mid={true}>
          <div className="loginBlocked">
            <div className="loginBlockIcon">
              <BlockErrorIcon />
            </div>
            <h3>Girişiniz məhdudlaşdırılıb</h3>
          </div>
        </CustomModal>
      )}
      <div className="login-left">
        <img src={logo} alt="Logo" />
        <h3>
          Axtarılan mütəxəssisi bilik, bacarıq və səriştələr
          formalaşdırır. Onlara catmaq üçün isə illərlə işləmək gərəkdir, lakin
          biz sizə keyfiyyətli dərsliklərlə zamanınızı qısaldıb məqsədlərinizə
          daha tez çatmağınızda yardımçı oluruq.
        </h3>
      </div>
      <div className="login-box">
        <h2>Xoş gəlmisiniz!</h2>
        <form onSubmit={handleLogin}>
          <div className="txtb">
            <label>Email</label>
            <div className={`inputMain ${emailError ? "errorMessage" : ""}`}>
              <MailIcon />
              <input
                type="text"
                onChange={handleEmailChange}
                name="email"
                placeholder="Email"
                required
              />
            </div>
            {emailError && (
              <div className="error-message">
                <img src={errorImg} alt="Logo" />
                <p>{emailError}</p>
              </div>
            )}
          </div>
          <div className="txtb">
            <label>Şifrə</label>
            <div className={`inputMain ${passwordError ? "errorMessage" : ""}`}>
              <LockIcon />
              <input
                className="loginPasword"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                name="password"
                placeholder="Şifrə"
                required
              />
              <div
                className="togglePassword"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <CloseEyeIcon className="inputIcon" />
                ) : (
                  <OpenEyeIcon className="inputIcon" />
                )}
              </div>
            </div>
            {passwordError && (
              <div className="error-message">
                <img src={errorImg} alt="Logo" />
                <p>{passwordError}</p>
              </div>
            )}
          </div>
          <Link className="reset-pass-btn" to="/resetPassword">
            Şifrəni unutmuşam
          </Link>
          <button type="submit">Daxil ol</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
