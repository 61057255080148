import React from "react";

const VideoDateTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const today = now.toISOString().split("T")[0];
  const yesterday = new Date(now.setDate(now.getDate() - 1))
    .toISOString()
    .split("T")[0];
  const formattedDate = date.toISOString().split("T")[0];
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const diffMinutes = Math.floor((now - date) / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffMinutes / (60 * 24));

  if (diffMinutes < 1) {
    return "İndi";
  } else if (diffMinutes < 60) {
    return `${diffMinutes} dəqiqə əvvəl`;
  } else if (diffHours < 24) {
    return `${diffHours} saat əvvəl`;
  } else if (formattedDate === today) {
    return `Bugün`;
  } else if (formattedDate === yesterday) {
    return `Dünən`;
  } else if (diffDays < 7) {
    const dayNames = [
      "Bazar",
      "Bazar ertəsi",
      "Çərşənbə axşamı",
      "Çərşənbə",
      "Cümə axşamı",
      "Cümə",
      "Şənbə",
    ];
    const dayName = dayNames[date.getDay()];
    return `${dayName}`;
  } else {
    return `${day}.${month}.${year}`;
  }
};

export default VideoDateTime;
