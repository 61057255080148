
import * as React from "react";
import PropTypes from "prop-types";

const İnfoİcon = ({
    size=24,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 10.977a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0v-6Z"></path>
    <path d="M12 6.055a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"></path>
    <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Z" clip-rule="evenodd"></path>
  </svg>
);

İnfoİcon.displayName = "Check";

İnfoİcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default İnfoİcon;
