import React, { useContext, useEffect, useState } from "react";
import "./Profile.css";
import { CameraIcon } from "../../assets/icons";
import axiosClient from "../../api/axiosClient";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import errorImg from "../../images/circle-exclamation.png";

const ProfileDetails = () => {
  const { auth, updateUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [fatherNameError, setFatherNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [professionError, setProfessionError] = useState("");
  const [photoError, setPhotoError] = useState("");

  const [formData, setFormData] = useState({
    Name: "",
    Surname: "",
    FatherName: "",
    BirthDate: "",
    PhoneNumber: "",
    Profession: "",
    Education: "",
    Gender: "",
    PhotoFile: null,
    PhotoUrl: "",
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      if (auth.accessToken) {
        try {
          const response = await axiosClient.get("/user/profile");
          if (response.status === 200) {
            const data = response.data;
            setFormData({
              Name: data?.name || "",
              Surname: data?.surname || "",
              FatherName: data?.fatherName || "",
              BirthDate: data?.birthDate
                ? formatToDateInput(data?.birthDate)
                : "",
              PhoneNumber: data?.phoneNumber || "",
              Profession: data?.profession || "",
              Education:
                data?.education !== null ? data?.education.toString() : "0",
              Gender: data?.gender !== undefined ? data?.gender : "0",
              PhotoFile: null,
              PhotoUrl: data?.photo || "",
            });
          } else {
            console.error(
              "Error fetching profile data, status:",
              response.status
            );
          }
        } catch (error) {
          console.error("Error fetching profile data", error);
        }
      } else {
        console.warn("Access token is missing.");
      }
    };

    fetchProfileData();
  }, [auth.accessToken]);

  const formatToDateInput = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      const photoUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        PhotoFile: file,
        PhotoUrl: photoUrl,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      switch (name) {
        case "Name":
          setNameError("");
          break;
        case "Surname":
          setLastNameError("");
          break;
        case "FatherName":
          setFatherNameError("");
          break;
        case "PhoneNumber":
          setNumberError("");
          break;
        case "Profession":
          setProfessionError("");
          break;
        case "PhotoFile":
          setPhotoError("");
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = new FormData();
      for (const key in formData) {
        if (formData[key]) {
          form.append(key, formData[key]);
        }
      }
      const response = await axiosClient.put("/User/UpdateProfile", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        updateUser({
          userFullName: `${formData.Name} ${formData.Surname}`,
          photo: formData.PhotoUrl,
        });
        navigate("/");
      } else {
      }
    } catch (error) {
      console.log(error);

      const errorData = error.response.data.errors;
      setNameError(
        Array.isArray(errorData?.Name) && errorData?.Name?.length > 0
          ? errorData.Name[0]
          : ""
      );
      setLastNameError(
        Array.isArray(errorData?.Surname) && errorData?.Surname?.length > 0
          ? errorData.Surname[0]
          : ""
      );
      setFatherNameError(
        Array.isArray(errorData?.FatherName) &&
          errorData?.FatherName?.length > 0
          ? errorData?.FatherName[0]
          : ""
      );
      setNumberError(
        Array.isArray(errorData?.PhoneNumber) &&
          errorData?.PhoneNumber?.length > 0
          ? errorData?.PhoneNumber[0]
          : ""
      );
      setProfessionError(
        Array.isArray(errorData?.Profession) &&
          errorData?.Profession?.length > 0
          ? errorData.Profession[0]
          : ""
      );
      setPhotoError(
        Array.isArray(errorData?.PhotoFile) && errorData?.PhotoFile?.length > 0
          ? errorData?.PhotoFile[0]
          : "error"
      );
      console.error("Error updating profile", error);
    }
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <div className="profileInfo">
      <h2>Şəxsi məlumatlar</h2>
      <div className="form-container">
        <div>
          <div className="avatar-section">
            <img src={formData.PhotoUrl} alt="Avatar" className="avatar" />
            <button className="edit-avatar">
              <label htmlFor="uploadPhoto" aria-label="edit">
                <CameraIcon />
              </label>
              <input
                type="file"
                accept="image/*"
                id="uploadPhoto"
                name="PhotoFile"
                onChange={handleChange}
              />
            </button>
          </div>
        </div>
        {photoError && (
          <div className="error-messageProfilePhoto">
            <img src={errorImg} alt="Error" />
            <p>{photoError}</p>
          </div>
        )}
        <form
          className={`personal-info ${
            auth.userStatus !== "User" ? "personal-info-admin" : ""
          }`}
          onSubmit={handleSubmit}
        >
          <div className="input-group">
            <label>Ad</label>
            <input
              className={nameError ? "errorMessageProfile" : ""}
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
            />
            {nameError && (
              <div className="error-messageProfile">
                <img src={errorImg} alt="Error" />
                <p>{nameError}</p>
              </div>
            )}
          </div>
          <div className="input-group">
            <label>Soyad</label>
            <input
              className={lastNameError ? "errorMessageProfile" : ""}
              type="text"
              name="Surname"
              value={formData.Surname}
              onChange={handleChange}
            />
            {lastNameError && (
              <div className="error-messageProfile">
                <img src={errorImg} alt="Error" />
                <p>{lastNameError}</p>
              </div>
            )}
          </div>
          <div className="input-group">
            <label>Ata adı</label>
            <input
              className={fatherNameError ? "errorMessageProfile" : ""}
              type="text"
              name="FatherName"
              value={formData.FatherName}
              onChange={handleChange}
            />
            {fatherNameError && (
              <div className="error-messageProfile">
                <img src={errorImg} alt="Error" />
                <p>{fatherNameError}</p>
              </div>
            )}
          </div>
          <div className="input-group">
            <label>Doğum tarixi</label>
            <input
              type="date"
              name="BirthDate"
              value={formData.BirthDate}
              onChange={handleChange}
              max={today}
            />
          </div>
          <div className="input-group">
            <label>Əlaqə nömrəsi</label>
            <input
              type="tel"
              name="PhoneNumber"
              value={formData.PhoneNumber}
              onChange={handleChange}
              className={numberError ? "errorMessageProfile" : ""}
            />
            {numberError && (
              <div className="error-messageProfile">
                <img src={errorImg} alt="Error" />
                <p>{numberError}</p>
              </div>
            )}
          </div>
          <div className="input-group">
            <label>İxtisas</label>
            <input
              type="text"
              name="Profession"
              value={formData.Profession}
              onChange={handleChange}
              className={professionError ? "errorMessageProfile" : ""}
            />
            {professionError && (
              <div className="error-messageProfile">
                <img src={errorImg} alt="Error" />
                <p>{professionError}</p>
              </div>
            )}
          </div>
          <div className="input-group">
            <label>Təhsil</label>
            <select
              name="Education"
              value={formData.Education}
              onChange={handleChange}
            >
              <option value="" disabled></option>
              <option value="0">Ali</option>
              <option value="1">Orta</option>
              <option value="2">Natamam Ali</option>
              <option value="3">Tam Ali</option>
            </select>
          </div>
          <div className="input-group genderRow">
            <label>Cinsiyyət</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="Gender"
                  value="0"
                  checked={formData.Gender == 0}
                  onChange={handleChange}
                />
                Kişi
              </label>
              <label>
                <input
                  type="radio"
                  name="Gender"
                  value="1"
                  checked={formData.Gender == 1}
                  onChange={handleChange}
                />
                Qadın
              </label>
            </div>
          </div>
          <button
            type="submit"
            className={`save-button ${
              auth.userStatus !== "User" ? "save-button-admin" : ""
            }`}
          >
            Yadda saxla
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProfileDetails;
