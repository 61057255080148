
import * as React from "react";
import PropTypes from "prop-types";

const CompanyUsersIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.125 7.25C4.18359 7.25 2.625 5.69141 2.625 3.75C2.625 1.83594 4.18359 0.25 6.125 0.25C8.03906 0.25 9.625 1.83594 9.625 3.75C9.625 5.69141 8.03906 7.25 6.125 7.25ZM7.49219 8.5625C10.1172 8.5625 12.25 10.6953 12.25 13.3203C12.25 13.8398 11.8125 14.25 11.293 14.25H0.929688C0.410156 14.25 0 13.8398 0 13.3203C0 10.6953 2.10547 8.5625 4.73047 8.5625H7.49219ZM13.0977 9C15.5312 9 17.5 10.9688 17.5 13.375C17.5 13.8672 17.0898 14.25 16.625 14.25H12.8516C13.0156 13.9766 13.125 13.6758 13.125 13.3203C13.125 11.5977 12.332 10.0391 11.0742 9H13.0977ZM11.8125 7.25C10.9375 7.25 10.1719 6.92188 9.625 6.34766C10.1719 5.63672 10.5 4.73438 10.5 3.75C10.5 3.03906 10.2812 2.35547 9.98047 1.75391C10.4727 1.37109 11.1016 1.125 11.8125 1.125C13.4805 1.125 14.875 2.51953 14.875 4.1875C14.875 5.88281 13.4805 7.25 11.8125 7.25Z" fill="#919DA8"/>
</svg>
);

CompanyUsersIcon.displayName = "Edit";

CompanyUsersIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CompanyUsersIcon;