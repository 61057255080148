import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VideoPermissions from "./VideoPermissions";
import DocsPermission from "./DocsPermission";

const DepartmentsPermissions = ({ setSelectedVideos, selectedVideos, setSelectedLessons, selectedLessons, data }) => {
  const tabRefs = useRef([]);
  const underlineRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [videoData, setVideoData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  useEffect(() => {
    if (data) {
      setVideoData(data.videos || data.videoGroupsDto || []);
      setLessonData(data.lessons || data.lessonGroupsDto || []);
    }
  }, [data]);
  

  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("tab")) || 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTabFromURL());

  const handleTabClick = (index) => {
    setActiveTab(index);
    const params = new URLSearchParams(location.search);
    params.set("tab", index);
    navigate({ search: params.toString() });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setActiveTab(getActiveTabFromURL());
  }, [location.search]);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "none";
    }
  }, []);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && underlineRef.current) {
      const { offsetWidth, offsetLeft } = activeTabElement;
      const underline = underlineRef.current;
      underline.style.width = `${offsetWidth}px`;
      underline.style.transform = `translateX(${offsetLeft}px)`;
      underline.style.transition = "transform 0.3s ease, width 0.3s ease";
    }
  }, [activeTab]);

  return (
    <>
      <div className="tabMenu2">
        <div className="tabUnderlineContainer2">
          {["Videolar", "Dərsliklər"].map((tab, index) => (
            <button
              key={index}
              ref={(el) => (tabRefs.current[index] = el)}
              className={`tabMenuButton2 ${activeTab === index ? "activeTab2" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </button>
          ))}
          <div ref={underlineRef} className="tabUnderline2" />
        </div>
      </div>
      <div className="contentProfile">
        {activeTab === 0 && (
          <VideoPermissions
            videoData={videoData}
            setVideoData={setVideoData}
            setSelectedVideos={setSelectedVideos}
            selectedVideos={selectedVideos}
          />
        )}
        {activeTab === 1 && (
          <DocsPermission
            lessonData={lessonData}
            setLessonData={setLessonData}
            setSelectedLessons={setSelectedLessons}
            selectedLessons={selectedLessons}
          />
        )}
      </div>
    </>
  );
};

export default DepartmentsPermissions;
