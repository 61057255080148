import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import './Departments.css';

const VideoList = ({ setSelectedVideos, selectedVideos, videoData, setVideoData }) => {
  useEffect(() => {
    if (!videoData) return;

    const allowedVideoIds = videoData
      .filter(video => video.isAllowed)
      .map(video => video.videoGroupId);

    setSelectedVideos(prevSelected => {
      const newSelection = [...new Set([...prevSelected, ...allowedVideoIds])];
      if (JSON.stringify(prevSelected) !== JSON.stringify(newSelection)) {
        return newSelection;
      }
      return prevSelected;
    });
  }, [videoData, setSelectedVideos]);

  const handleCheckboxChange = (videoGroupId) => {
    setSelectedVideos((prevSelected) =>
      prevSelected.includes(videoGroupId)
        ? prevSelected.filter((id) => id !== videoGroupId)
        : [...prevSelected, videoGroupId]
    );

    setVideoData((prevVideoData) =>
      prevVideoData.map((video) =>
        video.videoGroupId === videoGroupId
          ? { ...video, isAllowed: !selectedVideos.includes(videoGroupId) }
          : video
      )
    );
  };

  const isLoading = !videoData || videoData.length === 0;

  return (
    <div className="unique-video-list-container">
      <div className="unique-video-column">
        {isLoading
          ? Array.from({ length: videoData.length }).map((_, index) => (
              <div className="unique-video-item" key={index}>
                <Skeleton width={150} height={20} style={{ marginLeft: 10 }} />
              </div>
            ))
          : videoData.map((video) => (
              <div className="unique-video-item" key={video.videoGroupId}>
                <input
                  type="checkbox"
                  className="unique-checkbox"
                  checked={selectedVideos.includes(video.videoGroupId) || video.isAllowed}
                  onChange={() => handleCheckboxChange(video.videoGroupId)}
                />
                <label className="unique-video-label">
                  {video.videoGroupName}
                </label>
              </div>
            ))}
      </div>
    </div>
  );
};

export default VideoList;
