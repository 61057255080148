import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../api/axiosClient";
import { useNavigate, useParams } from "react-router-dom";
// import "../../Login.css";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/ReactContexify.css";
import { toast } from "react-toastify";
import errorImg from "../../images/circle-exclamation.png";

const MENU_ID = "blahblah";
function EditDocuments() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [folderModal, setFolderModal] = useState(false);
  const [lessonModal, setlessonModal] = useState(false);
  const [contextType, setContextType] = useState("folder");
  const [editModal, setEditModal] = useState(false);
  const [editedItem, setEditedItem] = useState();
  const [nameError, setNameError] = useState("");
  const [fileError, setFileError] = useState("");
  const [textError, setTextError] = useState("");
  const formRef = useRef(null);
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  function handleContextMenu(event, type, itemid) {
    show({
      event,
      props: {
        type,
        itemid,
      },
    });
    setContextType(type);
  }
  const handleItemClick = ({ id, event, props }) => {
    switch (id) {
      case "open":
        setData(null);
        navigate("/editdocuments/" + props.itemid);
        break;
      case "remove":
        removeItem(props.type, props.itemid);
        break;
      case "edit":
        editItem(props.type, props.itemid);
        break;
      case "rules":
        navigate("/documentrules/" + props.itemid);
        break;
    }
  };
  useEffect(() => {
    axiosClient
      .get("/GroupLessons/get/" + (id !== undefined ? +id : ""))
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [id]);
  const addFolder = (e) => {
    e.preventDefault();
    axiosClient
      .post("/GroupLessons/create", {
        name: e.target.name.value,
        parentId: id,
      })
      .then((res) => {
        let upd = {
          ...data,
          children: data.children.concat({
            id: res.data,
            name: e.target.name.value,
          }),
        };
        setData(upd);
        setFolderModal(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err?.response?.data || {};
        setNameError(
          Array.isArray(errorData?.errors?.Name) &&
            errorData?.errors?.Name.length > 0
            ? errorData.errors.Name[0]
            : ""
        );
      });
  };
  const addlesson = (e) => {
    e.preventDefault();
    axiosClient
      .post(
        "/lessons/create",
        {
          title: e.target.title.value,
          lessonFile: e.target.lessonFile.files[0],
          groupLessonId: id,
          description: e.target.description.value,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        let upd = {
          ...data,
          lessons: data.lessons.concat({
            id: res.data,
            title: e.target.title.value,
            id: res.data.id,
            description: e.target.description.value,
          }),
        };
        setData(upd);
        setlessonModal(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err.response?.data || {};
        setNameError(
          Array.isArray(errorData?.errors?.Title) &&
            errorData?.errors?.Title.length > 0
            ? errorData.errors.Title[0]
            : ""
        );
        setFileError(
          Array.isArray(errorData?.errors?.LessonFile) &&
            errorData?.errors?.LessonFile.length > 0
            ? errorData.errors.LessonFile[0]
            : ""
        );
        // setTextError("Error text");
      });
  };
  const removeItem = (type, id) => {
    switch (type) {
      case "folder":
        axiosClient
          .delete("/GroupLessons/delete/" + id)
          .then((res) => {
            let upd = {
              ...data,
              children: data.children.filter((x) => x.id != id),
            };
            setData(upd);
          })
          .catch((err) => {
            toast.dismiss();
            toast.error(
              "Qrupu silmək üçün içindəki bütün elementlər silinməlidir."
            );
          });
        break;
      case "lesson":
        axiosClient
          .delete("/lessons/delete/" + id)
          .then((res) => {
            let upd = {
              ...data,
              lessons: data.lessons.filter((x) => x.id != id),
            };
            setData(upd);
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });
        break;
    }
  };
  const editItem = (type, id) => {
    setEditModal(true);
    setEditedItem({
      id: id,
      type: type,
      name:
        type === "lesson"
          ? data.lessons.find((x) => x.id === id).title
          : data.children.find((x) => x.id === id).name,
      description:
        type === "lesson"
          ? data.lessons.find((x) => x.id === id).description
          : null,
    });
  };
  const updateMehtod = (e) => {
    e.preventDefault();
    if (editedItem.type === "lesson") {
      axiosClient
        .put("/lessons/update/" + editedItem.id, {
          title: e.target.name.value,
          description: e.target.description.value,
        })
        .then((res) => {
          const _data = {
            ...data,
            lessons: data.lessons.map((lesson) =>
              lesson.id === editedItem.id
                ? {
                    ...lesson,
                    title: e.target.name.value,
                    description: e.target.description.value,
                  }
                : lesson
            ),
          };
          setData(_data);
          setEditModal(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          const errorData = err.response?.data || {};
          setNameError(
            Array.isArray(errorData?.errors?.Title) &&
              errorData?.errors?.Title.length > 0
              ? errorData.errors.Title[0]
              : ""
          );
        });
    } else if (editedItem.type === "folder") {
      axiosClient
        .put("/groupLessons/update/" + editedItem.id, {
          name: e.target.name.value,
        })
        .then((res) => {
          const _data = {
            ...data,
            children: data.children.map((child) =>
              child.id === editedItem.id
                ? { ...child, name: e.target.name.value }
                : child
            ),
          };
          setData(_data);
          setEditModal(false);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          const errorData = err.response?.data || {};
          setNameError(
            Array.isArray(errorData?.errors?.Name) &&
              errorData?.errors?.Name.length > 0
              ? errorData.errors.Name[0]
              : ""
          );
        });
    }
  };
  const handleNameChange = () => {
    setNameError("");
  };
  const handleFileChange = () => {
    setFileError("");
  };
  const handleTextChange = () => {
    setTextError("");
  };
  const handleLessonModalClose = () => {
    setlessonModal(false);
    setFileError("");
    setNameError("");
    setTextError("");
  };
  const handleFolderModalClose = () => {
    setFolderModal(false);
    setFileError("");
    setNameError("");
    setTextError("");
  };
  //   const handleReset = () => {
  //     formRef.current.reset();
  //   };
  const handleEditModalClose = () => {
    setEditModal(false);
    setFileError("");
    setNameError("");
    setTextError("");
  };
  return (
    <>
      <div className="admin-video">
        <ul>
          {
            <li
              className={"card " + (id === undefined ? "disable-back" : null)}
              onClick={() => {
                if (id !== undefined) {
                  setData(null);
                  navigate(-1);
                }
              }}
            >
              <i className="fa-solid fa-rotate-left"></i> Geri
            </li>
          }
          <li className="card" onClick={() => setFolderModal(true)}>
            <i className="fa-solid fa-folder-plus"></i> Yeni qovluq
          </li>
          <li
            className={"card " + (id === undefined ? "disable-back" : null)}
            onClick={() => id !== undefined && setlessonModal(true)}
          >
            <i className="fa-solid fa-file-circle-plus"></i> Yeni dərslik
          </li>
        </ul>
        <ul className="explorer">
          {data !== null ? (
            <>
              {data.children &&
                data.children.map((g, i) => {
                  return (
                    <li
                      key={i}
                      onContextMenu={(event) =>
                        handleContextMenu(event, "folder", g.id)
                      }
                      className="card"
                      onClick={() => {
                        setData(null);
                        navigate("/editdocuments/" + g.id);
                      }}
                    >
                      <i className="fa-regular fa-folder"></i> {g.name}
                    </li>
                  );
                })}
              {data.lessons &&
                data.lessons.map((v, i) => {
                  return (
                    <li
                      key={i}
                      onContextMenu={(event) =>
                        handleContextMenu(event, "lesson", v.id)
                      }
                      className="card"
                    >
                      <i className="fa fa-file"></i> {v.title}
                    </li>
                  );
                })}
            </>
          ) : null}
        </ul>
      </div>
      {folderModal && (
        <div className="user-modal modal-add-folder">
          <form className="modal-card" onSubmit={addFolder}>
            <div className="modal-header">
              <h4 className="modal-title">Yeni qovluq</h4>
              <i
                className="fa-solid fa-xmark"
                onClick={handleFolderModalClose}
              ></i>
            </div>
            <div className="modal-body">
              <input
                className={`form-control ${nameError ? "errorMessage" : ""}`}
                onChange={handleNameChange}
                required
                name="name"
                placeholder="Ad"
              />
              {nameError && (
                <div className="error-message">
                  <img src={errorImg} alt="Logo" />
                  <p>{nameError}</p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={() => setFolderModal(false)}
              >
                Ləğv et
              </button>
              <button type="submit" className="save">
                Yadda saxla
              </button>
            </div>
          </form>
        </div>
      )}
      {lessonModal && (
        <div className="user-modal modal-video-folder">
          <form className="modal-card" onSubmit={addlesson} ref={formRef}>
            <div className="modal-header">
              <h4 className="modal-title">Yeni dərslik</h4>
              <i
                className="fa-solid fa-xmark"
                onClick={handleLessonModalClose}
              ></i>
            </div>
            <div className="modal-body">
              <div className="upInputs">
                <input
                  className={`form-control ${nameError ? "errorMessage" : ""}`}
                  required
                  name="title"
                  placeholder="Ad"
                  onChange={handleNameChange}
                />
                {nameError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{nameError}</p>
                  </div>
                )}
              </div>
              <div className="upInputs">
                <input
                  type="file"
                  className={`form-control ${fileError ? "errorMessage" : ""}`}
                  required
                  name="lessonFile"
                  onChange={handleFileChange}
                    accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .rar"
                  placeholder="Link"
                />
                {fileError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{fileError}</p>
                  </div>
                )}
              </div>
              <div className="upInputs">
                <textarea
                  rows={15}
                  className={`form-control ${textError ? "errorMessage" : ""}`}
                  onChange={handleTextChange}
                  name="description"
                  placeholder="Qeyd"
                  maxLength={2000}
                  minLength={5}
                ></textarea>
                {/* {textError && (
                  <div className="error-message">
                    <img src={errorImg} alt="Logo" />
                    <p>{textError}</p>
                  </div>
                )} */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={() => setlessonModal(false)}
              >
                Ləğv et
              </button>
              <button type="submit" className="save">
                Yadda saxla
              </button>
            </div>
          </form>
        </div>
      )}
      {editModal && (
        <div className="user-modal modal-add-folder">
          <form className="modal-card" onSubmit={updateMehtod}>
            <div className="modal-header">
              <h4 className="modal-title">
                {" "}
                {editedItem.type === "lesson"
                  ? "Dərsliyi dəyiş"
                  : "Qovluğu dəyiş"}
              </h4>
              <i
                className="fa-solid fa-xmark"
                onClick={handleEditModalClose}
              ></i>
            </div>
            <div className="modal-body">
              <input
                required
                name="name"
                placeholder="Ad"
                defaultValue={editedItem.name}
                className={`form-control ${nameError ? "errorMessage" : ""}`}
                onChange={handleNameChange}
                style={{ width: "100%" }}
              />{" "}
              {nameError && (
                <div className="error-message">
                  <img src={errorImg} alt="Logo" />
                  <p>{nameError}</p>
                </div>
              )}
              {editedItem.type === "lesson" && (
                <>
                  <textarea
                    rows={15}
                    className="form-control"
                    // required
                    name="description"
                    placeholder="Qeyd"
                    maxLength={2000}
                    minLength={5}
                    defaultValue={editedItem.description}
                  ></textarea>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="cancel"
                onClick={() => setEditModal(false)}
              >
                Ləğv et
              </button>
              <button type="submit" className="save">
                Yadda saxla
              </button>
            </div>
          </form>
        </div>
      )}
      <Menu id={MENU_ID}>
        {contextType === "folder" && (
          <Item id="open" onClick={handleItemClick}>
            Aç
          </Item>
        )}
        <Item id="edit" onClick={handleItemClick}>
          Dəyiş
        </Item>
        <Item id="remove" onClick={handleItemClick}>
          Sil
        </Item>
        {contextType === "folder" && (
          <Item id="rules" onClick={handleItemClick}>
            Səlahiyyətlər
          </Item>
        )}
      </Menu>
    </>
  );
}

export default EditDocuments;
