import * as React from "react";
import PropTypes from "prop-types";

const VideoSearchIcon = ({
  size = 30,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
<svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 0.25L10.5 3.75H7V0.25ZM6.125 3.75C6.125 4.24219 6.50781 4.625 7 4.625H10.5V12.9375C10.5 13.6758 9.89844 14.25 9.1875 14.25H1.3125C0.574219 14.25 0 13.6758 0 12.9375V1.5625C0 0.851562 0.574219 0.25 1.3125 0.25H6.125V3.75ZM6.125 10.75V8.125C6.125 7.66016 5.71484 7.25 5.25 7.25H2.625C2.13281 7.25 1.75 7.66016 1.75 8.125V10.75C1.75 11.2422 2.13281 11.625 2.625 11.625H5.25C5.71484 11.625 6.125 11.2422 6.125 10.75ZM8.75 8.04297C8.75 7.71484 8.36719 7.49609 8.06641 7.6875L7 8.34375V10.5312L8.06641 11.2148C8.36719 11.4062 8.75 11.1875 8.75 10.8594V8.04297Z" fill="#919DA8"/>
</svg>

);

VideoSearchIcon.displayName = "Check";

VideoSearchIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default VideoSearchIcon;
