import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { CancelIcon, PlusIcon } from "../../assets/icons";
import CustomModal from "../../components/Modal/CustomModal";
import UserPermission from "./UserPermission";
import axiosClient from "../../api/axiosClient";

const SetAdmin = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [activeRow, setActiveRow] = useState(null);
  const [choosenUser, setChoosenUser] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isAdmin, setIsAdmin] = useState([]);
  const navigate = useNavigate();

  const isModalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
  };

  const handleSave = () => {
    const params = { name, surname, email, phone };
    console.log("Name:", params);
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    closeModal();
  };
  const handleRowClick = (index) => {
    if (activeRow === index) {
      setActiveRow(null);
      setChoosenUser(null);
    } else {
      setActiveRow(index);
      setChoosenUser(index);
    }
  };
  
  const loadingProgress = false;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosClient.get(
          `/CompanyGroups/GetAllUsersForAddAdmin?companyGroupId=${id}`
        );
        if (response.status === 200) {
          setTableData(response.data);
          const adminUser = response.data.find((user) => user.isAdmin);
          if (adminUser) {
            setActiveRow(adminUser.id);
            setChoosenUser(adminUser.id);
          }
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error("Error in fetchProfileData:", error.message);
      }
    };
    fetchProfileData();
  }, [id]);

  return (
    <div className="departments-container">
      {openModal && (
        <CustomModal isOpen={isModalOpen} onClose={closeModal} mid={true}>
          <div className="newCompanyHeader">
            <h2>Yeni qrup</h2>
            <button onClick={closeModal}>
              <CancelIcon />
            </button>
          </div>
          <hr />
          <h5 onClick={() => navigate("../users")}>İstifadəçilər</h5>
          <form className="departments-form-content">
            <div className="departments-input-row">
              <div className="departments-input-group">
                <label>Ad</label>
                <input
                  name="name"
                  placeholder="Ad"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="departments-input-group">
                <label>Soyad</label>
                <input
                  name="surname"
                  placeholder="Soyad"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
            </div>
            <div className="departments-input-row">
              <div className="departments-input-group">
                <label>Email</label>
                <input
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="departments-input-group">
                <label>Telefon nömrəsi</label>
                <input
                  name="phone"
                  placeholder="Telefon nömrəsi"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="permissionsBtns">
              <button onClick={closeModal}>Ləğv et</button>
              <button type="button" onClick={handleSave}>
                Yadda saxla
              </button>
            </div>
          </form>
        </CustomModal>
      )}
      <div className="departments-header">
        <h2>Admin təyin edin</h2>
        <button className="newUserDepartment" onClick={isModalOpen}>
          <span>
            <PlusIcon />
          </span>
          <span>Yeni istifadəçi</span>
        </button>
      </div>
      <div className="DepartmentsTable-tableDiv">
        <table className="DepartmentsTable-table select-Admin-table">
          <thead className="DepartmentsTable-thead">
            <tr style={{ display: "flex" }}>
              <th className="DepartmentsTable-th">№</th>
              <th className="DepartmentsTable-th">İstifadəçilər</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((row, index) => (
                <tr
                  key={index}
                  style={{ display: "flex", gap: "10px" }}
                  onClick={() => handleRowClick(row.id)}
                  className={
                    activeRow === row.id
                      ? "DepartmentsTable-activeRow"
                      : index % 2 === 0
                      ? "DepartmentsTable-evenRow"
                      : "DepartmentsTable-oddRow"
                  }
                >
                  <td className="DepartmentsTable-td">{index + 1}</td>
                  <td className="DepartmentsTable-td">
                    {row?.fullName}{" "}
                    {row.isAdmin ? (
                      <span className="DepartmentsTable-isAdmin">(Admin)</span>
                    ) : null}
                    {activeRow === row.id && <i className="fa-solid fa-check" id="companyAdminCheck"></i>}
                  </td>
                </tr>
              ))
            ) : !loadingProgress ? (
              <tr style={{ display: "flex", gap: "10px" }}>
                <td colSpan="7" className="no-data">
                  No data
                </td>
              </tr>
            ) : null}
            {loadingProgress &&
              [1, 2, 3].map((_, index) => (
                <tr key={index}>
                  <td className="DepartmentsTable-td">
                    <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                  </td>
                  <td className="DepartmentsTable-td">
                    <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                  </td>
                  <td className="DepartmentsTable-td">
                    <Skeleton width="100%" style={{ padding: "6px 12px" }} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <UserPermission id={choosenUser} parentİd={id} />
    </div>
  );
};

export default SetAdmin;
