import * as React from "react";
import PropTypes from "prop-types";

const NavVideoIcon = ({
  size = 24,
  strokeWidth = 1.5,
  color = "currentColor",
  ...props
}) => (
    <svg
    width="11"
    height="15"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 3.58594V3.75H7V0.25H7.16406C7.32812 0.25 7.49219 0.332031 7.62891 0.46875L10.2812 3.12109C10.418 3.25781 10.5 3.42188 10.5 3.58594ZM6.125 3.96875C6.125 4.35156 6.39844 4.625 6.78125 4.625H10.5V13.5938C10.5 13.9766 10.1992 14.25 9.84375 14.25H0.65625C0.273438 14.25 0 13.9766 0 13.5938V0.90625C0 0.550781 0.273438 0.25 0.65625 0.25H6.125V3.96875ZM8.75 7.93359C8.75 7.33203 8.03906 7.05859 7.62891 7.46875L6.125 8.97266V7.90625C6.125 7.55078 5.82422 7.25 5.46875 7.25H2.40625C2.02344 7.25 1.75 7.55078 1.75 7.90625V10.9688C1.75 11.3516 2.02344 11.625 2.40625 11.625H5.46875C5.82422 11.625 6.125 11.3516 6.125 10.9688V9.92969L7.62891 11.4336C8.03906 11.8438 8.75 11.5703 8.75 10.9688V7.93359Z"
      fill="#919DA8"
    />
  </svg>

);

NavVideoIcon.displayName = "Check";

NavVideoIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default NavVideoIcon;
