


import * as React from "react";
import PropTypes from "prop-types";

const ApplicationSendIcon = ({
  size = 109,
  strokeWidth = 1,
  color = "currentColor",
  ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 110 108" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M85.4559 35.0443V76.4603C85.4559 89.2037 77.8524 93.4514 68.4647 93.4514H34.4824C25.0948 93.4514 17.4912 89.2037 17.4912 76.4603V35.0443C17.4912 21.2389 25.0948 18.0531 34.4824 18.0531C34.4824 20.6867 35.5442 23.0655 37.2858 24.8071C39.0274 26.5487 41.4063 27.6106 44.0399 27.6106H58.9072C64.1745 27.6106 68.4647 23.3204 68.4647 18.0531C77.8524 18.0531 85.4559 21.2389 85.4559 35.0443Z" fill="white" stroke="#002335" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M68.4648 18.0531C68.4648 23.3204 64.1745 27.6107 58.9072 27.6107H44.04C41.4063 27.6107 39.0274 26.5487 37.2859 24.8071C35.5443 23.0655 34.4824 20.6868 34.4824 18.0531C34.4824 12.7859 38.7727 8.49561 44.04 8.49561H58.9072C61.5409 8.49561 63.9198 9.55757 65.6613 11.2992C67.4029 13.0408 68.4648 15.4195 68.4648 18.0531Z" fill="white" stroke="#002335" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.4824 55.2213H51.4736" stroke="#002335" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.4824 72.2125H68.4648" stroke="#002335" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M88.9823 103.657C98.3862 103.657 106.08 95.9628 106.08 86.5589C106.08 77.1549 98.3862 69.4608 88.9823 69.4608C79.5784 69.4608 71.8843 77.1549 71.8843 86.5589C71.8843 95.9628 79.5784 103.657 88.9823 103.657Z" fill="white" stroke="#002335" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M81.7156 86.5588L86.5543 91.3976L96.2489 81.7201" stroke="#3E87D9" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

ApplicationSendIcon.displayName = "Check";

ApplicationSendIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default ApplicationSendIcon;
