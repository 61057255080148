
import * as React from "react";
import PropTypes from "prop-types";

const CompanyStatsIcon = ({
  size = 24,
  strokeWidth = 2,
  color = "currentColor",
  ...props
}) => (
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.75 10.1875C1.75 10.4336 1.94141 10.625 2.1875 10.625H13.125C13.5898 10.625 14 11.0352 14 11.5C14 11.9922 13.5898 12.375 13.125 12.375H2.1875C0.957031 12.375 0 11.418 0 10.1875V1C0 0.535156 0.382812 0.125 0.875 0.125C1.33984 0.125 1.75 0.535156 1.75 1V10.1875ZM9.35156 6.87891C9.02344 7.23438 8.44922 7.23438 8.12109 6.87891L6.5625 5.32031L4.10156 7.75391C3.77344 8.10938 3.19922 8.10938 2.87109 7.75391C2.51562 7.42578 2.51562 6.85156 2.87109 6.52344L5.93359 3.46094C6.26172 3.10547 6.83594 3.10547 7.16406 3.46094L8.75 5.01953L11.6211 2.14844C11.9492 1.79297 12.5234 1.79297 12.8516 2.14844C13.207 2.47656 13.207 3.05078 12.8516 3.37891L9.35156 6.87891Z" fill="#919DA8"/>
</svg>

);

CompanyStatsIcon.displayName = "Edit";

CompanyStatsIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default CompanyStatsIcon;