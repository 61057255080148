import React, { useEffect, useRef, useState } from "react";
import { ArrowDownIcon, CancelIcon } from "../../assets/icons";
import "./Departments.css";
import axiosClient from "../../api/axiosClient";
import DepartmentsPermissions from "./DepartmentsPermissions";

const GroupPermission = ({ closeModal, id, parentId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const iconRef = useRef(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosClient.get(
          `CompanyGroups/GetVideoAndLessonForGroup?companyGroupId=${id}`
        );
        if (response.status === 200) {
          setGroupPermissions(response.data);
          const allowedVideos = response.data.videos
            .filter((video) => video.isAllowed)
            .map((video) => video.id);
          setSelectedVideos(allowedVideos);
          const allowedLessons = response.data.lessons
            .filter((lesson) => lesson.isAllowed)
            .map((lesson) => lesson.id);
          setSelectedLessons(allowedLessons);
        } else {
          throw new Error("Error fetching profile data");
        }
      } catch (error) {
        console.error("Error in fetchProfileData:", error.message);
      }
    };
    fetchProfileData();
  }, []);

  const handleOutsideClick = (event) => {
    const dropdown = document.querySelector(".dropdown-menu");
    if (
      dropdown &&
      !dropdown.contains(event.target) &&
      !event.target.closest(".custom-select")
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleCreate = async () => {
    const params = {
      companyGroupId: id,
      videoIds: selectedVideos,
      lessonIds: selectedLessons,
    };
    
    try {
      const response = await axiosClient.post(
        `/CompanyGroups/AddVideoLessonPermissionForGroups`,
        params,{
          notification:false
        }
      );
      if (response.status === 200) {
        closeModal()
      }
    } catch (error) {
      console.error("Failed to add permissions:", error);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  return (
    <div className="newCompanyModal">
      <div className="newCompanyHeader">
        <h2>Yeni qrup</h2>
        <button onClick={closeModal}>
          <CancelIcon />
        </button>
      </div>
      <hr />
      <DepartmentsPermissions
        data={groupPermissions}
        setSelectedVideos={setSelectedVideos}
        selectedVideos={selectedVideos}
        setSelectedLessons={setSelectedLessons}
        selectedLessons={selectedLessons}
      />
      <div className="permissionsBtns">
        <button onClick={closeModal}>Ləğv et</button>
        <button onClick={handleCreate}>Yarat</button>
      </div>
    </div>
  );
};

export default GroupPermission;
