import React, { useContext, useState } from "react";
import "../Login.css";
import logo from "../images/logo white-01 1.png";
import { AuthContext } from "../context/AuthContext"; // AuthContext import edilmesi
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../api/axiosClient";
import errorImg from "../images/circle-exclamation.png";
import { MailIcon } from "../assets/icons";
function ResetPassword() {
  const { login } = useContext(AuthContext); // login fonksiyonunu context'ten alıyoruz
  const navigate = useNavigate();
  const { token } = useParams();
  const [emailError, setEmailError] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    axiosClient
      .put("/Auth/ResetPassword",{email:e.target.email.value})
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        const errorData = err.response?.data || {};
        // setEmailError(errorData.message || "Error message");
        setEmailError(
          errorData.message ||
            (errorData.errors?.Email?.length > 0 ? errorData.errors.Email[0] : "")
        );
      });
  };
  const handleEmailChange = () => {
    setEmailError("");
  };
  return (
    <div className="root">
       <div className="login-left">
        <img src={logo} alt="Logo" />
        <h3>
          Axtarılan mütəxəssisi bilik, bacarıq və səriştələr
          formalaşdırır. Onlara catmaq üçün isə illərlə işləmək gərəkdir, lakin
          biz sizə keyfiyyətli dərsliklərlə zamanınızı qısaldıb məqsədlərinizə
          daha tez çatmağınızda yardımçı oluruq.
        </h3>
      </div>
      <div className="login-box">
        <h2>Şifrənizi yeniləyin!</h2>
        <form onSubmit={handleLogin}>
          <div className="txtb">
            <label>Email</label>
            <div className={`inputMain ${emailError ? "errorMessage" : ""}`}>
              <MailIcon/>
            <input
              type="text"
              // className={emailError ? "errorMessage" : ""}
              onChange={handleEmailChange}
              name="email"
              placeholder="Email"
              required
            />
            </div>{emailError && (
              <div className="error-message">
                <img src={errorImg} alt="Logo" />
                <p>{emailError}</p>
              </div>
            )}
          </div>
          <button type="submit">Göndər</button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
